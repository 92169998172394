/**
 * Created by MTI on 10/09/2018.
 */

import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod
} from '@ngx-resource/core';
import {Injectable} from '@angular/core';
import {State} from '../model/location/state.model';
import {County} from '../model/location/county.model';
import {ResourceAuth} from '../auth/authenticate.resource';
import {AuthServerProvider} from '../auth/auth-jwt.service';

@Injectable()
@ResourceParams({
  pathPrefix: '/tramites'
  // url: 'http://localhost:8096'
})
export class FormalityValidateResource extends ResourceAuth {
  constructor(requestHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(requestHandler, authProv);
  }
  // export class FormalityValidateResource extends Resource {
  //  constructor(requestHandler: ResourceHandler) {
  //  super(requestHandler);
  //  }

  @ResourceAction({
    path: '/tramites/bloquear/{!_id}',
    method: ResourceRequestMethod.Patch
  })
  bloquearTramite: IResourceMethod<{ _id: any }, any>;

  @ResourceAction({
    path: '/tramites/desbloquear/{!_id}',
    method: ResourceRequestMethod.Patch
  })
  desbloquearTramite: IResourceMethod<{ _id: any }, any>;
}
