import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

 /*Este service fue creado para poder cambiar el valor de alguna variable o poder actualizar algun componente cuando no se puede acceder a el.

 en el componente donde se requiere modificar los valores se tiene que agregar el metodo que se desea acceder como este ejemplo:

     this.actualizarComponenteService.reloadComponent$.subscribe(componentName => {
      if (componentName === 'footer') {         //se agrega el nombre de el valor para poder acceder a esa parte
        this.ocultarNavegacion=false;
        console.log(this.ocultarNavegacion);
      }

despues en el componente donde quieres activar lo anterior lo mandas llamar y mandas el nombre agregado
 this.actualizarComponenteService.reloadComponent('footerActive');

 */

@Injectable({
  providedIn: 'root'
})
export class ActualizaComponenteService {
  private reloadComponentSource = new Subject<string>();
  reloadComponent$ = this.reloadComponentSource.asObservable();


  constructor() { }

  reloadComponent(componentName: string) {
    this.reloadComponentSource.next(componentName);
  }
}
