import {Component, OnInit} from '@angular/core';
import {ConfiguracionGeneralService} from '../admin.configuracion-general.module/service/configuracion-general.service';
import {ToastService} from 'ng-uikit-pro-standard';
import {ConfiguracionGeneralModel} from '../admin.configuracion-general.module/service/configuracion-general.model';
import {AuthServerProvider} from '../common.module/auth/auth-jwt.service';
import {UserAuth} from '../common.module/auth/user-auth.model';
import {DataSessionShareService} from '../common.module/util/data-share-session.service';
import { ActualizaComponenteService } from './service/actualiza-componente.service';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  public zoom = 14;
  public latitud: number;
  public longitud: number;
  public configuracion: ConfiguracionGeneralModel = {};
  public user:UserAuth;
  public ocultarNavegacion: Boolean = true;
  public inicioSesion:Boolean;

  constructor(private toastMsg: ToastService,
              private configuracionGeneralService: ConfiguracionGeneralService,
              private authServerProvider: AuthServerProvider,protected dataShare: DataSessionShareService,
              private actualizarComponenteService:ActualizaComponenteService) {
  }

  ngOnInit(): void {
    // este service se creo para activar o desactivar lo visible de el footer al momento de cambiar de pagina con router debido a que no se actualiza la pagina completa y se requiere acceder a estos valores.
    this.actualizarComponenteService.reloadComponent$.subscribe(componentName => {
      if (componentName === 'footer') {
        this.ocultarNavegacion=false;
        console.log(this.ocultarNavegacion);
      }if(componentName==='footerActive'){
        this.ocultarNavegacion=true;
      }
    });
    this.user = this.authServerProvider.getUserDecoded();
    this.configuracionGeneralService.obtenerConfiguracionGeneral().then(configuracion => {
      this.configuracion = configuracion;
      this.inicioSesion  =configuracion.inicio_sesion;
      this.mostrarLoginUsuarioNoLogueado();
    }, err => {
      console.log('Error al cargar la configuración en el pie de página');
      console.error(err);
    });
  }

  public placeMarker() {
    console.log('placed');
  }

  public redirect(url: string) {
    window.open(url);
  }
// este metodo valida cada vez que se carga la pagina si la navegacion de el footer se activa o no a diferencia del service que se utiliza arriba (actualizarComponenteService) este es debido a que al cambiar de pagina no actualiza variables y ese service lo hace desde el header).
  mostrarLoginUsuarioNoLogueado() {
    if(this.inicioSesion && this.user ==null){
    this.ocultarNavegacion=true;
    }else{
      this.ocultarNavegacion=false;
    }
}


}

