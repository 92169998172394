import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {EvaluacionModel} from './evaluacion-model';
import {AuthServerProvider} from "../../../common.module/auth/auth-jwt.service";
import {ResourceAuth} from "../../../common.module/auth/authenticate.resource";

@Injectable()
@ResourceParams({
  // url: 'http://localhost:8012'
  pathPrefix: '/core'
})
// export class EvaluacionResource extends Resource {
//  constructor(handler: ResourceHandler) {
//    super(handler);
//  }

  export class EvaluacionResource extends ResourceAuth {
   constructor(handler: ResourceHandler, auth: AuthServerProvider) {
     super(handler, auth);
  }

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/evaluacion'
  })
  crearEvaluacion: IResourceMethod<EvaluacionModel, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    array: true,
    path: '/evaluacion'
  })
  obtenerListaEvaluaciones: IResourceMethod<any, EvaluacionModel[]>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/evaluacion/{!id}'
  })
  obtenerEvaluacion: IResourceMethod<{ id: any }, EvaluacionModel>;
}
