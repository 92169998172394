<div class="card-body">
  <div class="row">
    <div class="col-12 col-lg-5" [ngClass]="{disabled:!cargandoInformacion}">
      <button type="button" class="btn btn-sm mdb-color text-white waves-light" (click)="mainPage()" mdbWavesEffect
              [disabled]="!list || list.length === 0 || filter.pagina === 1">
        |< Inicio
      </button>
      <button type="button" class="btn btn-sm mdb-color text-white waves-light" (click)="previousPage()" mdbWavesEffect
              [disabled]="!list || list.length === 0 || filter.pagina === 1">
        << Anterior
      </button>
      <button type="button" class="btn btn-sm mdb-color text-white waves-light" (click)="nextPage()" mdbWavesEffect
              [disabled]="!list || list.length === 0 || list.length <= filter.elemPorPagina">
        Siguiente >>
      </button>
    </div>
    <div class="col-12 col-lg-4">
      <strong *ngIf="count">Total de registros: {{count}}</strong>
    </div>
    <div class="col-12 col-lg-3 mt-3">
      <mdb-select placeholder=""
                  [options]="listNumberPerPage"
                  [(ngModel)]="filter.elemPorPagina"
                  (selected)="loadComponent()"
                  class="white">
      </mdb-select>
      <label>Elementos por página</label>
    </div>
  </div>
  <table mdbTable [responsive]="!disableTableResponsive" small="true" hover="true">
    <caption *ngIf="caption && caption != ''">{{caption}}</caption>
    <thead>
    <tr>
      <th class="font-weight-bold"
          *ngFor="let header of configItems; let i = index;"
          (click)="!header.filteringNotAllowed && reloadOrderBy(header.nameFilter)"
          [ngClass]="{'cursor-pointer': !header.filteringNotAllowed || header.orderEnabled}">
        {{header.title}} <i class="fa float-right" *ngIf="header.nameFilter === filter.ordenarPor"
                            [ngClass]="{'fa-sort-up': order === 'ASC', 'fa-sort-down': order === 'DESC'}"></i>
      </th>
    </tr>
    <tr>
      <th *ngFor="let control of configItems">
        <div>
          <input *ngIf="(!control.filteringNotAllowed && !control.orderEnabled) && (!control.typeSelect)" type="text"
                 [(ngModel)]="filter[control.nameFilter]"
                 class="form-control"
                 placeholder="Filtrar por..."
                 (blur)="storeFilterHistory && saveFilterHistory()"
                 (keyup.enter)="loadComponent()" mdbInput>
          <mdb-select *ngIf="control.typeSelect === true" [options]="control.options"
                      placeholder="Elige:"
                      (selected)="loadComponent()"
                      [(ngModel)]="filter[control.nameFilter]"
                      [filterEnabled]="true"
                      class="list-select"
                      [appendToBody]="false"
                      optionHeight="auto"
                      mdbInput>
          </mdb-select>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr mdbTableCol *ngFor="let item of list; let ind = index;"
        class="cursor-pointer"
        (click)="validateAccess(item)"
        [ngClass]="{'d-none': list.length > filter.elemPorPagina && (ind + 1) === list.length}">
      <td *ngFor="let elem of configItems" class="text-left">
        <ng-template [ngIf]="!elem.enableButtonAction && !elem.inputType && !elem.isObject">
          {{ elem.isDateType !== true ? item[elem.nameAttribute] : item[elem.nameAttribute] | date: 'd/M/y, h:mm a' }}
        </ng-template>
        <ng-template [ngIf]="elem.isObject">
          {{item[elem.fatherObject][elem.nameAttribute]}}
        </ng-template>
        <ng-template [ngIf]="elem.inputType === 'check'">
          <div class="form-check">
            <input class="form-check-input" mdbInput type="checkbox"
                   [id]="'read_res_' + ind"
                   [(ngModel)]="item[elem.nameAttribute]">
            <label class="form-check-label" [for]="'read_res_' + ind"></label>
          </div>
        </ng-template>
        <ng-template [ngIf]="elem.enableButtonAction === true">
          <a class="btn-floating btn-sm btn-info"
             (click)="elem.title == 'Editar' ? emitEditCallback(item) : emitDownloadCallback(item)">
            <i class="fa {{elem.iconButtonAction}}"></i>
          </a>
        </ng-template>
        <ng-template [ngIf]="elem.inputType=== 'colorView'">
          <div class="mr-1" style="width: 34px !important;height: 31px !important;margin-top: 4px;" [ngStyle]="{'background-color': item[elem.nameAttribute]}"></div>
        </ng-template>
      </td>
    </tr>
    <tr *ngIf="list.length === 0">
      <td colspan="100%" class="text-muted text-center pt-5">{{emptyListMessage}}</td>
    </tr>
    </tbody>
    <tfoot>
    <tr mdbTableCol *ngFor="let item of list2; let ind = index;"
        class="cursor-pointer"
        (click)="validateAccess(item)"
        [ngClass]="{'d-none': list2.length > filter.elemPorPagina && (ind + 1) === list2.length}">
      <td *ngFor="let elem of configItems" class="text-left">
        <ng-template [ngIf]="!elem.enableButtonAction && !elem.inputType && !elem.isObject">
          {{ elem.isDateType !== true ? item[elem.nameAttribute] : item[elem.nameAttribute] | date: 'd/M/y, h:mm a' }}
        </ng-template>
        <ng-template [ngIf]="elem.isObject">
          {{item[elem.fatherObject][elem.nameAttribute]}}
        </ng-template>
        <ng-template [ngIf]="elem.inputType === 'check'">
          <div class="form-check">
            <input class="form-check-input" mdbInput type="checkbox"
                   [id]="'read_res_' + ind"
                   [(ngModel)]="item[elem.nameAttribute]">
            <label class="form-check-label" [for]="'read_res_' + ind"></label>
          </div>
        </ng-template>
        <ng-template [ngIf]="elem.enableButtonAction === true">
          <a class="btn-floating btn-sm btn-info"
             (click)="elem.title == 'Editar' ? emitEditCallback(item) : emitDownloadCallback(item)">
            <i class="fa {{elem.iconButtonAction}}"></i>
          </a>
        </ng-template>
        <ng-template [ngIf]="elem.inputType=== 'colorView'">
          <div class="mr-1" style="width: 34px !important;height: 31px !important;margin-top: 4px;" [ngStyle]="{'background-color': item[elem.nameAttribute]}"></div>
        </ng-template>
      </td>
    </tr>
    </tfoot>
  </table>
  <div class="row">
    <div class="col-12 col-lg-5" [ngClass]="{disabled:!cargandoInformacion}">
      <button type="button" class="btn btn-sm mdb-color text-white waves-light" (click)="mainPage()" mdbWavesEffect
              [disabled]="!list || list.length === 0 || filter.pagina === 1">
        |< Inicio
      </button>
      <button type="button" class="btn btn-sm mdb-color text-white waves-light" (click)="previousPage()" mdbWavesEffect
              [disabled]="!list || list.length === 0 ||  filter.pagina === 1">
        << Anterior
      </button>
      <button type="button" class="btn btn-sm mdb-color text-white waves-light" (click)="nextPage()" mdbWavesEffect
              [disabled]="!list || list.length === 0 || list.length <= filter.elemPorPagina">
        Siguiente >>
      </button>
    </div>
    <div class="col-12 col-lg-4">
      <strong *ngIf="count">Total de registros: {{count}}</strong>
    </div>
    <div class="col-12 col-lg-3 mt-3">
      <mdb-select placeholder=""
                  [options]="listNumberPerPage"
                  [(ngModel)]="filter.elemPorPagina"
                  (selected)="loadComponent()"
                  class="white">
      </mdb-select>
      <label>Elementos por página</label>
    </div>
  </div>
</div>
