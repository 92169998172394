/**
 * Created by anunez on 18/07/2018.
 */
import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {PaidPaymentModel} from '../model/paid-payment.model';
import {environment} from '../../../environments/environment';
import { PaidPoolPaymentDetailModel } from '../model/PaidPoolPaymentDetailModel.model';
import { ResourceAuth } from '../auth/authenticate.resource';
import { AuthServerProvider } from '../auth/auth-jwt.service';

@Injectable()
@ResourceParams({
   //url: environment.apiBaseUrl + '/api/payments/na'
   pathPrefix: '/payments'
 // url: 'http://187.217.231.173:9938/api/payments/na'
 //  url: 'http://187.216.144.10:9938/api/payments/na'
 //  url: 'http://localhost:8087/na'
})

export class PaidPaymentResource extends ResourceAuth {
  constructor(resourceHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(resourceHandler, authProv);
  }

/*export class PaidPaymentResource extends Resource {
  constructor(restHandler: ResourceHandler) {
    super(restHandler);
  }*/
  @ResourceAction({
    path: '/na/paid_payments',
    method: ResourceRequestMethod.Post
  })
  savePaidPayment: IResourceMethod<PaidPaymentModel , any>;

  // usa microservicio de corregidora
  // FIXME: hardcode
  @ResourceAction({
    // path: '/na/santander/{!liquidacion}/{!tipo}',
    path: '/na/santander/{!liquidacion}/{!tipo}',
    // url: 'http://192.168.1.244:9990/api/payments/na/santander/{!liquidacion}/{!tipo}',
    method: ResourceRequestMethod.Get
  })
  getLigaSantander: IResourceMethod<{liquidacion: string, tipo: string} , any>;

  // usa microservicio de corregidora
  // FIXME: hardcode
  @ResourceAction({
     path: '/na/qr_code/{!liquidacion}/{!tipo}',
    //path: '/qr_code/{!liquidacion}/{!tipo}',
    // url: 'http://192.168.1.244:9990/api/payments/na/qr_code/{!liquidacion}/{!tipo}',
    method: ResourceRequestMethod.Get
  })
  getLigaSantanderQR: IResourceMethod<{liquidacion: any, tipo: any} , any>;

  @ResourceAction({
    path: '/na/save_payment_detail',
    method: ResourceRequestMethod.Post
  })
  savePoolPaymentDetail: IResourceMethod<PaidPoolPaymentDetailModel , any>;

}
