/**
 * Created by Raul on 03/05/2018.
 */

import {Injectable} from '@angular/core';
import {UsuarioResource} from './usuario.resource';
import {UsuarioModel} from './usuario.model';

@Injectable()
export class UsuarioExpService {

  constructor(private userResource: UsuarioResource) {
  }

  obtenerListaUsuario(): Promise<UsuarioModel[]> {
    return this.userResource.obtenerListaUsuarios({});
  }

}
