/**
 * Created by Raul on 03/05/2018.
 */

import {Injectable} from '@angular/core';
import {UsuarioResource} from './usuario.resource';
import {UsuarioModel} from './usuario.model';

@Injectable()
export class UsuarioService {

  constructor(private usuarioResource: UsuarioResource) {
  }

  registrarUsuario(user: UsuarioModel): Promise<UsuarioModel> {
    return this.usuarioResource.crearUsuario(user);
  }

  obtenerUsuarioPorCodigoConfirmacion(code: string): Promise<UsuarioModel> {
    return this.usuarioResource.obtenerPorCodigoConfirmacion({codigo_confirmacion: code});
  }

  obtenerUsuarioPorCodigoRecuperacion(code: string): Promise<UsuarioModel> {
    return this.usuarioResource.obtenerCodigoRecuperacion({codigo_recuperacion: code});
  }

  saveRegister(usr: UsuarioModel): Promise<any> {
    return this.usuarioResource.actualizarUsuario(usr);
  }

  obtenerUsuarioEnSesion(): Promise<UsuarioModel> {
    return this.usuarioResource.usuarioSesion();
  }

  actualizarUsuarioEnSesion(user: UsuarioModel): Promise<any> {
    return this.usuarioResource.actualizarUsuarioSesion(user);
  }

  recuperarCuenta(user: UsuarioModel): Promise<any> {
    return this.usuarioResource.recuperarCuenta(user);
  }

  solicitudRecuperarCuenta(user: UsuarioModel): Promise<any> {
    return this.usuarioResource.solicitudRecuperacionCuenta(user);
  }
}
