import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConsultaExpElecComponent} from './consulta-exp-elec/consulta-exp-elec.component';
import {AppCommonLibsModule} from '../common.module/common.module';
import {HttpClientModule} from '@angular/common/http';
import {ConsultaExpElecService} from './service/consulta-exp-elec.service';
import {ConsultaExpElecResource} from './service/consulta-exp-elec.resource';
import {ConsExpElecDetalleComponent} from './cons-exp-elec-detalle/cons-exp-elec-detalle.component';
import {LocationsResource} from './service/locations.resource';
import {LocationsService} from './service/locations.service';
import {RequisitosResource} from './service/requisitos.resource';
import {RequisitosService} from './service/requisitos.service';
import {AdminCiudadanoRoutingModule} from './consulta-exp-elec.routing';
import {VigenciaExpElectDetalleComponent} from './vigencia-exp-elect-detalle/vigencia-exp-elect-detalle.component';
import {ObservacionExpElectDetalleComponent} from './observacion-exp-elect-detalle/observacion-exp-elect-detalle.component';
import {UsuarioResource} from './service/usuario.resource';
import {UsuarioService} from '../common.tramite.module/entidad.component/service/usuario.service';
import {UsuarioEntidadResource} from '../common.tramite.module/entidad.component/service/usuario.resource';
import {ConsExpElecDuplicadoComponent} from './cons-exp-elec-duplicados/cons-exp-elec-duplicado.component';
import {UsuarioExpService} from './service/usuario.service';
import {DirectivasModule} from './../directivas.module/directivas.module';

@NgModule({
  declarations: [
    ConsultaExpElecComponent,
    ConsExpElecDetalleComponent,
    VigenciaExpElectDetalleComponent,
    ObservacionExpElectDetalleComponent,
    ConsExpElecDuplicadoComponent
  ],
  imports: [
    AdminCiudadanoRoutingModule,
    CommonModule,
    AppCommonLibsModule,
    HttpClientModule,
    DirectivasModule
  ],
  providers: [
    ConsultaExpElecService,
    ConsultaExpElecResource,
    LocationsResource,
    LocationsService,
    RequisitosService,
    RequisitosResource,
    UsuarioExpService,
    UsuarioResource,
    UsuarioService,
    UsuarioEntidadResource
  ]
})
export class AdminConsultaExpElecModule { }
