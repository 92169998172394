import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';
import {ConfiguracionGeneralModel} from './configuracion-general.model';

@Injectable()
@ResourceParams({
  pathPrefix: '/configuracion'
  //url: 'http://localhost:8003'
})
export class ConfiguracionGeneralResource extends ResourceAuth {
  constructor(resourceHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(resourceHandler, authProv);
  }
 /*export class ConfiguracionGeneralResource extends Resource {
   constructor(resourceHandler: ResourceHandler) {
     super(resourceHandler);
   }*/

  @ResourceAction({
    path: '/configuracion-general',
    method: ResourceRequestMethod.Patch
  })
  actualizarConfiguracionGeneral: IResourceMethod<ConfiguracionGeneralModel, any>;

  @ResourceAction({
    path: '/na/configuracion-general',
    method: ResourceRequestMethod.Get
  })
  obtenerConfiguracionGeneral: IResourceMethod<any, ConfiguracionGeneralModel>;
}
