/**
 * Created by Luis on 13/08/2018.
 */
import {Injectable} from '@angular/core';
import {RequestsResource} from './requests.resource';

import {StatusModel} from './status.model';
import {RequestInsertModel} from './request-insert.model';
import {ResponseCashModel} from './response-cash.model';
import {States} from './states.model';
import {Towns} from './towns.model';
import {Suburbs} from './suburbs.model';
import {BestowalSavePdfModel} from './bestowal-save-pdf';
import {DocumentsModel} from './documents.model';
import {DownloadDocument} from './download-document.model';
import {LocationResource} from "../resource/location.resource";
@Injectable()
export class RequestsService {
  constructor(private requestsResource: RequestsResource, private locationResource: LocationResource) {
  }

  getValidRfc() {
    return this.requestsResource.getValidRfc({});
  }

  getAllRequests() {
    return this.requestsResource.getRequests();
  }

  getRequest(pkey: number) {
    return this.requestsResource.getRequest({pkey});
  }

  getUpdateRequest(pkey: number) {
    return this.requestsResource.getUpdateRequest({pkey});
  }
  getState(id: any) {
    return this.locationResource.getStates({id});
  }
  getCounty(id: any) {
    return this.locationResource.getCounties({id});
  }
  getLocalities(id: any) {
    return this.locationResource.getLocalities({id});
  }

  updateStatus(status: StatusModel) {
    return this.requestsResource.updateStatus(status);
  }

  getLapse() {
    return this.requestsResource.getLapses();
  }
  getRequestUser() {
    return this.requestsResource.getRequestUser();
  }
  getObras() {
    return this.requestsResource.getObras();
  }

  getDocuments(tipoPer: any, tipoSol: any) {
    return this.requestsResource.getDocumentos({tipoPer, tipoSol});
  }

  getCamCols(pTipo: string) {
    return this.requestsResource.getCamCols({pTipo});
  }

  getObservationsRefuse(pkey: number) {
    return this.requestsResource.getObservationsRefuse({pkey});
  }

  postRequest(requestInsertModel: RequestInsertModel) {
    return this.requestsResource.postRequest(requestInsertModel);
  }

  getPaymentRequest(pcgk: number) {
    return this.requestsResource.getPaymentRequest({pcgk});
  }

  updateMakePayment(responseCash: BestowalSavePdfModel) {
    return this.requestsResource.updateMakePayment(responseCash);
  }

  getStates(countryKey: any): Promise<States> {
    return this.requestsResource.getStates({cve_pais: countryKey});
  }

  getTowns(stateKey: any): Promise<Towns> {
    return this.requestsResource.getTowns({cve_estado: stateKey});
  }

  getSuburbs(townKey: any): Promise<Suburbs> {
    return this.requestsResource.getSuburbs({cve_municipio: townKey});
  }

  getAllUpdateRequests() {
    return this.requestsResource.getUpdateRequests();
  }

  updateRequest(requestInsertModel: RequestInsertModel) {
    return this.requestsResource.updateRequest(requestInsertModel);
  }

  deleteDoc(pdoctok: any) {
    return this.requestsResource.delete({pdoctok: pdoctok});
  }

  postRequestUpdate(requestInsertModel: RequestInsertModel): Promise<any> {
    return this.requestsResource.postRequestUpdate(requestInsertModel);
  }

  getStatusHistory(pkey: number) {
    return this.requestsResource.getStatusHistory({pkey});
  }

  getRepOtorgamiento(pKey: number) {
    return this.requestsResource.getRepOtorgamiento({pKey : pKey});
  }

  addDocument(documentsModel: DocumentsModel) {
    return this.requestsResource.saveDocument(documentsModel);
  }



  DownloadDocument(id: any): Promise<DownloadDocument> {
    return this.requestsResource.DownloadDocument({id: id});
  }
}
