import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainLoginComponent} from './main-login.component/main-login.component';
import {PageNotFoundComponent} from './components/generic/not-found/not-found.component';
import {MainPortalComponent} from './main-portal.component/main.component';
import {PortalRoutesModules} from './app.portal.routes';
import {AdminRoutesModules} from './app.admin.routes';
import {MainAdminComponent} from './main-admin.component/main.component';
import {RecoverAccountComponent} from './main-recover.component/recover-account.component';
import {RegistryComponentComponent} from './main-registry.component/registry-continue.component';
import {MainLoginConsumosComponent} from './main-login-app-consumos.component/main-login-app-consumos.component';
import {MainAdminConsumosComponent} from './main-admin-consumos.component/main.component';
import {AdminRoutesModulesConsumos} from './app.admin-consumos.routes';
import { PaymentSuccessCreateComponent } from './portal-legacy/portal.payment.success.module/payment-success-create/payment-success-create.component';
export const routes: Routes = [
  {
    path: 'login',
    component: MainLoginComponent,
    // canActivate: [AuthGuardLoged],
    data: {
      pageTitle: 'Login - Trámites.'
    }
  },
  {
    path: 'recuperar',
    component: RecoverAccountComponent,
    data: {
      pageTitle: 'Recuperar cuenta de Portal de Trámites.'
    }
  },
  {
    path: 'registro',
    component: RegistryComponentComponent,
    data: {
      pageTitle: 'Finaliza tu registro.'
    }
  },
  {
    path: 'portal',
    component: MainPortalComponent,
    children: [
      ...PortalRoutesModules
    ],
    data: {
      pageTitle: 'Portal de Trámites'
    },
  },
  {
    path: 'app',
    component: MainAdminComponent,
    data: {
      pageTitle: 'Administración de Trámites.'
    },
    children: [
      ...AdminRoutesModules
    ]
  },
  {
    path: 'payment/success',
    component: PaymentSuccessCreateComponent,
    data: {
      pageTitle: 'Pago realizado'
    }
  },
  {
    path: 'app-consumos',
    component: MainAdminConsumosComponent,
    data: {
      pageTitle: 'App - Consumos.'
    },
    children: [
      ...AdminRoutesModulesConsumos
    ]
  },
   {
    path: 'login-consumos',
    component: MainLoginConsumosComponent,
    // canActivate: [AuthGuardLoged],
    data: {
      pageTitle: 'Login - Consumos.'
    }
  },
  {path: '', redirectTo: '/portal', pathMatch: 'full'},
  {path: '', redirectTo: '/portal/loginPrincipal', pathMatch: 'full'},
  {path: '**', component: PageNotFoundComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
