import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {ConfiguracionChatModel} from './configuracion-chat.model';
import {ResourceAuth} from './../../../auth/authenticate.resource';
import {AuthServerProvider} from './../../../auth/auth-jwt.service';

@Injectable()
@ResourceParams({
  pathPrefix: '/configuracion'
  // url: 'http://localhost:8003'
})
export class ConfiguracionChatResource extends ResourceAuth {
  constructor(resourceHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(resourceHandler, authProv);
  }
// export class ConfiguracionChatResource extends Resource {
//   constructor(resourceHandler: ResourceHandler) {
//     super(resourceHandler);
//   }

  @ResourceAction({
    path: '/configuracion-chat',
    method: ResourceRequestMethod.Patch
  })
  actualizarConfiguracionChat: IResourceMethod<ConfiguracionChatModel, any>;

  @ResourceAction({
    path: '/configuracion-chat',
    method: ResourceRequestMethod.Get
  })
  obtenerConfiguracionChat: IResourceMethod<any, ConfiguracionChatModel>;
}
