import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {AuthService} from "./auth.service";
import {ToastService} from "ng-uikit-pro-standard";
import {AuthServerProvider} from "./auth-jwt.service";

@Injectable()
export class AuthGuardInbox implements CanActivate {

  constructor(private auth: AuthService,
              private router: Router,
              private toastMsg: ToastService,
              private authServ: AuthServerProvider) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log(route)
    return this
      .authServ
      .validacionInbox(route.params.path !== undefined ? route.params.path : route.params.tramite_id);
  }
}
