/**
 * Created by Raul on 20/07/2018.
 */

import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {AuthServerProvider} from '../../../auth/auth-jwt.service';
import {ResourceAuth} from '../../../auth/authenticate.resource';
import {MessageModel} from '../message.model';

@Injectable()
@ResourceParams({
  // url: 'http://localhost:8001'
  pathPrefix: '/notificaciones'
})
// export class ChatRequestResource extends Resource {
export class ChatRequestResource extends ResourceAuth {
 // constructor(handler: ResourceHandler) {
 //   super(handler);
 // }
  constructor(handler: ResourceHandler, auth: AuthServerProvider) {
    super(handler, auth);
  }

  @ResourceAction({
    path: '/na/chat/request'
  })
  getChatRequest: IResourceMethod<any, MessageModel>;

  @ResourceAction({
    path: '/na/chat',
    method: ResourceRequestMethod.Post
  })
  sendMessage: IResourceMethod<MessageModel, any>;

  @ResourceAction({
    path: '/na/chat/accept',
    method: ResourceRequestMethod.Post
  })
  acceptRequest: IResourceMethod<MessageModel, MessageModel>;
}
