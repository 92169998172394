import {Component, OnInit, ViewChild} from '@angular/core';
import {ConfigItem} from '../../common.module/model/config-item.model';
import { PaseCajaCancelModel } from '../service/pase_caja_cancel.model';
import { PaseCajaDetalleComponent } from '../pase-caja-detalle/pase-caja-detalle.component';
import { PaseCajaService } from '../service/pase_caja.service';
import { ToastService } from 'ng-uikit-pro-standard';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'pdfmake/build/vfs_fonts.js';
import { isNullOrUndefined } from 'util';
import { ExcelService } from 'src/app/common.module/util/exportxls.service';

@Component({
  selector: 'app-pases-caja-lista',
  templateUrl: './pases-caja-lista.component.html'
})
export class PasesCajaListaComponent implements OnInit {

  @ViewChild(PaseCajaDetalleComponent) modalCancel;
  public pase_caja: PaseCajaCancelModel = <PaseCajaCancelModel>{
  }
  public pases_caja: PaseCajaCancelModel[] = [];
  public configItems: ConfigItem[] = [];
  public listItems: any[] = [];
  public listToExport: any[] = [];
  public filterModel: any = {
    elemPorPagina: 5,
    pagina: 1,
    forma: 'DESC',
    ordenarPor: '_id'
  };
  dataPdf: any;

  constructor(private paseCajaService: PaseCajaService,
    private toastMsg: ToastService,
    private excel: ExcelService
    ) 
    { }

  ngOnInit() {
    this.configItems = [
      {
        title: 'Número de pase de caja cancelado',
        nameAttribute: 'pase_caja',
        nameFilter: 'pase_caja'
      },
      {
        title: 'Usuario que registró',
        nameAttribute: 'nombre',
        nameFilter: 'nombre'
      },
      {
        title: 'Fecha registro',
        nameAttribute: 'fecha_cancelacion',
        nameFilter: 'fecha_cancelacion'
      },
      {
        title: 'Ver',
        filteringNotAllowed: true,
        enableButtonAction: true,
        iconButtonAction: 'fa-binoculars'
      }
    ];
    this.loadPasesCajaCancel();
  }

  public detalle(item: PaseCajaCancelModel) {
    this.modalCancel.enEdit(true);
    this.modalCancel.btnSearch = true;
    console.log("id: " + item.id);
    this.paseCajaService.obtenerPaseCajaCancel(item.id).then(cat => {
      this.pase_caja = cat;
      return;
    }, err => {
      if (err.body && err.body.message)
        this.toastMsg.info(err.body.message);
      else
        this.toastMsg.error('El detalle del trámite no se ha podido cargar.');
    });
    this.modalCancel.modal.show();
  }

  loadPasesCajaCancel(){

    if(this.filterModel.ordenarPor=="fecha_cancelacion"){
      this.filterModel.ordenarPor = "_id";
    }
    console.log(this.filterModel);

    this.paseCajaService.loadPasesCajaCancelados(this.filterModel).then(pasesCajaCancelados => {
     
      this.pases_caja = pasesCajaCancelados;
      // for (let i = 0; i < pasesCajaCancelados.length; i++) {
      //   this.pases_caja[i].nombre =  this.pases_caja[i].usuario[0].nombre;
      // }      
    }, err => {
      if (err.body && err.body.message)
        this.toastMsg.error(err.body.message);
      else
        this.toastMsg.error('Ocurrió un problema al cargar los pases de caja');
    })
  }

  pdf() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const tmpData: any[] = [
      [{text: 'ID', style: 'header'},
        {text: 'NUMERO PASE CAJA', style: 'header'},
        {text: 'USUARIO QUE REGISTRÓ', style: 'header'},
        {text: 'FECHA REGISTRO', style: 'header'}
      ]
    ];
    let result: PaseCajaCancelModel[];
    if (this.pases_caja.length <= this.filterModel.elemPorPagina)
      result = this.pases_caja;
    else
      result = this.pases_caja.slice(0, (this.filterModel.elemPorPagina));
    result.forEach(itm => {
      console.log(itm);
      itm.id = isNullOrUndefined(itm.id) ? '' : itm.id;
      itm.pase_caja = isNullOrUndefined(itm.pase_caja) ? '' : itm.pase_caja;
      itm.nombre = isNullOrUndefined(itm.nombre) ? '' : itm.nombre;
      itm.fecha_cancelacion = isNullOrUndefined(itm.fecha_cancelacion) ? '' : itm.fecha_cancelacion;
      tmpData.push([
        {text: itm.id, style: 'anotherStyle'},
        {text: itm.pase_caja, style: 'anotherStyle'},
        {text: itm.nombre, style: 'anotherStyle'},
        {text: itm.fecha_cancelacion, style: 'anotherStyle'}
      ]);
    });
    console.log(tmpData);
    this.dataPdf = {
      border: [false, true, false, false],
      fontSize: 8,
      pageOrientation: 'landscape',
      content: [
        {
          table: {
            body: tmpData
          },
          layout: {
            fillColor(rowIndex, node, colIndex) {
              return rowIndex % 2 === 0 ? '#e9e9e9' : '#fff';
            }
          }
        }
      ],
      styles: {
        header: {
          fontSize: 12,
          bold: true,
          alignment: 'center',
          fillColor: '#3F729B',
          color: '#ffffff'
        },
        anotherStyle: {
          fontSize: 10,
          italic: true,
          alignment: 'left'
        }
      }
    };
    if (this.dataPdf.content[0].table.body.length > 1) {
      pdfMake.createPdf(this.dataPdf).download('Pases_caja.pdf');
    } else {
      this.toastMsg.error('No se puede generar el PDF, no hay datos');
    }
  }

  xls() {
    let excelList: PaseCajaCancelModel[];
    if (this.pases_caja.length <= this.filterModel.elemPorPagina)
      excelList = this.pases_caja;
    else
      excelList = this.pases_caja.slice(0, (this.filterModel.elemPorPagina));
    const result = excelList.map(obj => {
      return {
        ID: obj.id,
        NUMERO_PASE_CAJA: obj.pase_caja,
        USUARIO_REGISTRO: obj.nombre,
        FECHA_CANCELACION: obj.fecha_cancelacion,
      };
    });
    console.log(result);
    if (result.length > 0) {
      console.log('Convertiremos a XLS');
      this.excel.exportAsExcelFile(result, 'Pases_caja');
    } else {
      this.toastMsg.error('No se puede generar el XLS, no hay datos');
    }
  }



  cleanModal(){
    this.pase_caja = <PaseCajaCancelModel> {
    }
  }

  createModal(create){
    // this.modalCancel.addControls();
    //  this.user.cumpleanios = '';
    //  create.rolSelected = null;
      this.modalCancel.enBusqueda(true);
      this.modalCancel.btnSearch = false;
      create.modal.show();
    //  create.formUser.reset();
    //  this.modalD.disEdit = false;
  }

}
