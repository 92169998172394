import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {Injectable} from '@angular/core';
import {PagoEnLineaModel} from './pago-en-linea.model';
import {AuthServerProvider} from '../../../auth/auth-jwt.service';
import {ResourceAuth} from '../../../auth/authenticate.resource';

/**
 * Created by dorozco on 28/11/2019.
 */

@Injectable()
@ResourceParams({
  pathPrefix: '/pagos'
  // url: 'http://localhost:8006'
})
// export class PagoEnLineaResource extends Resource {
export class PagoEnLineaResource extends ResourceAuth {
  // constructor(handler: ResourceHandler) {
  //   super(handler);
  // }
  constructor(handler: ResourceHandler, authProv: AuthServerProvider) {
    super(handler, authProv);
  }

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/hash/generar'
  })
  generarHash: IResourceMethod<PagoEnLineaModel, PagoEnLineaModel>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/na/info-pago'
  })
  getInfo: IResourceMethod<any, PagoEnLineaModel>;
}
