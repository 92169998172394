import {NgModule} from '@angular/core';
import {AppCommonLibsModule} from '../common.module/common.module';
import {ConsultaResolutivoRouting} from '../portal.consulta-resolutivo/consulta-resolutivo.routing';
import {DirectivasModule} from '../directivas.module/directivas.module';
import {ConsultaResolutivoResource} from '../portal.consulta-resolutivo/service/consulta-resolutivo.resource';
import {ConsultaResolutivoService} from '../portal.consulta-resolutivo/service/consulta-resolutivo.service';
import {PdfXmlResource} from '../portal.consulta-pago.module/service/pdfXml.resource';
import {ConsultaResolutivoComponent} from './consulta-resolutivo/portal.consulta-resolutivo.component';

@NgModule({
  declarations: [
    ConsultaResolutivoComponent
  ],
  imports: [
    AppCommonLibsModule,
    ConsultaResolutivoRouting,
    DirectivasModule
  ],
  providers: [
    ConsultaResolutivoResource,
    ConsultaResolutivoService,
   // PdfXmlResource
  ]
})
export class ConsultaResolutivoModule {
}
