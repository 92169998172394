import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {Injectable} from '@angular/core';
import {State} from '../model/location/state.model';
import {County} from '../model/location/county.model';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';
import { AuthCorregidoraResource } from '../auth/auth-corregidora.resource';

@Injectable()
@ResourceParams({
  pathPrefix: '/formalities'
  // url: 'http://localhost:8096'
})
export class FormalityValidateMcResource extends ResourceAuth {
  constructor(requestHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(requestHandler, authProv);
  }

  // export class FormalityValidateMcResource extends Resource {
  //  constructor(requestHandler: ResourceHandler) {
  //  super(requestHandler);
  //  }

  @ResourceAction({
    path: '/formality/block/{!id}',
    method: ResourceRequestMethod.Put
  })
  blockingFormality: IResourceMethod<{ id: any }, any>;

  @ResourceAction({
    path: '/formality/block/{!id}'
  })
  getFormality: IResourceMethod<{ id: any }, any>;
}
