import { Injectable } from '@angular/core';
import {ConfiguracionGeneralResource} from '../resource/configuracion-general.resource';
import {ConfiguracionGeneralModel} from '../model/configuracion-general.model';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionGeneralService {

  constructor(private configuracionGeneralResource: ConfiguracionGeneralResource) { }

  obtenerConfiguracionGeneral(): Promise<ConfiguracionGeneralModel> {
    return this.configuracionGeneralResource.obtenerConfiguracionGeneral();
  }
  
}