import {Injectable} from '@angular/core';
import {
  IResourceMethod,
  Resource,
  ResourceAction,
  ResourceHandler,
  ResourceParams,
  ResourceRequestMethod
} from '@ngx-resource/core';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';
import {RegistraFirmaModel} from "../../common.module/service/registra-firma.model";
import {RelacionIngresoModel} from "../../admin.bpm.vu.module/configuracion.flujo.component/service/model/relacion_ingreso.model";
import {ConsultaPagoModel} from "./consulta-pago.model";

@Injectable()
@ResourceParams({
  pathPrefix: '/pagos'
  // url: 'http://localhost:8006'
})
// export class ConsultaPagoResource extends Resource {
export class ConsultaPagoResource extends ResourceAuth {
  constructor(handler: ResourceHandler, auth: AuthServerProvider) {
    super(handler, auth);
  }

  // constructor(requestHandler: ResourceHandler) {
  //   super(requestHandler);
  // }

  @ResourceAction({
    path: '/na/consulta-info-recibo',
    method: ResourceRequestMethod.Get
  })
  obtenerInfoRecibo: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/na/consulta-info-recibo-configurable',
    method: ResourceRequestMethod.Get
  })
  obtenerInfoReciboConfigurable: IResourceMethod<any, any>;
}
