import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {ResourceAuth} from '../auth/authenticate.resource';
import {AuthServerProvider} from '../auth/auth-jwt.service';
import {CaseFileModel} from '../components/upload-requirements.component/service/case-file-uploaded.model';
/**
 * Created by MTI on 09/10/2018.
 */
@Injectable()
@ResourceParams({
  pathPrefix: '/sim'
  // url: 'http://localhost:8072'
})
export class ResolutiveResource extends ResourceAuth {
  constructor(requestHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(requestHandler, authProv);
  }

// export class ResolutiveResource extends Resource {
//   constructor(requestHandler: ResourceHandler) {
//     super(requestHandler);
//   }
  @ResourceAction({
    path: '/getNsave',
    method: ResourceRequestMethod.Post
  })
  getRes: IResourceMethod<any, CaseFileModel>;
}
