import {Injectable} from '@angular/core';
import {
  IResourceMethod,
  Resource,
  ResourceAction,
  ResourceHandler,
  ResourceParams,
  ResourceRequestMethod,
} from '@ngx-resource/core';
import {ResourceAuth} from '../../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../../common.module/auth/auth-jwt.service';
import {BpmEstadoProcesoDetalleModel} from './bpm-estado-proceso-detalle.model';
import {RegistraFirmaModel} from '../../../common.module/service/registra-firma.model';
// carpeta

@Injectable()
@ResourceParams({
  pathPrefix: '/ven-unica'
  // url: 'http://localhost:8006'
})
// export class BpmEstadoProcesoDetalleResource extends Resource {
export class BpmEstadoProcesoDetalleResource extends ResourceAuth {
  constructor(restHandler: ResourceHandler, provService: AuthServerProvider) {
    super(restHandler, provService);
  }

  /* constructor(restHandler: ResourceHandler) {
    super(restHandler);
  }*/

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    isArray: true,
    path: '/bmp-estado-proceso-detalle'
  })
  postBmpEstadoProcesoDetalle: IResourceMethod<BpmEstadoProcesoDetalleModel, any>;

  @ResourceAction({
    path: '/archivo-entidad',
    method: ResourceRequestMethod.Post
  })
  postGuardarFirmarArchivo: IResourceMethod<RegistraFirmaModel, any>;

  @ResourceAction({
    path: '/bmp-estado-proceso-detalle/{!_id}',
    method: ResourceRequestMethod.Patch
  })
  patchBmpEstadoProcesoDetalle: IResourceMethod<BpmEstadoProcesoDetalleModel, any>;

  @ResourceAction({
    path: '/bmp-est-proc-det-actdatadic/{!_id}',
    method: ResourceRequestMethod.Patch
  })
  patchBpmEstProcDetActDatAdic: IResourceMethod<BpmEstadoProcesoDetalleModel, any>;

  @ResourceAction({
    path: '/bmp-estado-proceso-detalle/{!id}',
    method: ResourceRequestMethod.Delete
  })
  deleteBmpEstadoProcesoDetalle: IResourceMethod<{ id: string }, any>;

  @ResourceAction({
    path: '/bmp-lista-estado-proceso-detalle/{!id}',
    method: ResourceRequestMethod.Get
  })
  getListaBmpEstadoProcesoDetallePorIdMaster: IResourceMethod<{id: string}, BpmEstadoProcesoDetalleModel[]>;

  @ResourceAction({
    path: '/estado-proceso-documento/{!id}',
    method: ResourceRequestMethod.Get
  })
  getEstadoProcesoDoc: IResourceMethod<{id:string}, RegistraFirmaModel>;

}
