<div class="row align-items-stretch">
  <div class="col-12">
    <div class="card mt-lg-3 mt-sm-3">
      <div mti-color-theme class="card-header unique-color lighten-1 white-text">
        <div class="row">
          <div class="col-auto mr-auto align-self-center"><strong>Usuarios</strong></div>
          <div class="switch col-auto ml-auto align-self-center"  *ngIf="rolAdm == '5db89adb54f3470105730c8e'" [ngClass]="{disabled: disEdit}">
            <label>
              Doble Autentificación
              <input type="checkbox"  [checked]="isCheck === true" [(ngModel)]="isCheck"
                     (change)="actualizarDA()">
              <span class="lever"></span>
            </label>
          </div>
          <div class="col-auto align-self-center mr-0 pr-0">
            <a class="btn-floating btn-sm red" (click)="pdf()" mdbTooltip="Descargar pdf" placement="bottom"
               mdbWavesEffect><i class="far fa-file-pdf white-text" aria-hidden="true"></i></a>
            <a class="btn-floating btn-sm green" (click)="xls()" mdbTooltip="Descargar excel" placement="bottom"
               mdbWavesEffect><i class="far fa-file-excel white-text" aria-hidden="true"></i></a>
          </div>
          <div class="col-auto ml-0 pl-0 col-uno-btn">
            <button class="btn btn-sm btn-deep-orange waves-light btn-cat-principal"
                    (click)="createModal(createUserModal); cleanModal();" mdbWavesEffect="">
              <i class="fa fa-plus mr-1" aria-hidden="true"></i>
              Nuevo
            </button>
          </div>
        </div>
      </div>
      <app-list-items [configItems]="configItems"
                      [list]="listItems"
                      [listToExport]="listToExport"
                      [filter]="filterModel"
                      [disableValidate]="true"
                      [storeFilterHistory]="false"
                      [emptyListMessage]="'No se han encontrado coincidencias de usuarios.'"
                      (actionEmitterCallback)="cleanModal(); actionEmmiterCallback($event, createUserModal)"
                      [disableTableResponsive]="false"
                      (load)="loadUsers()"></app-list-items>
    </div>
  </div>
</div>
<app-user-detail #createUserModal [user]="user"></app-user-detail>
