import { Injectable } from '@angular/core';
import {ZonaModel} from './zona.model';
import {ZonaResource} from './zona.resource';

@Injectable({
  providedIn: 'root'
})
export class ZonaService {

  constructor(private zonaResource: ZonaResource) {}

  public lodZona(id: string): Promise<ZonaModel> {
    return this.zonaResource.lodZona({id: id});
  }

  public lodListaZonas(filtros: any): Promise<ZonaModel[]> {
    return this.zonaResource.lodListaZonas(filtros);
  }

  public lodListaZonasNa(filtros: any): Promise<ZonaModel[]> {
    return this.zonaResource.lodListaZonasNa(filtros);
  }

  public crtZona(zona: ZonaModel): Promise<any> {
    return this.zonaResource.crtZona(zona);
  }

  public updZona(zona: ZonaModel): Promise<any> {
    return this.zonaResource.updZona(zona);
  }
}
