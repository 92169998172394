import {Injectable} from '@angular/core';
import {PagoEnLineaResource} from './pago-en-linea.resource';
import {PagoEnLineaModel} from './pago-en-linea.model';

@Injectable({
  providedIn: 'root'
})
export class PagoEnLineaService {

  constructor(private pagoEnLineaResource: PagoEnLineaResource) {
  }

  /**
   * Llamada al back para generar el hash a partir de la información de pago.
   * @param pagoEnLinea Objeto con la información del pago
   * @return El mismo objeto enviado, pero con el hash generado
   */
  generarHash(pagoEnLinea: PagoEnLineaModel): Promise<PagoEnLineaModel> {
    return this.pagoEnLineaResource.generarHash(pagoEnLinea);
  }

  getInfo(): Promise<PagoEnLineaModel> {
    return this.pagoEnLineaResource.getInfo();
  }
}
