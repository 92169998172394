/**
 * Created by MTI on 04/05/2018.
 */
 
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthResourceConsumos} from './resource/auth.resource';
import {LoginModel} from './model/login.model';
import {AuthServerProvider} from '../common.module/auth/auth-jwt.service';
import {Router} from '@angular/router';
import {ToastService} from 'ng-uikit-pro-standard';
import {LoginServiceConsumo} from './service/login.service';
import {ConfiguracionGeneralModel} from './model/configuracion-general.model';
import {ConfiguracionGeneralServiceConsumos} from './service/configuracion-general.service';
import { isUndefined } from 'util';
import {AuthServerProviderConsumos} from '../common.module/auth/auth-jwt-consumos.service';
import {UserAuthConsumos} from '../common.module/auth/user-auth-consumos.model';

@Component({
  selector: 'app-main-login-app-consumos',
  templateUrl: 'main-login-app-consumos.component.html'
})
export class MainLoginConsumosComponent implements OnInit {
  public loginForm: FormGroup;
  public authModel: LoginModel = <LoginModel>{};
  networkMode = 'online';
  public configuracion: ConfiguracionGeneralModel = {};
  public esDobleAuth = false;
  public marker: any = {};
  public themes: any = {};
  public user: UserAuthConsumos;
  constructor(private fb: FormBuilder,
              private loginService: LoginServiceConsumo,
              private authResource: AuthResourceConsumos,
              private toastMsg: ToastService,
              private authProvider: AuthServerProvider,
              private configuracionGeneralService: ConfiguracionGeneralServiceConsumos,
              private router: Router,
              private authServerProvider: AuthServerProviderConsumos) {
    navigator.onLine === true ? this.networkMode = 'online' : this.networkMode = 'offline';
  }

  ngOnInit() {



    this.configuracionGeneralService.obtenerConfiguracionGeneral().then(configuracion => {
      this.configuracion = configuracion;
      let i = 0;
    
      if (this.configuracion.ubicacionLongitud && this.configuracion.ubicacionLatitud) {
        this.marker = {latitude: this.configuracion.ubicacionLatitud, longitude: this.configuracion.ubicacionLongitud};
      } else {
        this.configuracion.ubicacionLongitud = -90.53974092006683;
        this.configuracion.ubicacionLatitud = 19.844943580379265;
      }
      
      if (typeof this.themes[configuracion.tema] !== 'undefined') {
        this.configuracion.tema = configuracion.tema;
        /* Verificar si el tema seleccionado de base es personalizado */
        if (configuracion.tema == 'personalizado') {
          this.themes.personalizado.fondo.color1.codigo = configuracion.groupElements1;
          this.themes.personalizado.fondo.color2.codigo = configuracion.groupElements2;
          this.themes.personalizado.fondo.color3.codigo = configuracion.groupElements3;
          this.themes.personalizado.fondo.color4.codigo = configuracion.groupElements4;
          this.themes.personalizado.texto.color1.codigo = configuracion.color1;
          this.themes.personalizado.texto.color2.codigo = configuracion.color2;
          this.themes.personalizado.texto.color3.codigo = configuracion.color3;
          this.themes.personalizado.texto.color4.codigo = configuracion.color4;
        }
      } else {
        this.configuracion.tema = 'default';
      }
     
    });


    this.loginForm = this.fb.group({
      email: ['', Validators.compose([Validators.email, Validators.required])],
      password: ['', [Validators.required]],
      codigo: ['', Validators.pattern('^[0-9]+')]
    });
    this.configuracionGeneralService.obtenerConfiguracionGeneral().then(configuracion => {
      this.configuracion = configuracion;
    }, err => {
      console.log('Error al cargar la configuración en el pie de página');
      console.error(err);
    });
  }

  login() {
    console.log("en nuestro metodo login");
    let codigo = this.loginForm.controls['codigo'].value.toString();
    if(codigo != ""){
      this.loginForm.controls['codigo'].setValue(codigo);
    }
    this.authResource.login(<LoginModel> this.loginForm.value).then(res => {
      console.log(res);
      if(res.token != undefined){
        this.esDobleAuth = false;
      this.authProvider.storeAuthenticationToken(res.token, false);

      this.user = this.authServerProvider.getUserDecoded();
      console.log(this.user);
      this.toastMsg.success('Hemos iniciado sesión.');
      if(this.user.is_admin){
        this.router.navigate(['app-consumos']);
      }else{
        this.router.navigate(['app-consumos/consumos']);
      }

     
      
    }else{
     this.toastMsg.success("Código de autentificación enviado a tu correo electrónico.");
      this.esDobleAuth = true;
    }
      // Reditrect app
      // Save token locastorage
    }, err => {
      // Mensaje de error
      console.log(err);
      if (err && err.body && err.body.message)
        this.toastMsg.error(err.body.message);
    });
  }
}
