/**
 * Created by Raul on 03/05/2018.
 */
import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {UsuarioModel} from "./usuario.model";

@Injectable()
@ResourceParams({
  // url: 'http://localhost:8001'
  pathPrefix: '/administracion'
})
// export class UsuarioResource extends Resource {
//   constructor(handler: ResourceHandler) {
//    super(handler);
//   }
export class UsuarioResource extends ResourceAuth {
  constructor(restHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(restHandler, authProv);
  }

  @ResourceAction({
    path: '/lista-usuarios'
  })
  obtenerListaUsuarios: IResourceMethod<any, UsuarioModel[]>;
}
