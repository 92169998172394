<div mdbModal [config]="{backdrop: 'static'}" #detExpElectModal="mdb-modal" class="modal fade overflow-auto" tabindex="-1" role="textbox"
     aria-labelledby="myBasicModalLabel" aria-hidden="true" (onShow)="limpiarDatos()">
  <div class="modal-dialog mw-90i" role="textbox">
    <div class="modal-content">
      <div mti-color-theme class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="detExpElectModal.hide()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">Detalle expediente electrónico</h4>
      </div>
      <div class="modal-body">
        <div class="row justify-content-center mb-3">
          <div class="col-12 col-lg-9 d-flex"></div>
          <div class="col-12 col-lg-3 d-flex" *ngIf="expedienteElect.estatus != 'A' && main_action == 1">
            <button mdbBtn type="button" color="primary" rounded="true" outline="true" mdbWavesEffect size="sm"
                    class="ml-auto" [disabled]="revisarRequirements().length != revisarAprobadosRequirements().length" (click)="aprobarExpediente()">
              <i class="fa mr-1" aria-hidden="true"></i>
              Aprobar
            </button>
          </div>
          <!--<div class="col-12 col-lg-3 d-flex">-->
            <!--<button mdbBtn type="button" *ngIf="expedienteElect.estatus != 'A' && main_action == 1" color="primary" rounded="true" outline="true" mdbWavesEffect size="sm"-->
                    <!--class="ml-auto" (click)="habilitarEdicion()">-->
              <!--<i class="fa mr-1" aria-hidden="true" [ngClass]="{'fa-eye': editando, 'fa-edit': !editando}"></i>-->
              <!--{{editar_title}}-->
            <!--</button>-->
          <!--</div>-->
          <div class="row col-12">
            <label><b>Estatus del expediente: {{expedienteElect.estatus == 'A' ? 'Aprobado': 'Pendiente'}}</b></label>
          </div>
        </div>
        <mdb-tabset mti-color-theme #staticTabs [buttonClass]="'classic-tabs tabs-cyan'" [contentClass]="'card'" class="classic-tabs">
          <mdb-tab heading="Datos" >
            <form [formGroup]="detExpedienteForm">
              <div class="row justify-content-end">
                <div class="md-form col-12 col-lg-8">
                  <mdb-select id="detUsuario"
                              formControlName="formUsuario"
                              notFoundMsg="Sin resultados"
                              label="Correo Usuario"
                              [(ngModel)]="expedienteElect.usuarios_id"
                              [filterEnabled]="true"
                              [options]="listaCorreo"></mdb-select>
                  <div *ngIf="detExpedienteForm.controls['formTipoPersona'].touched">
                    <div *ngIf="detExpedienteForm.controls['formTipoPersona'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      Campo requerido
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="field">
                  <div class="md-form col-12"  [ngClass]="{'col-lg-6': desPerFisica, 'col-lg-6': desPerMoral}">
                    <mdb-select id="detTipoPersona"
                                formControlName="formTipoPersona"
                                label="Tipo persona"
                                notFoundMsg="Sin resultados"
                                [(ngModel)]="expedienteElect.tipo_persona"
                                (selected)="cambioPersona()"
                                [options]="optTipoPersona"></mdb-select>
                    <div *ngIf="detExpedienteForm.controls['formTipoPersona'].touched">
                      <div *ngIf="detExpedienteForm.controls['formTipoPersona'].errors?.required"
                           class="text-danger animated fadeInDown fast-4 small">
                        Campo requerido
                      </div>
                    </div>
                  </div>
                <div class="md-form col-12 col-lg-6" *ngIf="desPerFisica">
                  <input mdbInput type="text" formControlName="formCurp"
                         [(ngModel)]="expedienteElect.curp" id="detCurp"
                         (change)="limpiarINE()"
                         class="form-control text-uppercase">
                  <label for="detCurp" class="col-lg-12">CURP</label>
                  <div *ngIf="detExpedienteForm.controls['formCurp'].touched">
                    <div *ngIf="detExpedienteForm.controls['formCurp'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      Campo requerido
                    </div>
                    <div class="text-danger animated fadeInDown fast-4 small"
                         *ngIf="detExpedienteForm.controls['formCurp'].errors?.pattern">
                      Formato Incorrecto
                    </div>
                  </div>
                </div>
                <div class="md-form col-12 col-lg-6" *ngIf="desPerFisica">
                    <input mdbInput type="text" formControlName="formIne"
                           [(ngModel)]="expedienteElect.ine" id="detIne"
                           class="form-control text-uppercase">
                    <label for="detIne" class="col-lg-12">Clave de elector</label>
                  <div *ngIf="detExpedienteForm.controls['formIne'].touched">
                    <div *ngIf="detExpedienteForm.controls['formIne'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      Campo requerido
                    </div>
                    <div class="text-danger animated fadeInDown fast-4 small"
                         *ngIf="detExpedienteForm.controls['formIne'].errors?.pattern">
                      Formato Incorrecto
                    </div>
                  </div>
                  </div>
                  <div class="md-form col-12" [ngClass]="{'col-lg-6': desPerFisica, 'col-lg-6': desPerMoral}">
                    <input mdbInput type="text" formControlName="formRfc"
                           [(ngModel)]="expedienteElect.rfc" id="detRfc"
                           class="form-control text-uppercase">
                    <label for="detRfc" class="col-lg-12">RFC</label>
                    <div *ngIf="detExpedienteForm.controls['formRfc'].touched">
                      <div *ngIf="detExpedienteForm.controls['formRfc'].errors?.required"
                           class="text-danger animated fadeInDown fast-4 small">
                        Campo requerido
                      </div>
                      <div class="text-danger animated fadeInDown fast-4 small"
                           *ngIf="detExpedienteForm.controls['formRfc'].errors?.pattern">
                        Formato Incorrecto
                      </div>
                    </div>
                  </div>
              </div>
              <div class="row" *ngIf="!field">
                  <div class="md-form col-12"  [ngClass]="{'col-lg-4': desPerFisica, 'col-lg-6': desPerMoral}">
                    <mdb-select id="detTipoPersonaL"
                                formControlName="formTipoPersona"
                                label="Tipo persona"
                                notFoundMsg="Sin resultados"
                                [(ngModel)]="expedienteElect.tipo_persona"
                                (selected)="cambioPersona()"
                                [options]="optTipoPersona"></mdb-select>
                    <div *ngIf="detExpedienteForm.controls['formTipoPersona'].touched">
                      <div *ngIf="detExpedienteForm.controls['formTipoPersona'].errors?.required"
                           class="text-danger animated fadeInDown fast-4 small">
                        Campo requerido
                      </div>
                    </div>
                  </div>
                  <div class="md-form col-12 col-lg-4" *ngIf="desPerFisica">
                    <input mdbInput type="text" formControlName="formCurp"
                           [(ngModel)]="expedienteElect.curp" id="detCurp"
                           class="form-control text-uppercase">
                    <label for="detCurp" class="col-lg-12">CURP</label>
                    <div *ngIf="detExpedienteForm.controls['formCurp'].touched">
                      <div *ngIf="detExpedienteForm.controls['formCurp'].errors?.required"
                           class="text-danger animated fadeInDown fast-4 small">
                        Campo requerido
                      </div>
                      <div class="text-danger animated fadeInDown fast-4 small"
                           *ngIf="detExpedienteForm.controls['formCurp'].errors?.pattern">
                        Formato Incorrecto
                      </div>
                    </div>
                  </div>
                  <div class="md-form col-12" [ngClass]="{'col-lg-4': desPerFisica, 'col-lg-6': desPerMoral}">
                    <input mdbInput type="text" formControlName="formRfc"
                           [(ngModel)]="expedienteElect.rfc" id="detRfc"
                           class="form-control text-uppercase">
                    <label for="detRfc" class="col-lg-12">RFC</label>
                    <div *ngIf="detExpedienteForm.controls['formRfc'].touched">
                      <div *ngIf="detExpedienteForm.controls['formRfc'].errors?.required"
                           class="text-danger animated fadeInDown fast-4 small">
                        Campo requerido
                      </div>
                      <div class="text-danger animated fadeInDown fast-4 small"
                           *ngIf="detExpedienteForm.controls['formRfc'].errors?.pattern">
                        Formato Incorrecto
                      </div>
                    </div>
                  </div>
              </div>
              <div class="row" *ngIf="desPerFisica">
                <div class="md-form col-12 col-lg-6">
                  <input mdbInput type="text" formControlName="formApPaterno"
                         [(ngModel)]="expedienteElect.apellido_p" id="detApPaterno"
                         class="form-control text-uppercase">
                  <label for="detApPaterno" class="col-lg-12">Apellido paterno</label>
                  <div *ngIf="detExpedienteForm.controls['formApPaterno'].touched">
                    <div *ngIf="detExpedienteForm.controls['formApPaterno'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small ">
                      Campo requerido
                    </div>
                    <div class="text-danger animated fadeInDown fast-4 small"
                         *ngIf="detExpedienteForm.controls['formApPaterno'].errors?.pattern">
                      Formato Incorrecto
                    </div>
                  </div>
                </div>
                <div class="md-form col-12 col-lg-6">
                  <input mdbInput type="text" formControlName="formApMaterno"
                         [(ngModel)]="expedienteElect.apellido_m" id="detApMaterno"
                         class="form-control text-uppercase">
                  <label for="detApMaterno" class="col-lg-12">Apellido materno</label>
                  <div *ngIf="detExpedienteForm.controls['formApMaterno'].touched">
                    <div *ngIf="detExpedienteForm.controls['formApMaterno'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      Campo requerido
                    </div>
                    <div class="text-danger animated fadeInDown fast-4 small"
                         *ngIf="detExpedienteForm.controls['formApMaterno'].errors?.pattern">
                      Formato Incorrecto
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="desPerFisica">
                <div class="md-form col-12 col-lg-12">
                  <input mdbInput type="text" formControlName="formName"
                         [(ngModel)]="expedienteElect.nombre" id="detName"
                         class="form-control text-uppercase">
                  <label for="detName" class="col-lg-12">Nombre</label>
                  <div *ngIf="detExpedienteForm.controls['formName'].touched">
                    <div *ngIf="detExpedienteForm.controls['formName'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      Campo requerido
                    </div>
                    <div class="text-danger animated fadeInDown fast-4 small"
                         *ngIf="detExpedienteForm.controls['formName'].errors?.pattern">
                      Formato Incorrecto
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="desPerMoral">
                <div class="md-form col-12 col-lg-12">
                  <input mdbInput type="text" formControlName="formProp"
                         [(ngModel)]="expedienteElect.propietario" id="detProp"
                         class="form-control text-uppercase">
                  <label for="detProp" class="col-lg-12">Propietario</label>
                  <div *ngIf="detExpedienteForm.controls['formProp'].touched">
                    <div *ngIf="detExpedienteForm.controls['formProp'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small text-uppercase">
                      Campo requerido
                    </div>
                    <div class="text-danger animated fadeInDown fast-4 small"
                         *ngIf="detExpedienteForm.controls['formProp'].errors?.pattern">
                      Formato Incorrecto
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="desPerMoral">
                <div class="md-form col-12 col-lg-12">
                  <input mdbInput type="text" formControlName="formRazonSoc"
                         [(ngModel)]="expedienteElect.razon_social" id="detRazonSoc"
                         class="form-control">
                  <label for="detRazonSoc" class="col-lg-12">Razón social</label>
                  <div *ngIf="detExpedienteForm.controls['formRazonSoc'].touched">
                    <div *ngIf="detExpedienteForm.controls['formRazonSoc'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small text-uppercase">
                      Campo requerido
                    </div>
                    <div class="text-danger animated fadeInDown fast-4 small"
                         *ngIf="detExpedienteForm.controls['formRazonSoc'].errors?.pattern">
                      Formato Incorrecto
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-lg-12">
                  <h3>Domicilio</h3>
                </div>
              </div>
              <div class="row">
                <div class="md-form col-12 col-lg-4">
                  <mdb-select id="detEstado"
                              notFoundMsg="Sin resultados"
                              label="Estado"
                              formControlName="formEstado"
                              [filterEnabled]="true"
                              (selected)="buscarListaMunicipios()"
                              [(ngModel)]="location.key"
                              [options]="optEstado"></mdb-select>
                  <!--[(ngModel)]="listaElementos.tipo_persona"
                  [options]="optTipoPersona"-->
                  <div *ngIf="detExpedienteForm.controls['formEstado'].touched">
                    <div *ngIf="detExpedienteForm.controls['formEstado'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      Campo requerido
                    </div>
                  </div>
                </div>
                <div class="md-form col-12 col-lg-4">
                  <mdb-select id="detMunicipio"
                              formControlName="formMunicipio"
                              notFoundMsg="Sin resultados"
                              label="Municipio"
                              [filterEnabled]="true"
                              [(ngModel)]="location.municipality_id"
                              [options]="optMunicipio"></mdb-select>
                  <!--[(ngModel)]="listaElementos.tipo_persona"
                  [options]="optTipoPersona"-->
                  <div *ngIf="detExpedienteForm.controls['formMunicipio'].touched">
                    <div *ngIf="detExpedienteForm.controls['formMunicipio'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      Campo requerido
                    </div>
                  </div>
                </div>
                <div class="md-form col-12 col-lg-4">
                  <input mdbInput type="text" formControlName="formCiudad"
                         [(ngModel)]="expedienteElect.ciudad" id="detCiudad"
                         class="form-control text-uppercase">
                  <label for="detCiudad" class="col-lg-12">Ciudad</label>
                  <div *ngIf="detExpedienteForm.controls['formCiudad'].touched">
                    <div *ngIf="detExpedienteForm.controls['formCiudad'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      Campo requerido
                    </div>
                    <div class="text-danger animated fadeInDown fast-4 small"
                         *ngIf="detExpedienteForm.controls['formCiudad'].errors?.pattern">
                      Formato Incorrecto
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="md-form col-12 col-lg-6">
                  <input mdbInput type="text" formControlName="formCalle"
                         [(ngModel)]="expedienteElect.calle" id="detCalle"
                         class="form-control text-uppercase">
                  <label for="detCalle" class="col-lg-12">Calle</label>
                  <div *ngIf="detExpedienteForm.controls['formCalle'].touched">
                    <div *ngIf="detExpedienteForm.controls['formCalle'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      Campo requerido
                    </div>
                    <div class="text-danger animated fadeInDown fast-4 small"
                         *ngIf="detExpedienteForm.controls['formCalle'].errors?.pattern">
                      Formato Incorrecto
                    </div>
                  </div>
                </div>
                <div class="md-form col-12 col-lg-6">
                  <input mdbInput type="text" formControlName="formColonia"
                         [(ngModel)]="expedienteElect.colonia" id="detColonia"
                         class="form-control text-uppercase">
                  <label for="detColonia" class="col-lg-12">Colonia</label>
                  <div *ngIf="detExpedienteForm.controls['formColonia'].touched">
                    <div *ngIf="detExpedienteForm.controls['formColonia'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      Campo requerido
                    </div>
                    <div class="text-danger animated fadeInDown fast-4 small"
                         *ngIf="detExpedienteForm.controls['formColonia'].errors?.pattern">
                      Formato Incorrecto
                    </div>
                  </div>
                </div>
                <div class="md-form col-12 col-lg-3">
                  <input mdbInput type="text" formControlName="formNumExt"
                         [(ngModel)]="expedienteElect.num_ext" id="detNumExt"
                         class="form-control text-uppercase">
                  <label for="detNumExt" class="col-lg-12">Núm. Ext.</label>
                  <div *ngIf="detExpedienteForm.controls['formNumExt'].touched">
                    <div *ngIf="detExpedienteForm.controls['formNumExt'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      Campo requerido
                    </div>
                    <div class="text-danger animated fadeInDown fast-4 small"
                         *ngIf="detExpedienteForm.controls['formNumExt'].errors?.pattern">
                      Formato Incorrecto
                    </div>
                  </div>
                </div>
                <div class="md-form col-12 col-lg-3">
                  <input mdbInput type="text" formControlName="formNumInt"
                         [(ngModel)]="expedienteElect.num_int" id="detNumInt"
                         class="form-control">
                  <label for="detNumInt" class="col-lg-12">Núm. Int.</label>
                  <div *ngIf="detExpedienteForm.controls['formNumInt'].touched">
                    <div *ngIf="detExpedienteForm.controls['formNumInt'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      Campo requerido
                    </div>
                    <div class="text-danger animated fadeInDown fast-4 small"
                         *ngIf="detExpedienteForm.controls['formNumInt'].errors?.pattern">
                      Formato Incorrecto
                    </div>
                  </div>
                </div>
                <div class="md-form col-12 col-lg-3">
                  <input mdbInput type="text" formControlName="formLetra"
                         [(ngModel)]="expedienteElect.letra" id="detLetra"
                         class="form-control text-uppercase">
                  <label for="detLetra" class="col-lg-12">Letra</label>
                  <div *ngIf="detExpedienteForm.controls['formLetra'].touched">
                    <div *ngIf="detExpedienteForm.controls['formLetra'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      Campo requerido
                    </div>
                    <div class="text-danger animated fadeInDown fast-4 small"
                         *ngIf="detExpedienteForm.controls['formLetra'].errors?.pattern">
                      Formato Incorrecto
                    </div>
                  </div>
                </div>
                <div class="md-form col-12 col-lg-3">
                  <input mdbInput type="text" formControlName="formCp"
                         [(ngModel)]="expedienteElect.cp" id="detCp" maxlength="5"
                         class="form-control">
                  <label for="detCp" class="col-lg-12">C.P.</label>
                  <div *ngIf="detExpedienteForm.controls['formCp'].touched">
                    <div *ngIf="detExpedienteForm.controls['formCp'].errors?.required"
                         class="text-danger animated fadeInDown fast-4 small">
                      Campo requerido
                    </div>
                    <div class="text-danger animated fadeInDown fast-4 small"
                         *ngIf="detExpedienteForm.controls['formCp'].errors?.pattern">
                      Formato Incorrecto
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </mdb-tab>
          <mdb-tab heading="Documentos">
            <div class="col-12 col-lg-12">
              <app-upload-requirements #appUploadRequirements
                                       [requirements]="requirements"
                                       [pathUploadUrl]="pathUploadUrl"
                                       [showReq]="showReq"
                                       [disDel]="disDel"
                                       [disAprv]="true"
                                       [enabled]="true"
                                       [estatus_proceso]="expedienteElect.estatus == 'A' ? true : false"
                                       [disCiudadanoAprv]="false"
                                       [fromExpediente]="true"
                                       [blockLoading] = "blockLoading"
                                       [isDownloaded]="isDownloaded"
                                       [validity]="validity"
                                       [main_action] = "main_action"
                                       (deleteRequirement)="dltRequerimiento($event)"
                                       (openValidity)="abrirVigencia($event,CrearVigencia)"
                                       (openObservacion)="abrirObservacion($event,CrearObservacion)">
              </app-upload-requirements>
            </div>
          </mdb-tab>
        </mdb-tabset>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="detExpElectModal.hide();" class="btn btn-danger waves-light btn-md"
                aria-label="Close"
                mdbWavesEffect>
          Cancelar
        </button>
        <button type="button" (click)=" guardarSolicitud()"
                *ngIf="hideBtn"
                class="btn btn-theme-primary indigo darken-3 waves-light btn-md"
                mti-color-theme
                aria-label="Close"
                mdbWavesEffect>
          Guardar
        </button>
      </div>
    </div>
  </div>
</div>
<app-vigencia-exp-elect-detalle #CrearVigencia [(numRequisito)]="numRequisito"
                                [(vigencia)]="vigencia"></app-vigencia-exp-elect-detalle>

<app-observacion-exp-elect-detalle #CrearObservacion  [(numRequisito)]="numRequisito"
                                [(observacion)]="observacion"></app-observacion-exp-elect-detalle>

<div mdbModal #basicConfirmModal="mdb-modal" class="modal fade bottom" tabindex="-1" role="dialog"
     aria-labelledby="myBasicModalLabel" [config]="{backdrop: 'static'}"
     aria-hidden="true">
  <div class="modal-dialog modal-frame modal-bottom" role="document">
    <div class="modal-content">
      <!--Body-->
      <div class="modal-body">
        <div class="row d-flex justify-content-center align-items-center">

          <p class="pt-3 pr-2">¿Desea aprobar el expediente electrónico? Esta operación no se puede deshacer</p>


          <a type="button" class="btn btn-success waves-effect waves-light" (click)="acceptRequest()">Aceptar
            <i class="fa fa-check ml-1"></i>
          </a>
          <a type="button" class="btn btn-outline-danger waves-effect" data-dismiss="modal" (click)="basicConfirmModal.hide()">
            Cancelar
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
