/**
 * Created by OROJO on 10/01/2019.
 */

import {Injectable} from '@angular/core';
import {IResourceAction, IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';
import {CommonFormalitiesConfigModel} from '../model/formalities-config.model';
import {FormalitiesConfigModel} from '../../admin.formalities.module/service/formalitiesConfig.model';
import { AuthCorregidoraService } from '../auth/auth-corregidora.service';

@Injectable()
@ResourceParams({
  pathPrefix: '/coremicro'
  // url: 'http://localhost:8082'
})

export class FormalitiesConfigResource extends ResourceAuth {
// export class FormalitiesConfigResource extends AuthCorregidoraService {
  constructor(restHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(restHandler, authProv);
  }

// export class FormalitiesConfigResource extends Resource {
//   constructor(restHandler: ResourceHandler) {
//     super(restHandler);
//   }

  @ResourceAction({
    path: '/formalities/detail/{!id}'
  })
  getFormalityConfig: IResourceMethod<{ id: any }, CommonFormalitiesConfigModel>;

  @ResourceAction({
    path: '/na/formalities/detail/{!id}'
  })
  getFormalityConfigAll: IResourceMethod<{ id: any }, CommonFormalitiesConfigModel>;
}
