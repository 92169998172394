import {Injectable} from '@angular/core';
import {FlujoResourceR} from './resource/flujo.resource';
import {SeccionesTramitesModel} from './model/secciones.model';


@Injectable({
  providedIn: 'root'
})
export class FlujoServiceR {

  constructor(private flujoResource: FlujoResourceR) {
  }

  getAllFlujos(parametro: any): Promise<any> {
    return this.flujoResource.getFlujos(parametro);
  }

  insertFlujo(post) {
    return this.flujoResource.insertFlujo(post);
  }

  getFlujo(id: string): Promise<any> {
    return this.flujoResource.getFlujo({id: id});
  }

  getFlujoDet(id: string, filterFlujos: any): Promise<any> {
    return this.flujoResource.getFlujoDet({id: id}, filterFlujos);
  }

  getFlujoByAdmTramite(id: string): Promise<any> {
    return this.flujoResource.getFlujoByAdmTramite({id: id});
  }

  updateFlujo(FlujoModel): Promise<any> {
    console.log('*******************************************');
    console.log(FlujoModel);
    return this.flujoResource.updateFlujo(FlujoModel);
  }

  deleteFlujo(id: string): Promise<any> {
    return this.flujoResource.deleteFlujo({id: id});
  }

  getAllTramites() {
    return this.flujoResource.obtTramites();
  }

  obtSeccionesTramites(): Promise<SeccionesTramitesModel[]> {
    return this.flujoResource.obtSecciones();
  }

  getAllTipoCampo() {
    return this.flujoResource.obtTipoCampo();
  }

  obtExpedienteReq() {
    return this.flujoResource.obtExpedienteReq();
  }


  getAllEntidades() {
    return this.flujoResource.obtEntidades();
  }

  dltDetalle(id: string): Promise<any> {
    return this.flujoResource.dltDetalle({id: id});
  }

}
