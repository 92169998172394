/**
 * Created by EMOLINA on 24/10/2018.
 */
import {Injectable} from '@angular/core';
import {UnlockFrmResource} from '../resource/unlock-formality.resource';
@Injectable()
export class UnlockFrmService {
  constructor(private uFrmResource: UnlockFrmResource) {}

  unlockFrm(id: number) {
    return this.uFrmResource.unlockFormality({id: id});
  }
}
