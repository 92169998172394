<!--Modal: Login / Register Form-->
<div mdbModal #formDataUser="mdb-modal" class="modal fade modal-overflow" id="modalLRForm" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" (onShow)="loadUser()" [config]="{backdrop: true, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-md cascading-modal" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div mti-color-theme class="modal-header light-blue darken-3 white-text">
        <img class="img-responsive center-block" [src]="imagenCargada" id="moon" style="background: lightblue;
        border-radius: 50%;
        width: 200px;
        height: 200px;" />
<!--
<div class="image-upload">
  <label for="file-input">
      <i class="fa fa-edit">a</i>  
  </label>
  <input id="file-input" style="display: none;" type="file"/>
</div>
-->
<div class="col-12" style="margin-left:100px;margin-top: -55px;">
  <label for="file-input" class="btn-floating btn-sm grey">
    <i class="fa fa-edit"></i>
  </label>
     <input accept="image" id="file-input" type="file" style="display: none;"
     (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" mdbFileSelect capture>
</div>
        <button type="button" class="close waves-effect waves-light" data-dismiss="modal" aria-label="Close"
                (click)="formDataUser.hide()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <!-- <div class="row" style="margin-top: -15px; margin-bottom: -20px;">
        <div class="col-12 d-flex justify-content-end">
          <a class="btn-floating btn-sm light-blue" mdbtooltip="Ver datos completos empleado"
            mdbTooltip="Ver datos completos" mdbwaveseffect="" (click)="abrirFormularios()"
            placement="bottom" ng-reflect-mdb-tooltip="Formulario" ng-reflect-placement="bottom">
            <i aria-hidden="true" class="far fa-address-card white-text"></i>
          </a>
        </div>           
      </div> -->
      <!--Body-->
      <div class="modal-body">
        <form [formGroup]="formUser">
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="md-form" >
                <input mdbInput type="text" id="usName" class="form-control"
                       formControlName="defaultFormName"
                       [(ngModel)]="user.nombre">
                <label for="usName" class="">Nombre</label>
                <div *ngIf="formUser.controls['defaultFormName'].dirty">
                  <div *ngIf="formUser.controls['defaultFormName'].errors?.required"
                       class="text-danger animated fadeInDown fast-4 small">
                        El nombre del usuario es obligatorio.
                  </div>
                  <div *ngIf="formUser.controls['defaultFormName'].errors?.pattern"
                       class="text-danger animated fadeInDown fast-4 small">
                        El campo no puede contener numeros
                  </div>
                </div>
              </div>
            </div>
            <!-- </div>
            <div class="row no-gutters"> -->
            <div class="col-12 col-md-4">
              <div class="md-form">
                <input mdbInput type="text" id="first_name" class="form-control"
                       formControlName="defaultFormFirstName"
                       [(ngModel)]="user.apellido_p">
                <label for="first_name" class="">Apellido Paterno</label>
                <div *ngIf="formUser.controls['defaultFormFirstName'].dirty">
                  <div *ngIf="formUser.controls['defaultFormFirstName'].errors?.required"
                       class="text-danger animated fadeInDown fast-4 small">
                    El apellido paterno del usuario es obligatorio.
                  </div>
                  <div *ngIf="formUser.controls['defaultFormFirstName'].errors?.pattern"
                       class="text-danger animated fadeInDown fast-4 small">
                       El campo no puede contener numeros
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="md-form">
                <input mdbInput type="text" id="last_name" class="form-control"
                       formControlName="defaultFormLastName"
                       [(ngModel)]="user.apellido_m">
                <label for="last_name" class="">Apellido Materno</label>
                <div *ngIf="formUser.controls['defaultFormLastName'].dirty">
                  <div *ngIf="formUser.controls['defaultFormLastName'].errors?.required"
                       class="text-danger animated fadeInDown fast-4 small">
                    El apellido materno del usuario es obligatorio.
                  </div>
                  <div *ngIf="formUser.controls['defaultFormLastName'].errors?.pattern"
                       class="text-danger animated fadeInDown fast-4 small">
                       El campo no puede contener numeros
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" [ngClass]="{disabled:true}">
            <div class="col-12">
              <div class="md-form">
                <input mdbInput type="text" id="email" class="form-control"
                       formControlName="defaultFormEmail"
                       [(ngModel)]="user.correo">
                <label for="email" class="">Email</label>
                <div *ngIf="formUser.controls['defaultFormEmail'].dirty">
                  <div *ngIf="formUser.controls['defaultFormEmail'].errors?.required"
                       class="text-danger animated fadeInDown fast-4 small">
                    El correo electrónico del usuario es obligatorio.
                  </div>
                  <div *ngIf="formUser.controls['defaultFormEmail'].errors?.email"
                       class="text-danger animated fadeInDown fast-4 small">
                    El formato del correo electrónico es incorrecto.
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="row"> 
            <div class="col-12">
              <div class="md-form">
                <input mdbInput type="text" id="curp" class="form-control text-uppercase" maxlength="18" [(ngModel)]="user.curp"
                       formControlName="defaultCURP">
                <label for="curp" class="">CURP</label>
                <div *ngIf="formUser.controls['defaultCURP'].touched">
                  <div *ngIf="formUser.controls['defaultCURP'].errors?.pattern"
                    class="text-danger animated fadeInDown fast-4 small">Formato incorrecto
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row"> 
            <div class="col-12">
              <div class="md-form">
                <input mdbInput type="text" id="rfc" class="form-control text-uppercase" maxlength="13" [(ngModel)]="user.rfc"
                       formControlName="defaultRFC">
                <label for="rfc" class="">RFC</label>
                <div *ngIf="formUser.controls['defaultRFC'].dirty">
                  <div *ngIf="formUser.controls['defaultRFC'].errors?.pattern"
                       class="text-danger animated fadeInDown fast-4 small">
                    Formato de RFC incorrecto.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="md-form">
                <input mdbInput type="text" id="telephone" class="form-control" [(ngModel)]="user.telefono"
                       
                       formControlName="dafaultPhone"
                       >
                <label for="telephone" class="">Teléfono</label>
                <div *ngIf="formUser.controls['dafaultPhone'].touched">
                  <div *ngIf="formUser.controls['dafaultPhone'].errors?.maxlength"
                       class="text-danger animated fadeInDown fast-4 small">El campo tiene que ser menor de 10
                    caracteres
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="md-form">
                <input mdbInput type="text" id="celular" class="form-control" [(ngModel)]="user.celular"
                       
                       formControlName="defaultCellphone">
                <label for="telephone" class="">Celular</label>
                <div *ngIf="formUser.controls['defaultCellphone'].touched">
                  <div *ngIf="formUser.controls['defaultCellphone'].errors?.maxlength"
                       class="text-danger animated fadeInDown fast-4 small">El campo tiene que ser menor de 10
                    caracteres
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="md-form">
                <input mdbInput type="password" id="password" class="form-control"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="user.contrasenia">
                <label for="password" class="">Contraseña</label>
                <div *ngIf="user.contrasenia">
                  <div *ngIf="user.contrasenia != user.contraseniaConfirma"
                       class="text-danger animated fadeInDown fast-4 small">
                    Las contraseñas no coinciden, favor de validar.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="md-form">
                <input mdbInput type="password" id="passwordConfirm" class="form-control"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="user.contraseniaConfirma">
                <label for="passwordConfirm" class="">Confirmación de contraseña</label>
                <div *ngIf="user.contrasenia">
                  <div *ngIf="user.contrasenia != user.contraseniaConfirma"
                       class="text-danger animated fadeInDown fast-4 small">
                    Las contraseñas no coinciden, favor de validar.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex align-items-left justify-content-left">
              <mdb-checkbox [(ngModel)]="user.twoFactorAuth" formControlName="twoFactorAuth">
                Doble autentificación
              </mdb-checkbox>
            </div>
          </div>
          

          <div class="text-center mt-1-half">
            <button type="submit" (click)="saveData();" mti-color-theme class="btn btn-primary btn-theme-primary mb-1 waves-light" mdbWavesEffect>
              <i class="fa fa-save mr-1"></i>Guardar
            </button>
          </div>
        </form>
      </div>
      <!--/.Content-->
    </div>
  </div>
  <!--Modal: Login / Register Form-->
</div>
<!--
@Override
  public BPMEstadoProcesoDetalleService actualizarBpmEstadoProcesoDetalleIdsModAcciones(BPMEstadoProcesoDetalle bpmEstadoProcesoDetalle, String id_master, Handler<AsyncResult<Void>> handler) {
    logger.info("Entro al service para actualizar los IDS");
    Future f = Future.future();
    f.setHandler(handler);
    bpmestadoProcesoDetalleRepository.actualizarBpmEstadoProcesoDetalleIdsModAcciones(bpmEstadoProcesoDetalle,id_master)
      .map(response->{
        JsonObject json = new JsonObject();
        json.put("status", 200);
        json.put("mensaje", "Ids Actualizados");
        return json;
      }).subscribe(f::complete, f::fail);
    return this;
  }

-->
<div mdbModal #modalFoto="mdb-modal" class="modal fade overflow-auto" tabindex="-1" role="dialog"
  aria-labelledby="myBasicModalLabel" aria-hidden="">

</div>

