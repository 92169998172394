import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {UsuarioModel} from './usuario.model';
import {ResourceAuth} from '../../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../../common.module/auth/auth-jwt.service';
/**
 * Created by Raul on 03/05/2018.
 */

@Injectable()
@ResourceParams({
   // url: 'http://localhost:8001'
  pathPrefix: '/administracion'
})
export class UsuarioResource extends ResourceAuth {
// export class UsuarioResource extends Resource {
  constructor(handler: ResourceHandler, authProv: AuthServerProvider) {
    super(handler, authProv);
  }

   /*constructor(handler: ResourceHandler) {
    super(handler);
   }*/

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/na/usuario/registro'
  })
  crearUsuario: IResourceMethod<any, UsuarioModel>;

  @ResourceAction({
    path: '/na/usuario/{!codigo_confirmacion}/registro'
  })
  obtenerPorCodigoConfirmacion: IResourceMethod<{codigo_confirmacion: string}, UsuarioModel>;

  @ResourceAction({
    path: '/na/usuario/recuperar/:codigo_recuperacion'
  })
  obtenerCodigoRecuperacion: IResourceMethod<{codigo_recuperacion: string}, UsuarioModel>;

  @ResourceAction({
    method: ResourceRequestMethod.Patch,
    path: '/na/usuario/{!_id}/registro'
  })
  actualizarUsuario: IResourceMethod<UsuarioModel, any>;

  @ResourceAction({
    path: '/ss/usuario'
  })
  usuarioSesion: IResourceMethod<any, UsuarioModel>;

  @ResourceAction({
    path: '/ss/usuario',
    method: ResourceRequestMethod.Patch
  })
  actualizarUsuarioSesion: IResourceMethod<UsuarioModel, any>;

  @ResourceAction({
    path: '/na/usuario/recuperar/{!codigo_recuperacion}',
    method: ResourceRequestMethod.Patch
  })
  recuperarCuenta: IResourceMethod<UsuarioModel, any>;

  @ResourceAction({
    path: '/na/usuario/recuperar',
    method: ResourceRequestMethod.Patch
  })
  solicitudRecuperacionCuenta: IResourceMethod<UsuarioModel, any>;
}
