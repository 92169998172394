/**
 * Created by Daniel on 09/07/2018.
 */

import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';

@Injectable()
@ResourceParams({
  url: 'http://localhost:test'
})

export class EvaluateFormalityResource extends Resource {
  constructor(resHandler: ResourceHandler) {
    super(resHandler);
  }

  @ResourceAction({
    path: '/test',
    method: ResourceRequestMethod.Post
  })
  sendRejectNotification: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/test2',
    method: ResourceRequestMethod.Post
  })
  sendGreenLightNotification: IResourceMethod<any, any>;

}
