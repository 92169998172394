import {Component, OnInit} from '@angular/core';
import {ConfiguracionGeneralService} from '../admin.configuracion-general.module/service/configuracion-general.service';
import {ConfiguracionGeneralModel} from '../admin.configuracion-general.module/service/configuracion-general.model';
/**
 * Created by EMOLINA on 24/01/2019.
 */
@Component({
  selector: 'app-index-admin',
  templateUrl: './index-admin.component.html'
})
export class IndexAdminComponent implements OnInit {
  public configuracion: ConfiguracionGeneralModel = {};

  constructor(private configuracionGeneralService: ConfiguracionGeneralService) {
  }

  ngOnInit(): void {
    this.configuracionGeneralService.obtenerConfiguracionGeneral().then(configuracion => {
      this.configuracion = configuracion;
    });
  }
}
