<div class="row no-gutters">
  <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0">
    <div class="page-title">
      <mdb-icon fas icon="arrow-left" (click)="regresar()"></mdb-icon>
      <div mti-color-theme class="bg-icon"></div>
      <div mti-color-theme class="title">
        <div class="w-100 bg-title">Consulta de recibos</div>
      </div>
    </div>
  </div>
  <div class="card mt-3 col-lg-12">
    <div mti-color-theme class="card-header unique-color lighten-1 white-text d-flex">
      <div class="mr-auto align-self-center">
        <strong >
          R E C I B O S
        </strong>
      </div>
    </div>
  </div>
  <div class="card mt-3 col-lg-12">
    <mdb-card class="card">
      <div class="card">
        <mdb-card-body class="card-body">
          <div class="mt-3 col-lg-12" *ngIf="existRecibo">
            <div mti-color-theme class="card-header unique-color lighten-1 white-text d-flex">
              <div class="mr-auto align-self-center">
                <strong >
                  Descarga de comprobantes fiscales
                </strong>
              </div>
            </div>
            <div class="modal-body">
              <!--              TODO: agregar «style="overflow-x:hidden"» a table; tambiñen «important» (si no)-->
              <table mdbTable responsive="true">
                <thead>
                <tr>
                  <th scope="col">Serie</th>
                  <th scope="col">Folio</th>
                  <th scope="col">Fecha de pago</th>
                  <th scope="col">Monto</th>
                  <th scope="col">RFC</th>
                  <th scope="col">Nombre/Razón social</th>
                  <th scope="col">Fecha de timbrado</th>
                  <ng-container *ngIf="isTimbrado"><th scope="col">UUID</th></ng-container>
                  <th scope="col">Estado</th>
                  <th scope="col">Recibo de pago</th>
                  <th scope="col">PDF</th>
                  <th scope="col">XML</th>
                </tr>
                </thead>
                <tbody>
                <tr mdbTableCol *ngFor="let ins of listaReciboPago">
                  <td>{{ins.serie}}</td>
                  <td>{{ins.folio}}</td>
                  <td>{{ins.fechaPago}}</td>
                  <td>${{ins.importeTotal | number:'1.2-2' : 'en-US'}}</td>
                  <td>{{ins.rfc_contribuyente}}</td>
                  <td>{{ins.nombre_contribuyente}}</td>
                  <td>{{ins.fechaTimbrado}}</td>
                  <ng-container *ngIf="isTimbrado"><td>{{ins.uuid}}</td></ng-container>
                  <!--<ng-container *ngIf="ins.estatusFact == 'N'"><td>Pagado</td></ng-container>
                  <ng-container *ngIf="ins.estatusFact == 'T'"><td>Timbrado</td></ng-container>-->
                  <td>{{ ins.estatus | titlecase}}</td>
                  <td>
                    <button mdbBtn type="button" size="sm" color="warning" rounded="true"
                            (click)="descargarComprobante(filterModel.serie, filterModel.folio.toString())"
                            mdbWavesEffect>
                      <mdb-icon fas icon="file-alt"></mdb-icon>
                    </button>
                  </td>

                  <td>
                    <ng-container *ngIf="isTimbrado">
                      <button mdbBtn type="button" size="sm"  color="danger" rounded="true"
                              (click)="descargarPdf(filterModel.serie, filterModel.folio.toString(), filterModel.importeTotal)"
                              dbWavesEffect>
                        <mdb-icon fas icon="file-pdf"></mdb-icon>
                      </button>
                    </ng-container>
                  </td>
                  <td>
                    <ng-container *ngIf="isTimbrado">
                      <button mdbBtn type="button" size="sm"  color="info" rounded="true"
                              (click)="descargarXml(filterModel.serie, filterModel.folio.toString(), filterModel.importeTotal)"
                              mdbWavesEffect>
                        <mdb-icon fas icon="file-code"></mdb-icon>
                      </button>
                    </ng-container>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <form [formGroup]="reciboForm">
            <div class="row" *ngIf="!existRecibo">
              <div class="md-form col-12 col-lg-4">
                <input mdbInput type="text" formControlName="formSerie"
                       id="serie"
                       [(ngModel)]="modelCampos.serie"
                       class="form-control text-uppercase">
                <label for="serie" class="col-lg-12">Serie</label>
                <div *ngIf="reciboForm.controls['formSerie'].touched">
                  <div *ngIf="reciboForm.controls['formSerie'].errors?.required"
                       class="text-danger animated fadeInDown fast-4 small">
                    Campo requerido
                  </div>
                  <div *ngIf="reciboForm.controls['formSerie'].errors?.pattern"
                       class="text-danger animated fadeInDown fast-4 small">Formato incorrecto
                  </div>
                </div>
              </div>
              <div class="md-form col-12 col-lg-4">
                <input mdbInput type="text" formControlName="formFolio"
                       id="folio"
                       [(ngModel)]="modelCampos.folio"
                       class="form-control text-uppercase">
                <label for="folio" class="col-lg-12">Folio</label>
                <div *ngIf="reciboForm.controls['formFolio'].touched">
                  <div *ngIf="reciboForm.controls['formFolio'].errors?.required"
                       class="text-danger animated fadeInDown fast-4 small">
                    Campo requerido
                  </div>
                  <div *ngIf="reciboForm.controls['formFolio'].errors?.pattern"
                       class="text-danger animated fadeInDown fast-4 small">Formato incorrecto
                  </div>
                </div>
              </div>
              <div class="md-form col-12 col-lg-4">
                <input mdbInput type="text" formControlName="formImporte"
                       id="importe"
                       [(ngModel)]="modelCampos.importeTotal"
                       class="form-control text-uppercase">
                <label for="importe" class="col-lg-12">Importe pagado (999999.99)</label>
                <div *ngIf="reciboForm.controls['formImporte'].touched">
                  <div *ngIf="reciboForm.controls['formImporte'].errors?.required"
                       class="text-danger animated fadeInDown fast-4 small">
                    Campo requerido
                  </div>
                  <div *ngIf="reciboForm.controls['formImporte'].errors?.pattern"
                       class="text-danger animated fadeInDown fast-4 small">Formato incorrecto
                  </div>
                </div>
              </div>
            </div>
          </form>
          <ng-container *ngIf="!existRecibo">
            <button type="button" mti-color-theme class="btn btn-sm btn-theme-primary waves-light"
                    (click)="buscarRecibo()" mdbWavesEffect>
              <mdb-icon fas icon="search"></mdb-icon>
              Buscar
            </button>
          </ng-container>
        </mdb-card-body>
      </div>
    </mdb-card>
  </div>
</div>
