import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from 'angular-bootstrap-md';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IMyOptions, ToastService} from 'ng-uikit-pro-standard';
import {DataShareService} from '../../common.module/util/data.share';
import {VigenciaReqModel} from '../service/vigencia-req.model';
import {ConsultaExpElecService} from '../service/consulta-exp-elec.service';

@Component({
  selector: 'app-vigencia-exp-elect-detalle',
  templateUrl: './vigencia-exp-elect-detalle.component.html',
  styles: []
})
export class VigenciaExpElectDetalleComponent implements OnInit {
  @ViewChild('CrearVigencia')
  public modalVigencia: ModalDirective;
  public formVigencia: FormGroup;
  @Input()
  public vigencia: VigenciaReqModel = <VigenciaReqModel>{};
  @Input()
  public numRequisito: string;
  public myDatePickerOptions: IMyOptions = {
    minYear: 1900,
    maxYear: 2060,
    
    todayBtnTxt: 'Hoy',
    closeBtnTxt: 'Cerrar',
    showClearDateBtn: false,
    showTodayBtn: false,

    closeAfterSelect: true,

    dateFormat: 'dd-mm-yyyy',

    dayLabels: {su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mie', th: 'Jue', fr: 'Vie', sa: 'Sab'},
    dayLabelsFull: {su: 'Domingo', mo: 'Lunes', tu: 'Martes', we: 'Miércoles', th: 'Jueves', fr: 'Viernes', sa: 'Sábado'},
    monthLabels: {
      1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul',
      8: 'Ago', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dic'
    },
    monthLabelsFull: {
      1: 'Enero', 2: 'Febrero', 3: 'Marzo', 4: 'Abril', 5: 'Mayo', 6: 'Junio', 7: 'Julio',
      8: 'Agosto', 9: 'Septiembre', 10: 'Octubre', 11: 'Noviembre', 12: 'Diciembre'
    }
  };

  constructor(private fb: FormBuilder,
              private toastMsg: ToastService,
              private dataShareService: DataShareService,
              private consultaExpElecService: ConsultaExpElecService) {
  }

  ngOnInit() {
    this.formVigencia = this.fb.group({
      formVigenciaReq: ['', Validators.compose(
        [Validators.pattern(
          '^((((3[01])|([0-2][0-9]))[\\/]?((1[02])|(0[13578])))|(((30)|([0-2][0-9]))[\\/]?((11)|(0[2469]))))[\\/]?\\d{4}$'
        )])],
    });
  }

  crtVigencia() {
    if (this.formVigencia.valid == false) {
      this.toastMsg.warning('Formato de fecha incorrecto');
      return;
    }
    if (this.vigencia !== null && this.vigencia !== undefined) {
      const vigencia: VigenciaReqModel = <VigenciaReqModel>{
        vigencia: this.vigencia.vigencia,
        id: this.numRequisito
      };
      console.log('Requerimiento');
      console.log(vigencia);
      this.consultaExpElecService.actualizaVigencia(vigencia).then(res => {
        this.dataShareService.confirmObject({vigencia : vigencia.vigencia == '' ? 'Sin vigencia' : vigencia.vigencia, id : vigencia.id});
        this.toastMsg.success('La vigencia se ha actualizado con éxito');
        this.formVigencia.reset();
        this.modalVigencia.hide();
      }, err => {
        if (err.body) {
          if (err.body.message)
            this.toastMsg.error(err.body.message);
          else
            this.toastMsg.error('Hubo un problema al actualizar la vigencia');
        } else {
          this.toastMsg.error('Hubo un error inesperado');
        }
        this.modalVigencia.hide();
      });
    } else {
      this.toastMsg.error('Error al guardar, no se encontro vigencia.');
    }
  }

}
