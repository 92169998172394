import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';
import {ConfiguracionGeneralModel} from '../model/configuracion-general.model';
import {EstablecimientoModel} from '../model/establecimientoModel.model';
import {UsuarioModel} from '../model/usuario.model';

@Injectable()
@ResourceParams({
  pathPrefix: '/consumos'
  // url: 'http://localhost:8202/'
})
export class ConfiguracionGeneralResourceConsumos extends ResourceAuth {
  constructor(resourceHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(resourceHandler, authProv);
  }
 /*export class ConfiguracionGeneralResourceConsumos extends Resource {
   constructor(resourceHandler: ResourceHandler) {
     super(resourceHandler);
   }*/

  @ResourceAction({
    path: '/configuracion-general',
    method: ResourceRequestMethod.Patch
  })
  actualizarConfiguracionGeneral: IResourceMethod<ConfiguracionGeneralModel, any>;

  @ResourceAction({
    path: '/na/configuracion-general',
    method: ResourceRequestMethod.Get
  })
  obtenerConfiguracionGeneral: IResourceMethod<any, ConfiguracionGeneralModel>;

  

  @ResourceAction({
    path: '/usuario/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtenerUsuario: IResourceMethod<{ id: any }, UsuarioModel>;

  
  @ResourceAction({
    path: '/establecimiento/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtenerEstablecimiento: IResourceMethod<{ id: any }, EstablecimientoModel>;


}
