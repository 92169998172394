import {Injectable} from '@angular/core';
import {States} from './model/request-registration-endorsement-consultation-update/states.model';
import {RequestRegistrationAndEndorsementResource} from './request-registration-endorsement-consultation-update.resource';
import {Towns} from './model/request-registration-endorsement-consultation-update/towns.model';
import {Suburbs} from './model/request-registration-endorsement-consultation-update/suburbs.model';
import {Banks} from './model/request-registration-endorsement-consultation-update/banks.model';
import {Giros} from './model/request-registration-endorsement-consultation-update/giros.model';
import {PublicFileModel} from './model/public-file.model';
import {TypesDocumentsModel} from './model/request-registration-endorsement-consultation-update/types-documents.model';
import {DataRequestProviderResponse} from './model/request-registration-endorsement-consultation-update/data-request-provider-response.model';
import {BuscarRfcResponse} from './model/request-registration-endorsement-consultation-update/buscar-rfc-response.model';
import {DetailRequestProviderResponse} from './model/request-registration-endorsement-consultation-update/detail-request-provider-response.model';
import {DocumentsModel} from './model/request-registration-endorsement-consultation-update/documents.model';
import {SaveRequestProviderResponse} from './model/request-registration-endorsement-consultation-update/save-request-provider-response.model';
import {PaysResponseModel} from './model/request-registration-endorsement-consultation-update/pays-response.model';
import {TicketModel} from './model/request-registration-endorsement-consultation-update/ticket.model';
import {DataLogResponse} from './model/request-registration-endorsement-consultation-update/data-log-response';
import {CaseFileCensusModel} from './model/request-registration-endorsement-consultation-update/case-file-census.model';
import {PrintCashPassModel} from './model/request-registration-endorsement-consultation-update/print-cash-pass.model';
import {AdvanceStatusResponse} from './model/request-registration-endorsement-consultation-update/advance-status-response.model';
import {DownloadDocument} from './model/request-registration-endorsement-consultation-update/download-document.model';
import {UpdateRequestPrinc} from './model/request-registration-endorsement-consultation-update/update-request-princ.model';
import {UpdateRequest} from './model/request-registration-endorsement-consultation-update/update-request.model';
import {RequestFormatsEndorsementModel} from './model/request-formats-endorsement'
/**
 * Created by JMOLINA on 13/08/2018.
 */

@Injectable()
export class RequestRegistrationAndEndorsementService {
  constructor(private requestRegistrationAndEndorsementResource: RequestRegistrationAndEndorsementResource) {
  }
  getStates(countryKey: any): Promise<States> {
    return this.requestRegistrationAndEndorsementResource.getStates({cve_pais: countryKey});
  }
  getTowns(stateKey: any): Promise<Towns> {
    return this.requestRegistrationAndEndorsementResource.getTowns({cve_estado: stateKey});
  }
  getSuburbs(townKey: any): Promise<Suburbs> {
    return this.requestRegistrationAndEndorsementResource.getSuburbs({cve_municipio: townKey});
  }
  getBanks(): Promise<Banks> {
    return this.requestRegistrationAndEndorsementResource.getBanks({});
  }
  getGiros(): Promise<Giros> {
    return this.requestRegistrationAndEndorsementResource.getGiros({});
  }
  getTypesDocuments(id: any): Promise<TypesDocumentsModel> {
    return this.requestRegistrationAndEndorsementResource.getTypesDocuments({id: id});
  }

  saveDocument(documentsModel: DocumentsModel) {
    return this.requestRegistrationAndEndorsementResource.saveDocument(documentsModel);
  }
  getDocuments(id: any): Promise<DocumentsModel> {
    return this.requestRegistrationAndEndorsementResource.getDocuments({id: id});
  }
  getPaysBySol(id: any): Promise<PaysResponseModel> {
    return this.requestRegistrationAndEndorsementResource.getPaysBySol({id: id});
  }

  postCashPass(printCashPassModel: PrintCashPassModel): Promise<CaseFileCensusModel> {
    return this.requestRegistrationAndEndorsementResource.postCashPass(printCashPassModel);
  }
  getPublicFile(id: any): Promise<PublicFileModel> {
    return this.requestRegistrationAndEndorsementResource.getPublicFile({id: id});
  }
  getDataRequestProv(email: any): Promise<DataRequestProviderResponse> {
    return this.requestRegistrationAndEndorsementResource.getDataRequestProv({email: email});
  }
  getSearchByRfc(rfc: any): Promise<BuscarRfcResponse> {
    return this.requestRegistrationAndEndorsementResource.getSearchByRfc({rfc: rfc});
  }
  GetDetailRequestProvider(email: any, request): Promise<DetailRequestProviderResponse> {
    return this.requestRegistrationAndEndorsementResource.GetDetailRequestProvider({email: email, request: request});
  }
  SaveRequestProvider(pSolProv: any []): Promise<SaveRequestProviderResponse> {
    return this.requestRegistrationAndEndorsementResource.SaveRequesProvider({pSolProv: pSolProv});
  }
  GetDataLog(requestKey: any []): Promise<DataLogResponse> {
    return this.requestRegistrationAndEndorsementResource.GetDataLog({requestKey: requestKey});
  }
  AdvanceStatus(p_avanza: any, id: any): Promise<AdvanceStatusResponse> {
    return this.requestRegistrationAndEndorsementResource.AdvanceStatus({p_avanza: p_avanza, id: id});
  }

  downloadDocument(id: any): Promise<DownloadDocument> {
    return this.requestRegistrationAndEndorsementResource.downloadDocument({id: id});
  }

  updateRequest(updateRequest: UpdateRequest []): Promise<UpdateRequestPrinc> {
    return this.requestRegistrationAndEndorsementResource.updateRequest({updateRequest: updateRequest});
  }

  getRequirementsProv(tipoPer: string) {
    return this.requestRegistrationAndEndorsementResource.getRequirementsProv({tipoPer});
  }

  getFormatsEndorsement(): Promise<RequestFormatsEndorsementModel[]> {
    return this.requestRegistrationAndEndorsementResource.getFormatsEndorsement();
  }

  generateFileBase64(id:number) : Promise<any> {
    return this.requestRegistrationAndEndorsementResource.generateFileBase64({id: id});
  }
}
