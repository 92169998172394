/**
 * Created by rsierra on 25/09/2017.
 */

import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {UsuarioListaComponent} from './usuario-lista/usuario-lista.component';
import {UsersDetailComponent} from './usuario-detalle/usuario-detalle.component';

const exampleRoutes: Routes = [
  {
    path: '',
    component: UsuarioListaComponent,
    data: {
      pageTitle: 'Usuarios del sistema'
    },
    children: [
      {
        path: '',
        component: UsersDetailComponent
      },
      {
        path: ':_id',
        component: UsersDetailComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(exampleRoutes)],
  exports: [RouterModule]
})
export class AdminAdminRoutingModule {
}
