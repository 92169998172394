import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
// import {ToastService} from '../../typescripts/pro/alerts/toast/toast.service';
import {ToastService, UploadOutput} from 'ng-uikit-pro-standard';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import {RequestRegistrationAndEndorsementService} from '../../../../portal-legacy/portal.providers.module/service/request-registration-endorsement-consultation-update.service';

@Component({
  selector: 'app-provider-pdf-format',
  templateUrl: 'provider-pdf-format.component.html'
})
//
export class ProviderFormatComponent implements OnInit {
  public ID_REQUISITOS_INSCRIPCION = 11;
  public innerHtml: SafeHtml;
  @Input()
  public outputImage: UploadOutput = <UploadOutput>{};
  @Input()
  public obtenerPublicFile: boolean = false;

  constructor(
    private toastMsg: ToastService,
    private fb: FormBuilder,
    private _sanitizer: DomSanitizer,
    private http: HttpClient,
    private requestRegistrationAndEndorsementService: RequestRegistrationAndEndorsementService) {}

  ngOnInit() {
    if (this.obtenerPublicFile) {
      this.generatePublicFileWith64(this.ID_REQUISITOS_INSCRIPCION);
    }
  }

  generatePublicFileWith64(idFilePublic) {
    this.requestRegistrationAndEndorsementService.getPublicFile(idFilePublic).then(res => {
        if (res[0].file_public_64 != null) {
          // this.downloadFile(res[0].name, res[0].file_public_64);
          this.downloadFile(res[0].file_public_64);
        } else {
          this.toastMsg.error('El archivo no esta disponible por el momento');
        }
      }, err => {
        if (err.body && err.body.message)
          this.toastMsg.error(err.body.message);
        else
          this.toastMsg.error('Ocurrio un error al obtener el documento ');
      });
  }

  downloadFile(base64) {
    const dataURI = this.base64ToArrayBuffer(base64);
    const blob = new Blob([dataURI], {type: 'application/pdf'});
    const elem = window.document.createElement('a');
    // window.open(window.URL.createObjectURL(blob), '_blank');
    console.log(blob);
    this.setInnerHtml(window.URL.createObjectURL(blob));
  }

  base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  public setInnerHtml(pdfurl: string) {
    this.innerHtml = this._sanitizer.bypassSecurityTrustHtml(
      '<object style="height: 29rem" data=' + pdfurl + ' type="application/pdf" class="col-12 col-lg-12 mw-100">' +
      'Object ' + pdfurl + ' failed ' +
      '</object>');
  }

  // Para archivos formato ZIP

  fileZip() {
    this.http.get('../../../assets/doc/file-zip-message.pdf', {responseType: 'blob'})
        .subscribe(data => {
          console.log(data)
          this.setInnerHtml(window.URL.createObjectURL(data));
        });
  }
}
