/**
 * Created by Raul.
 */
import {Injectable} from '@angular/core';
import {IResourceAction, Resource, ResourceHandler} from '@ngx-resource/core';
import {AuthServerProvider} from './auth-jwt.service';
import {environment} from '../../../environments/environment';

@Injectable()
export class ResourceAuth extends Resource {
  private apiBaseUrl: string = environment.apiBaseUrl + '/api';
  //  private apiBaseUrl: string = '';

  constructor(resourceHandler: ResourceHandler, private authProv: AuthServerProvider) {
    super(resourceHandler);
  }

  $getHeaders(actionOptions?: IResourceAction): any | Promise<any> {
    let headers: any = super.$getHeaders();
    if (!actionOptions.auth) {
      headers = this.authProv.extendHeaders(headers);
    }
    return headers;
  }

  $getUrl(methodOptions?: IResourceAction): string | Promise<string> {
    const resPath = super.$getUrl();
    return this.apiBaseUrl + resPath;
  }


}
