import { Injectable } from '@angular/core';
import {LocationsResource} from "./locations.resource";
import {LocationsModel} from "./locations.model";
import {RequisitosResource} from "./requisitos.resource";
import {RequisitosModel} from "./requisitos.model";
import {ExpedienteArchivosModel} from "./expediente-archivos.model";
import {ExpHistoricoClaveModel} from "./expHistoricoClave.model";

@Injectable({
  providedIn: 'root'
})
export class RequisitosService {

  constructor(private requisitosResource: RequisitosResource) { }

  obtenerListaRequisitos(estatus: string): Promise<RequisitosModel []> {
    return this.requisitosResource.obtenerListaRequisitos({estatus: estatus});
  }

  obtenerRequisito(id: string): Promise<RequisitosModel> {
    return this.requisitosResource.obtenerRequisito({id: id});
  }

  obtenerListaRequisitosIdSolServ(idSolExp: string): Promise<ExpedienteArchivosModel []> {
    return this.requisitosResource.obtenerListaRequisitosIdSol({idSolExp: idSolExp});
  }


  actualizarRequisitosServ(archivosExpElect: ExpedienteArchivosModel): Promise<any> {
    return this.requisitosResource.actualizarRequisitosRes({archivosExpElect: archivosExpElect});
  }

  actualizarArchivoId(archivosExpElect: ExpedienteArchivosModel): Promise<any> {
    return this.requisitosResource.actualizarArchivoId({archivosExpElect: archivosExpElect});
  }

  guardarArchivoExpedienteServ(cargaArchivosReq: any): Promise<any> {
    return this.requisitosResource.GuardarArchivosExpediente({cargaArchivosReq: cargaArchivosReq});
  }

  eliminarArchivoExpediente(id: string): Promise<any> {
    return this.requisitosResource.eliminarArchivoExpediente({id: id});
  }

  obtenerArchivoExpediente(id: string): Promise<any> {
    return this.requisitosResource.obtenerArchivoExpediente({id: id});
  }

}
