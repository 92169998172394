/**
 * Created by Alejandro on 16/08/2018.
 */

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {ToastService} from "ng-uikit-pro-standard";
import {AuthServerProvider} from './auth-jwt.service';

@Injectable()
export class AuthGuardPortalEmployee implements CanActivate {
  private isEmployee = false;

  constructor(private auth: AuthService,
              private router: Router,
              private toastMsg: ToastService,
              private authServ: AuthServerProvider) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.loggedIn()) {
      console.log(state);
      const usr = this.authServ.getUserDecoded();
      usr.authorities.map(r => {
        console.log('Authority: ' + r.authority);
        if (r.authority === 'SYS_ROLE_EMPLOYEE') {
          this.isEmployee = true;
        }
      });
      console.log('Es empleado: ' + this.isEmployee + ' - ' + state.url);
      if (!this.isEmployee && state.url === '/portal/nomina-cfdi') {
        console.log('Entra en el if employee');
        this.toastMsg.warning('El usuario logeado no es un empleado de SIM.');
        return false;
      }
      return true;
    }
  }
}

