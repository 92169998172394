import { Injectable } from '@angular/core';

import {CnfFolioResource} from './cnf-folio.resource';
import {CnfFolioModel} from './model/cnf-folio.model';
import {TramitesModel} from './model/tramites.model';

@Injectable({
  providedIn: 'root'
})
export class CnfFolioService {

  constructor( private cnfFolioResource: CnfFolioResource) { }

  public obtieneCnfFolioPorID(id: string): Promise<CnfFolioModel> {
    return this.cnfFolioResource.obtieneCnfFolioPorID({id: id});
  }

  public creaCnfFolio (cnfFolioModel: CnfFolioModel) {
    return this.cnfFolioResource.creaCnfFolio(cnfFolioModel);
  }

  public actualizaCnfFolioPorID(cnfFolioModel: CnfFolioModel): Promise<any> {
    return this.cnfFolioResource.actualizaCnfFolioPorID(cnfFolioModel);
  }

  public obtieneCnfFolioListaFiltro(filtros: any): Promise<CnfFolioModel[]> {
    return this.cnfFolioResource.obtieneCnfFolioListaFiltro(filtros);
  }

  public eliminaCnfFolio(id: string): Promise<any> {
    return this.cnfFolioResource.eliminaCnfFolio({id: id});
  }

  public obtieneTramitesLista(): Promise<TramitesModel[]> {
    return this.cnfFolioResource.obtieneTramitesLista();
  }

  public obtieneConfigFolioPorTramiteId(id: string): Promise<CnfFolioModel> {
    return this.cnfFolioResource.obtieneConfigFolioPorTramiteId({id: id});
  }

  public actualizarFolioInternoConsecutivo(params: any): Promise<any> {
    return this.cnfFolioResource.actualizarFolioInternoConsecutivo(params);
  }
}
