import {IResourceMethod, ResourceAction, ResourceHandler, ResourceParams} from '@ngx-resource/core';
import {Injectable} from '@angular/core';
import {FormalityConfigModel} from './formality-config.model';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {TramitesModel} from '../../portal.index.module/service/tramites.model';
import {Category} from 'src/app/portal.index.module/service/category.model';

/**
 * Created by Raul on 03/05/2018.
 */
@Injectable()
@ResourceParams({
  pathPrefix: '/core'
  // url: 'http://localhost:8012'
})
export class FormalitiesResource extends ResourceAuth {
  constructor(handler: ResourceHandler, authProv: AuthServerProvider) {
    super(handler, authProv);
  }
/*
export class FormalitiesResource extends Resource {
   constructor(handler: ResourceHandler) {
    super(handler);
   }*/

  @ResourceAction({
    path: '/na/tramite?s={!s}'
  })
  getFormalities: IResourceMethod<{ s: any }, FormalityConfigModel[]>;

  @ResourceAction({
    path: '/na/tramites-populares'
  })
  obtenerTramitesPopulares: IResourceMethod<{ s: any }, TramitesModel[]>;

  @ResourceAction({
    path: '/na/categorias'
  })
  obtenerCategorias: IResourceMethod<any, Category[]>;

  @ResourceAction({
    path: '/na/categorias'
  })
  obtenerUnaCategoria: IResourceMethod<any, Category>;
}
