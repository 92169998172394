/**
 * Created by Raul on 03/05/2018.
 */

import {Injectable} from '@angular/core';
import {SolicitudesModel} from './model/solicitudes.model';
import {TramitesModel} from './model/tramites.model';
import {TramitesResource} from './resource/tramites.resource';
import {CfgTramite} from './model/CfgTramite.model';
import {BPMEstadoProcesoMasterModel} from "../../admin.bpm.module/reasignacion-component/service/model/bmpestadoproceso.model";
import {BPMEstadoProcesoMasterUModel} from "./model/bmpestadoproceso.model";
import {BPMEstProcMasterCPModel} from "./model/bpmestadoprocmastercp.model";
import {ReporteDatosAdicionales} from "./model/reporte-datos-adicionales.model";
import {ConfiguracionReporteAdicional} from './model/configuracion-reporte-adicionales.model';

@Injectable()
export class TramitesService {

  constructor(private tramitesResource: TramitesResource) {
  }

  obtenerSolicitudesPorTramite(filtro: any): Promise<SolicitudesModel[]> {
    return this.tramitesResource.obtenerSolicitudes(filtro);
  }

  obtenerSolicitudPorIdFolio(idfolio: string): Promise<SolicitudesModel[]> {
    return this.tramitesResource.obtenerSolicitud({idfolio: idfolio});
  }

  obtenerTodosTramites(): Promise<TramitesModel[]> {
    return this.tramitesResource.obtenerTramites();
  }

  obtenerPretramites(idsTramites: string[]): Promise<BPMEstadoProcesoMasterModel[]> {
    return this.tramitesResource.obtenerPretramites({idsTramites: idsTramites});
  }

  obtenerTramitesConUsuatios(id: string): Promise<BPMEstadoProcesoMasterUModel[]> {
    return this.tramitesResource.obtenerTramitesConUsuarios({id: id});
  }

  obtenerTramitesRepDatAdic(id: string): Promise<ReporteDatosAdicionales[]> {
    return this.tramitesResource.obtenerTramitesRepDatAdic({id: id});
  }

  obtenerConfiguracionTramDatAdic(idTramite: string): Promise<ConfiguracionReporteAdicional[]> {
    return this.tramitesResource.obtenerConfiguracionTramDatAdic({idTramite: idTramite});
  }

  obtenerTramitesUsuario(): Promise<TramitesModel[]> {
    return this.tramitesResource.obtenerTramitesUsuario();
  }

  obtenerAdi(id: string): Promise<AdicionalesVm[]> {
    return this.tramitesResource.obtenerAdicionales({id: id});
  }

  obtenerCfgTramite(id: string): Promise<CfgTramite> {
    return this.tramitesResource.obtenerCfgTramites({id: id});
  }

  obtenerTramiteFolio(id: string): Promise<TramitesModel[]> {
    return this.tramitesResource.obtenerTramiteFolio({id: id});
  }
}
