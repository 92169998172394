import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastService} from 'ng-uikit-pro-standard';
import {ActivatedRoute, Router} from '@angular/router';
import {Location, DatePipe} from '@angular/common';
import {ValidatorHelper} from '../../common.module/util/validator.helper';
import {DownloadHelper} from '../../common.module/util/download-helper.service';
import {ConsultaResolutivoService} from '../service/consulta-resolutivo.service';

@Component({
  selector: 'app-portal.consulta-resolutivo',
  templateUrl: './portal.consulta-resolutivo.component.html'
})
export class ConsultaResolutivoComponent implements OnInit {

  constructor(private fb: FormBuilder, private toastMsg: ToastService,
    private activedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private ConsultaResolutivoService: ConsultaResolutivoService) {
      this.activedRoute.params.subscribe(params=>{
        console.log(params['id']);
        if(params['id'] != null && params['id'] != "null"){
          this.personaAprobo = "";
          this.fechaHoraTramite = "";
          this.nombreCiudadano ="";
          this.folioTramite = "";
          this.nombreTramite = "";
          this.monto ="";
          this.idResolutivoEncontrado = false;
          this.mostrarMonto = false;
          this.mostrarNombreSolicitante = false;
        this.consultaResolutivo(params['id']);
        }else{
          this.toastMsg.error('Se necesita un id de resolutivo válido');
        }
      })
}

    nombreCiudadano:string= "";
    nombreTramite:string= "";
    folioTramite:string = "";
    fechaHoraTramite:string="";
    personaAprobo: string= ""
    monto:string="";
    mostrarMonto:boolean=false;
    mostrarNombreSolicitante:boolean=false;
    idResolutivoEncontrado:boolean=false;
    nombre_completo_funcionario_firma:string = "";
    correo_funcionario_firma:string = "";
  ngOnInit() {
  }


  consultaResolutivo(idResolutivo: string){
    this.ConsultaResolutivoService.getComprobante(idResolutivo).then(res => {

      if(res.respuesta == 200){
        this.idResolutivoEncontrado = true;
        this.personaAprobo = res.nombre_funcionario;
        this.fechaHoraTramite = res.fecha_Hora;
       // this.nombreCiudadano =res.detalle_estado_master[0].nombre_solicitante+" "+ res.detalle_estado_master[0].apellido_paterno_solicitante+" "+ res.detalle_estado_master[0].apellido_materno_solicitante
        this.nombreCiudadano = res.nombre_comercial != null ? res.nombre_comercial : res.nombre_solicitante+" "+  res.apellido_paterno_solicitante+" "+ res.apellido_materno_solicitante;
        this.nombre_completo_funcionario_firma = res.nombre_completo_funcionario_firma;
        this.correo_funcionario_firma = res.correo_funcionario_firma;
        this.folioTramite = res.folio;
        this.nombreTramite = res.nombre_tramite;
        this.mostrarMonto = false;
        this.mostrarNombreSolicitante = true;

        if(res.monto != "sin_pago")
        {
          this.mostrarMonto = true;
          this.monto = res.monto;
        }

        // if(res.apellido_materno_solicitante != undefined && res.apellido_paterno_solicitante != undefined){
        //   this.mostrarNombreSolicitante = true;
        // }

        this.toastMsg.success('Resolutivo encontrado');

      }else{
        this.idResolutivoEncontrado = false;
        this.personaAprobo = "";
        this.fechaHoraTramite = "";
        this.nombreCiudadano ="";
        this.folioTramite = "";
        this.nombreTramite = "";
        this.monto ="";
        this.toastMsg.error('No se ha encontrado este Resolutivo');
      }


  }, err => {
      if (err.body && err.body.message) {
          this.toastMsg.error(err.body.message);
      } else {
          this.toastMsg.error('No se ha encontrado resolutivo');
      }
  });
  }

}
