import { PredialModel } from './../model/predial.model';
import { AuthServerProvider } from './../../common.module/auth/auth-jwt.service';
import { ResourceAuth } from './../../common.module/auth/authenticate.resource';
import { Injectable } from '@angular/core';
import {
  IResourceMethod,
  ResourceParams,
  ResourceHandler,
  ResourceAction,
  ResourceRequestMethod,
  Resource,
} from '@ngx-resource/core';
import { ConsultaPredioModel } from '../model/consulta-predio.model';
import { PaseCajaModel } from '../model/paseCaja.model';
import { ArchivoPaseCaja } from '../model/archivoPaseCaja.model';

@Injectable()
@ResourceParams({
  pathPrefix: '/pagos'
  // url: 'http://localhost:8014'
})
export class AdeudosResource extends ResourceAuth {
  constructor(handler: ResourceHandler, authProv: AuthServerProvider) {
    super(handler, authProv);
  }
   /*export class AdeudosResource extends Resource {
       constructor(handler: ResourceHandler) {
           super(handler);
       }*/

  @ResourceAction({
    path: '/na/adeudos-ciudadano',
    method: ResourceRequestMethod.Get,
  })
  obtenerAdeudos: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/na/predio-ciudadano',
    method: ResourceRequestMethod.Get,
  })
  obtenerPredio: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/na/pase-caja-predial',
    method: ResourceRequestMethod.Post
  })
  generarPaseCajaPredial: IResourceMethod<PredialModel, any>;

  @ResourceAction({
    path: '/na/descargar-pase-caja',
    method: ResourceRequestMethod.Post
  })
  descargarPaseCaja: IResourceMethod<any[], ArchivoPaseCaja>;

  @ResourceAction({
    path: '/na/obtener-configuracion-parametros',
    method: ResourceRequestMethod.Get
  })
  obtenerConfiguracionParametros: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/na/consulta-predio',
    method: ResourceRequestMethod.Get
  })
  consultaPredio: IResourceMethod<any, ConsultaPredioModel>;

  @ResourceAction({
    path: '/na/pase-predial',
    method: ResourceRequestMethod.Post
  })
  pasePredial: IResourceMethod<any, PaseCajaModel>;

  @ResourceAction({
    path: '/na/tipo-conexion',
    method: ResourceRequestMethod.Get
  })
  obtenerTipoConexion: IResourceMethod<any, any>;
}
