import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {States} from './model/request-registration-endorsement-consultation-update/states.model';
import {Towns} from './model/request-registration-endorsement-consultation-update/towns.model';
import {Suburbs} from './model/request-registration-endorsement-consultation-update/suburbs.model';
import {Banks} from './model/request-registration-endorsement-consultation-update/banks.model';
import {Giros} from './model/request-registration-endorsement-consultation-update/giros.model';
import {PublicFileModel} from './model/public-file.model';
import {TypesDocumentsModel} from './model/request-registration-endorsement-consultation-update/types-documents.model';
import {BuscarRfcResponse} from './model/request-registration-endorsement-consultation-update/buscar-rfc-response.model';
import {DocumentsModel} from './model/request-registration-endorsement-consultation-update/documents.model';
import {DetailRequestProviderResponse} from './model/request-registration-endorsement-consultation-update/detail-request-provider-response.model';
import {DataRequestProviderResponse} from './model/request-registration-endorsement-consultation-update/data-request-provider-response.model';
import {SaveRequestProviderResponse} from './model/request-registration-endorsement-consultation-update/save-request-provider-response.model';
import {PaysResponseModel} from './model/request-registration-endorsement-consultation-update/pays-response.model';
import {DataLogResponse} from './model/request-registration-endorsement-consultation-update/data-log-response';
import {CaseFileCensusModel} from './model/request-registration-endorsement-consultation-update/case-file-census.model';
import {PrintCashPassModel} from './model/request-registration-endorsement-consultation-update/print-cash-pass.model';
import {AdvanceStatusResponse} from './model/request-registration-endorsement-consultation-update/advance-status-response.model';
import {AuthServerProvider} from '../../../common.module/auth/auth-jwt.service';
import {ResourceAuth} from '../../../common.module/auth/authenticate.resource';
import {DownloadDocument} from './model/request-registration-endorsement-consultation-update/download-document.model';
import {UpdateRequestPrinc} from './model/request-registration-endorsement-consultation-update/update-request-princ.model';
import {UpdateRequest} from './model/request-registration-endorsement-consultation-update/update-request.model';
import {RequirementsProviders} from './model/request-registration-endorsement-consultation-update/RequirementsProviders';
import {RequestFormatsEndorsementModel} from './model/request-formats-endorsement'
import {environment} from "../../../../environments/environment";
/**
 * Created by JMOLINA on 13/08/2018.
 */

@Injectable()
@ResourceParams({
  pathPrefix: '/providers'
  //   url: environment.apiCorregidoraBaseUrl + '/api/providers',
  // url: 'http://localhost:8074'
})

/*export class RequestRegistrationAndEndorsementResource extends Resource {
constructor(restHandler: ResourceHandler) {
super(restHandler);
}*/


export class RequestRegistrationAndEndorsementResource extends ResourceAuth {
    constructor(restHandler: ResourceHandler,
                provService: AuthServerProvider) {
   super(restHandler, provService);
 }


      
  @ResourceAction({
    isArray: false,
    path: '/getStates'
  })
  getStates: IResourceMethod<any, States>;
  @ResourceAction({
    isArray: false,
    path: '/getTowns'
  })
  getTowns: IResourceMethod<any, Towns>;
  @ResourceAction({
    isArray: false,
    path: '/getSuburbs'
  })
  getSuburbs: IResourceMethod<any, Suburbs>;
  @ResourceAction({
    isArray: false,
    path: '/getBanks'
  })
  getBanks: IResourceMethod<any, Banks>;
  @ResourceAction({
    isArray: false,
    path: '/getGiro'
  })
  getGiros: IResourceMethod<any, Giros>;

  @ResourceAction({
    array: true,
    path: '/na/public-file/{!id}'
  })
  getPublicFile: IResourceMethod<{ id: any }, PublicFileModel>;
  @ResourceAction({
    isArray: false,
    path: '/dataRequestProvider'
  })
  getDataRequestProv: IResourceMethod<{ email: any }, DataRequestProviderResponse>;
  @ResourceAction({
    isArray: false,
    path: '/searchByRfc'
  })
  getSearchByRfc: IResourceMethod<{ rfc: any }, BuscarRfcResponse>;
  @ResourceAction({
    isArray: false,
    path: '/detailRequestProvider'
  })
  GetDetailRequestProvider: IResourceMethod<{ email: any, request: any}, DetailRequestProviderResponse>;
  @ResourceAction({
    isArray: false,
    path: '/types-documents/{!id}'
  })
  getTypesDocuments: IResourceMethod<{ id: any }, TypesDocumentsModel>;
  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/save-document'
  })
  saveDocument: IResourceMethod<DocumentsModel, any>;

  @ResourceAction({
    isArray: false,
    path: '/documents/{!id}'
  })
  getDocuments: IResourceMethod<{ id: any }, DocumentsModel>;

  @ResourceAction({
    isArray: false,
    path: '/pays-by-sol/{!id}'
  })
  getPaysBySol: IResourceMethod<{ id: any }, PaysResponseModel>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/saveRequestProvider',
    isArray: true,
  })
  SaveRequesProvider: IResourceMethod<{ pSolProv: any [] }, SaveRequestProviderResponse>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/keys/pass',
    isArray: true,
  })
  postCashPass: IResourceMethod<PrintCashPassModel, CaseFileCensusModel>;

  @ResourceAction({
    path: '/getDataLog',
    isArray: false,
  })
  GetDataLog: IResourceMethod<{ requestKey: any }, DataLogResponse>;
  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/advance-status/{!id}',
  })
  AdvanceStatus: IResourceMethod<{ p_avanza: any, id: any }, AdvanceStatusResponse>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/download-document/{!id}',
  })
  downloadDocument: IResourceMethod<{id: any}, DownloadDocument>;

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/update-request',
  })
  updateRequest: IResourceMethod<{updateRequest: UpdateRequest []}, UpdateRequestPrinc>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/na/get-requirements?pTipoPersona={!tipoPer}',
  })
  getRequirementsProv: IResourceMethod<{tipoPer: any}, RequirementsProviders>;

  @ResourceAction({
    path: '/getFormatsEndorsement',
    method: ResourceRequestMethod.Get,
  })
  getFormatsEndorsement: IResourceMethod<any, RequestFormatsEndorsementModel[]>;

  @ResourceAction({
    path: '/generateFileBase64/{!id}',
    method: ResourceRequestMethod.Get
  })
  generateFileBase64: IResourceMethod<{id: number}, RequestFormatsEndorsementModel>;
}
