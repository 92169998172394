import {Injectable} from '@angular/core';
import {ConsultaResolutivoResource} from './consulta-resolutivo.resource';
//import {ConsultaPagoModel} from './consulta-pago.model';
//import {PdfXmlResource} from './pdfXml.resource';
//import {EndPointModel} from './end-point.model';

@Injectable({
  providedIn: 'root'
})
export class ConsultaResolutivoService {
  constructor(private consultaResolutivoResource: ConsultaResolutivoResource) {
  }

  /*
  public getInfoResolutivo(idResolutivo:string){
    return this.consultaResolutivoResource.obtenerInfoResolutivo('','');
  }*/

  /*public getComprobante(id: string) {
    return this.consultaResolutivoResource.obtenerInfoResolutivo({ id: id });
  }*/

  getComprobante(id: string): Promise<any> {
    return this.consultaResolutivoResource.obtenerInfoResolutivo({id: id});
  }

  public getComprobanteExterno(params: any): Promise<any> {
    return this.consultaResolutivoResource.getComprobanteExterno(params);
  }
}