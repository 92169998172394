/**
 * Created by Raul on 20/07/2018.
 */

import {Injectable} from '@angular/core';
import {IResourceMethod, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {FormalitiesNotesModel} from './formality-notes.model';
import {AuthServerProvider} from '../../../auth/auth-jwt.service';
import {ResourceAuth} from '../../../auth/authenticate.resource';

@Injectable()
@ResourceParams({
  // url: 'http://localhost:8073'
  pathPrefix: '/notifications'
})
// export class FormalityNotesResource extends Resource {
export class FormalityNotesResource extends ResourceAuth {
//  constructor(handler: ResourceHandler) {
//    super(handler);
//  }

  constructor(handler: ResourceHandler, auth: AuthServerProvider) {
    super(handler, auth);
  }

  @ResourceAction({
    path: '/formality-notes/{!formalities_id}'
  })
  getFormalityNotes: IResourceMethod<any, FormalitiesNotesModel[]>;

  @ResourceAction({
    path: '/formality-notes',
    method: ResourceRequestMethod.Post
  })
  saveFormalityNotes: IResourceMethod<FormalitiesNotesModel, any>;
}
