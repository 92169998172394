<div mdbModal #authorityModal="mdb-modal" class="modal fade modal-overflow" id="centralModalSuccess" tabindex="-1"
     role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-notify modal-info modal-lg" role="document">
    <!--Content-->
    <div class="modal-content">
      <div mti-color-theme class="modal-header">
        <p class="heading lead">Detalles del rol: <strong>{{authority.nombre}}</strong></p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="authorityModal.hide()">
          <span aria-hidden="true" class="white-text">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="md-form">
              <input mdbInput type="text" id="name" class="form-control"
                     [(ngModel)]="authority.nombre" disabled>
              <label for="name" class="">Nombre del rol</label>
            </div>
          </div>
          <div class="col-12">
            <div class="md-form">
              <textarea mdbInput type="text" id="description" class="md-textarea form-control"
                        [(ngModel)]="authority.descripcion" disabled>
              </textarea>
              <label for="description" class="">Descripción del rol</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mdb-tabset mti-color-theme [buttonClass]="'classic-tabs tabs-cyan'" [contentClass]="'card'" class="classic-tabs">
              <!--Panel de Trámites -->
              <mdb-tab heading="Trámites">
                <div class="row">
                  <div class="col-12">
                    <table class="table table-sm">
                      <thead>
                      <tr>
                        <th>Trámite</th>
                      </tr>
                      </thead>
                      <tbody>
                      <ng-template ngFor let-tramite [ngForOf]="authority?.tramites">
                        <tr>
                          <td>{{tramite.adm_tramite_nombre}}</td>
                        </tr>
                      </ng-template>
                      <tr *ngIf="!authority?.tramites || authority?.tramites?.length == 0">
                        <td colspan="7">
                          <p class="text-muted text-center">No se han cargado trámites al rol.</p>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </mdb-tab>
              <!--Panel de Pantallas -->
              <mdb-tab heading="Pantallas">
                <div class="row">
                  <div class="col-12">
                    <table class="table table-sm">
                      <thead>
                      <tr>
                        <th>Pantalla</th>
                        <th width="115px">Tiene acceso</th>
                      </tr>
                      </thead>
                      <tbody>
                      <ng-template ngFor let-pantalla [ngForOf]="authority?.pantallas">
                        <tr>
                          <td>{{pantalla.nombre}}</td>
                          <td>
                            <div class="form-check">
                              <input class="form-check-input" mdbInput type="checkbox"
                                     [id]="'tiene_acceso_' + i"
                                     [(ngModel)]="pantalla.tiene_acceso" disabled>
                              <label class="form-check-label" [for]="'tiene_acceso_' + i"></label>
                            </div>
                          </td>
                        </tr>
                      </ng-template>
                      <tr *ngIf="!authority?.pantallas || authority?.pantallas?.length == 0">
                        <td colspan="7">
                          <p class="text-muted text-center">No se han cargado pantallas al rol.</p>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </mdb-tab>
            </mdb-tabset>
          </div>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--Modal: Login / Register Form-->
