import { Injectable } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { OperacionesMapaService } from './operaciones-mapa.service';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { isNullOrUndefined } from 'util';

@Injectable()
export class ReporteTramitePdfService {

  private readonly COLOR_BLACK = 'black';
  private readonly COLOR_WHITE = 'white';
  private readonly COLOR_FILL_FILA = '#1e4d7c'
  private readonly COLOR_TEXTO_TITULO = '#224f80';
  private readonly TAM_TEXTO_TITULO = 14;
  private readonly TAM_TEXTO_TITULO_CATEGORIA = 17;
  private readonly TAM_NOM_SEC =  12;
  private readonly TAM_FILA = 12;
  private readonly TAM_CABECERA = 12;

  constructor(
    private opMapaService: OperacionesMapaService
  ) { }

  exportarPdf(nombreReporte: string, categoriaReporte: string, solicitud: any, logo: string, imagenInformacion: string, firma: string) {
    return new Promise<string>(async(resolve, reject) => {
      let contentPdf: any[] = [];
      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      if (logo && !logo.startsWith('data:image')) 
        logo = 'data:image/png;base64,' + logo;
      if (imagenInformacion && !imagenInformacion.startsWith('data:image')) 
        imagenInformacion = 'data:image/png;base64,' + imagenInformacion;
      if (firma && !firma.startsWith('data:image')) 
        firma = 'data:image/png;base64,' + firma;

      contentPdf.push(this.getCabecera(nombreReporte, categoriaReporte, logo, imagenInformacion));
      contentPdf.push(this.getFechaActual());

      for(let key in solicitud) {
        if (solicitud[key].datos.length > 0) {
          const datos: any[] = solicitud[key].datos;
          const nombre = solicitud[key].nombre;

          if (!solicitud[key].esMapa) {
            contentPdf.push(this.getSecciones(datos, nombre));
          } else {
            let latitud = '';
            let longitud = '';
            for(let dato of datos) {
              if (dato.etiqueta.toUpperCase() === 'LATITUD') {
                latitud = dato.valor_usuario;
              } else if (dato.etiqueta.toUpperCase() === 'LONGITUD') {
                longitud = dato.valor_usuario;
              }
            }

            if (latitud && longitud) {
              const mapa: string = await this.obtenerMapaUbicacion(
                +latitud,
                +longitud
              );
              contentPdf.push(this.getSeccionesImagenes(mapa, 530, 250, nombre));
            }
          }
        }
      }

      if (firma) {
        contentPdf.push(this.getSeccionFirma(firma));
      }

      const docDefinition = {
        info: {
          title: nombreReporte
        },
        pageMargins: [30, 10, 30, 50],
        content: contentPdf,
        styles: this.getStyles(),
        footer: (currentPage, pageCount) =>  {
          return {
            text: currentPage.toString() + ' de ' + pageCount,
            alignment: 'right',
            margin: [0, 30, 10, 0],
            color: 'gray',
            bold: true
          };
        },
      }

      try {
        // pdfMake.createPdf(docDefinition).open();
        pdfMake.createPdf(docDefinition).getBase64(ex => {
          resolve(ex);   
        });
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  }

  getSeccionFirma(imagen: string) {
    return [
      {
        style: 'imagenFirma',
        columns: [
          {
            text: ''
          },
          {
            image: imagen,
            width: 240,
            height: 155,
          }
        ]
      }
    ];
  }

  getSeccionesImagenes(imagen: string, ancho: number, alto, nombreSeccion: string) {
    const resultado = [];
    resultado.push(
      {
        text: nombreSeccion,
        style: 'nombreSeccion'
      },
      {
        image: imagen,
        width: ancho,
        height: alto,
      }
    );

    return resultado;
  }

  getSecciones(registros: any[], nombreSeccion: string) {
    const resultado = [];
    let bodyFinal = [];
    const noColumnasPorFila = 3;

    const dataAgrupada = this.agruparSecciones(registros);
    for(let seccion in dataAgrupada) {
        const registro = dataAgrupada[seccion];
        const data = registro.datos;
        let tamanoData = data.length;
        let contador = 0;

        // Se agrega una fila que representa la seccion
        if (!isNullOrUndefined(seccion) && seccion != 'undefined' && seccion != 'null' && seccion.trim().length > 0) {
          bodyFinal.push([{
              colSpan: 3,
              style: 'filaTablaCabecera',
              text: seccion,
              borderColor: [this.COLOR_BLACK, this.COLOR_BLACK, this.COLOR_BLACK, this.COLOR_BLACK]
          },{},{}]);
        }

        while(tamanoData > 0) {
          let noActualColum = 0;
          let bodyRows = [];
          let headerPdf = [];

          if (tamanoData >= noColumnasPorFila) {
            tamanoData = tamanoData - noColumnasPorFila;
            noActualColum = noColumnasPorFila;
          } else {
            noActualColum = tamanoData;
            tamanoData = 0;
          }

          for(let index = 0; index <noActualColum; index++) {
            headerPdf.push({
              style: 'filaTablaCabecera',
              text: data[contador].etiqueta,
              borderColor: [this.COLOR_BLACK, this.COLOR_BLACK, this.COLOR_BLACK, this.COLOR_BLACK]
            });

            bodyRows.push({
              style: 'filaTablaFila',
              text: data[contador].valor_usuario ? data[contador].valor_usuario : ' ',
              borderColor: [this.COLOR_BLACK, this.COLOR_BLACK, this.COLOR_BLACK, this.COLOR_BLACK]
            });

            contador++;
          }


          // Se agregan columnas en blanco para cerrar los cuadros
          if (noActualColum < noColumnasPorFila) {
              for(let index = 0; index < noColumnasPorFila - noActualColum; index++) {
                headerPdf.push({
                  style: 'filaTablaCabecera',
                  text: '',
                });

                bodyRows.push({
                  style: 'filaTablaFila',
                  text: '',
                });
              }
          }

          bodyFinal.push(headerPdf);
          bodyFinal.push(bodyRows);
        }
    }

    resultado.push(
      {
        text: nombreSeccion,
        style: 'nombreSeccion'
      },
      {
        table: {
          // Le dice que todas sus columnas sean del mismo tamano
          widths: [168.93, 168.93, 168.93],
          body: bodyFinal,
        },
      }
    );

    return resultado;
  }

  getCabecera(titulo: string, categoria: string, imagenIzquierda: string, imagenDerecha: string) {
    const columnasCabecera: any[] = [];
    if (imagenIzquierda) {
      columnasCabecera.push({
        image: imagenIzquierda,
        width: 110,
        height: 90,
      });
    }
    columnasCabecera.push({
      // style: 'cabeceraTitulo',
      text: [
        {
          text: categoria, 
          style: 'cabeceraTituloCategoria'
        },
        {
          text: '\n\n',
        },
        {
          text: titulo,
          style: 'cabeceraTitulo',
        }
      ],
    });
    if (imagenDerecha) {
      columnasCabecera.push({
        image: imagenDerecha,
        width: 160,
        height: 90,
      });
    }

    return {
      style: 'cabeceraPrincipal',
      columns: columnasCabecera
    };
  }

  getFechaActual() {
    const fecha = Date.now();
    const fechaPipe = new DatePipe('en-US');
    let dia = fechaPipe.transform(fecha, 'dd');
    let mes = fechaPipe.transform(fecha, 'MM');
    let anio = fechaPipe.transform(fecha, 'yyyy');

    return [
      {
        style: 'tablaFecha',
        table: {
          widths: ['*', 150],
          body: [
            [
                {
                    text: '',
                    borderColor: [this.COLOR_WHITE, this.COLOR_WHITE, this.COLOR_BLACK, this.COLOR_WHITE]
                },
              {
                text: 'Fecha',
                style: 'filaTablaCabecera',
                borderColor: [this.COLOR_BLACK, this.COLOR_BLACK, this.COLOR_BLACK, this.COLOR_BLACK]
              }
            ],
            [
              {
                  text: '',
                  borderColor: [this.COLOR_WHITE, this.COLOR_WHITE, this.COLOR_BLACK, this.COLOR_WHITE]
              },
              {
                alignment: 'right',
                columns: [
                  {
                    text: [dia + ' /']
                  },
                  {
                    text: [mes + ' /']
                  },
                  {
                    text: anio
                  }
                ]
              }
            ]
          ],
        },
      }
    ]
  }

  getStyles() {
    return {
      cabeceraPrincipal: {
        margin: [0, 10, 0, 0],
      },
      cabeceraTituloCategoria: {
        alignment: 'center',
        bold: true,
        fontSize: this.TAM_TEXTO_TITULO_CATEGORIA,
        color: this.COLOR_TEXTO_TITULO
      },
      cabeceraTitulo: {
        alignment: 'center',
        bold: true,
        fontSize: this.TAM_TEXTO_TITULO,
        color: this.COLOR_TEXTO_TITULO
      },
      filaTablaCabecera: {
        fillColor: this.COLOR_FILL_FILA,
        fontSize: this.TAM_CABECERA,
        bold: true,
        color: this.COLOR_WHITE
      },
      filaTablaFila: {
        fontSize: this.TAM_FILA,
      },
      tablaFecha: {
        margin: [0, 10, 0, 10],
        alignment: 'right',
      },
      nombreSeccion: {
        bold: true,
        fontSize: this.TAM_NOM_SEC,
        margin: [0, 10, 0, 0],
      },
      imagenFirma: {
        alignment: 'justify',
        margin: [0, 30, 0, 0],
      }
    };
  }

  agruparSecciones(data: any[]) {
    const obj = {};
    data.forEach(d => {
      if (!obj.hasOwnProperty(d.seccion)) {
          obj[d.seccion] = {
              datos: []
          }
      }

      obj[d.seccion].datos.push(d);
    });

    return obj;
  }

  obtenerMapaUbicacion(latitud: number, longitud: number) {
    let elementoContenedorMapa: any;
    return new Promise<string>((resolve, reject) => {
      this.opMapaService.crearMapa(latitud, longitud, false, true)
      .then(elemento => {
        // document.body.appendChild(elemento);
        elementoContenedorMapa = elemento.parentElement;
        of(([])).pipe(
          delay(3000)
        ).subscribe(() => {
          return this.opMapaService.convertirElementABase64(elemento)
            .then(base64 => {
              document.body.removeChild(elementoContenedorMapa);
              resolve(base64);
            });
        });
      })
      .catch(error => {
        if (elementoContenedorMapa)
          document.body.removeChild(elementoContenedorMapa);
        reject(error);
      });
    });
  }
}
