import {Injectable} from '@angular/core';
import {BpmEstadoProcesoDetalleResource} from './bpm-estado-proceso-detalle.resource';
import {BpmEstadoProcesoDetalleModel} from './bpm-estado-proceso-detalle.model';
import {RegistraFirmaModel} from '../../../common.module/service/registra-firma.model';
// carpeta
@Injectable()
export class BpmEstadoProcesoDetalleService {
  constructor(private bpmEstadoProcesoDetalleResource: BpmEstadoProcesoDetalleResource) {
  }

  postBpmEstadoProcesoDetalle(bpmEstadoProcesoDetalleModel: BpmEstadoProcesoDetalleModel): Promise<any> {
    return this.bpmEstadoProcesoDetalleResource.postBmpEstadoProcesoDetalle(bpmEstadoProcesoDetalleModel);
  }

  postGuardarFirmarArchivo(registraFirmaModel: RegistraFirmaModel): Promise<any> {
    return this.bpmEstadoProcesoDetalleResource.postGuardarFirmarArchivo(registraFirmaModel);
  }

  patchBpmEstadoProcesoDetalle(bpmEstadoProcesoDetalleModel: BpmEstadoProcesoDetalleModel): Promise<any> {
    return this.bpmEstadoProcesoDetalleResource.patchBmpEstadoProcesoDetalle(bpmEstadoProcesoDetalleModel);
  }

  patchBpmEstProcDetActDatAdic(bpmEstadoProcesoDetalleModel: BpmEstadoProcesoDetalleModel): Promise<any> {
    return this.bpmEstadoProcesoDetalleResource.patchBpmEstProcDetActDatAdic(bpmEstadoProcesoDetalleModel);
  }

  deleteBpmEstadoProcesoDetalle(id: string) {
    return this.bpmEstadoProcesoDetalleResource.deleteBmpEstadoProcesoDetalle({id: id});
  }
  getListaBpmEstadoProcesoDetallePorIdMaster(id: string): Promise<BpmEstadoProcesoDetalleModel []> {
    return this.bpmEstadoProcesoDetalleResource.getListaBmpEstadoProcesoDetallePorIdMaster({id: id});
  }

  getEstadoProcesoDoc(id: string): Promise<RegistraFirmaModel> {
    return this.bpmEstadoProcesoDetalleResource.getEstadoProcesoDoc({id: id});
  }

}
