import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';
import {LocationsModel} from "./locations.model";

@Injectable()
@ResourceParams({
  pathPrefix: '/core'
  // url: 'http://localhost:8004'
})

export class LocationsRepCiudResource extends ResourceAuth {
// export class ReporteCiudadanoResource extends Resource {
  constructor(restHandler: ResourceHandler, provService: AuthServerProvider) {
    super(restHandler, provService);
  }
  // constructor(restHandler: ResourceHandler) {
  //   super(restHandler);
  // }

  @ResourceAction({
    isArray: true,
    // method: ResourceRequestMethod.Get,
    path: '/na/cargar-estados'
  })
  obtenerEstados: IResourceMethod<{}, LocationsModel[]>;

  @ResourceAction({
    isArray: true,
    // method: ResourceRequestMethod.Get,
    path: '/na/cargar-municipios/{!key}'
  })
  obtenerListaMunicipios: IResourceMethod<{key: string}, LocationsModel[]>;

  @ResourceAction({
    isArray: true,
    // method: ResourceRequestMethod.Get,
    path: '/na/cargar-colonias/{!municipio_id}/{!key}'
  })
  obtenerListaColonias: IResourceMethod<{municipio_id: string, key: string}, LocationsModel[]>;
}
