/**
 * Created by MTI on 22/06/2018.
 */


export class DatePickerConstant {
  public static datePickerOptions = {
    dateFormat: 'dd/mm/yyyy',
    minYear: 1900,
    maxYear: 2060,
    
    dayLabels: {su: 'Dom', mo: 'Lun', tu: 'Mar', we: 'Mier', th: 'Jue', fr: 'Vie', sa: 'Sab'},
    dayLabelsFull: {
      su: 'Domingo',
      mo: 'Lunes',
      tu: 'Martes',
      we: 'Miércoles',
      th: 'Jueves',
      fr: 'Viernes',
      sa: 'Sábado'
    },
    monthLabels: {
      1: 'Ene', 2: 'Feb', 3: 'Mar', 4: 'Abr', 5: 'May', 6: 'Jun', 7: 'Jul',
      8: 'Ago', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dic'
    },
    monthLabelsFull: {
      1: 'Enero',
      2: 'Febrero',
      3: 'Marzo',
      4: 'Abril',
      5: 'Mayo',
      6: 'Junio',
      7: 'Julio',
      8: 'Agosto',
      9: 'Septiembre',
      10: 'Octubre',
      11: 'Noviembre',
      12: 'Diciembre'
    },

    showClearDateBtn: false,
    showTodayBtn: false,

    closeBtnTxt: 'Cerrar',

    closeAfterSelect: true,
  };
}
