/**
 * Created by MTI on 04/05/2018.
 */

import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthResource} from './service/auth.resource';
import {LoginModel} from './service/login.model';
import {AuthServerProvider} from '../common.module/auth/auth-jwt.service';
import {Router} from '@angular/router';
import {ToastService} from 'ng-uikit-pro-standard';
import {LoginService} from './service/login.service';
import {ConfiguracionGeneralModel} from '../admin.configuracion-general.module/service/configuracion-general.model';
import {ConfiguracionGeneralService} from '../admin.configuracion-general.module/service/configuracion-general.service';
import { isUndefined } from 'util';

@Component({
  selector: 'app-main-login',
  templateUrl: 'main-login.component.html'
})
export class MainLoginComponent implements OnInit {
  public loginForm: FormGroup;
  public authModel: LoginModel = <LoginModel>{};
  networkMode = 'online';
  public configuracion: ConfiguracionGeneralModel = {};
  public esDobleAuth = false;

  constructor(private fb: FormBuilder,
              private loginService: LoginService,
              private authResource: AuthResource,
              private toastMsg: ToastService,
              private authProvider: AuthServerProvider,
              private configuracionGeneralService: ConfiguracionGeneralService,
              private router: Router) {
    navigator.onLine === true ? this.networkMode = 'online' : this.networkMode = 'offline';
  }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([Validators.email, Validators.required])],
      password: ['', [Validators.required]],
      codigo: ['', Validators.pattern('^[0-9]+')]
    });
    this.configuracionGeneralService.obtenerConfiguracionGeneral().then(configuracion => {
      this.configuracion = configuracion;
    }, err => {
      console.log('Error al cargar la configuración en el pie de página');
      console.error(err);
    });
    localStorage.removeItem('mcp-id_token'); //TODO
    localStorage.removeItem('token_corregidora'); //TODO
  }

  login() {
    let codigo = this.loginForm.controls['codigo'].value.toString();
    if(codigo != ""){
      this.loginForm.controls['codigo'].setValue(codigo);
    }
    this.authResource.login(<LoginModel> this.loginForm.value).then(res => {
      console.log(res);
      if(res.token != undefined){
        this.esDobleAuth = false;
      this.authProvider.storeAuthenticationToken(res.token, false);
      this.toastMsg.success('Hemos iniciado sesión.');
      this.router.navigate(['app']);
    }else{
     this.toastMsg.success("Código de autentificación enviado a tu correo electrónico.");
      this.esDobleAuth = true;
    }
      // Reditrect app
      // Save token locastorage
    }, err => {
      // Mensaje de error
      console.log(err);
      if (err && err.body && err.body.message)
        this.toastMsg.error(err.body.message);
    });
  }
}
