import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ModalDirective} from 'angular-bootstrap-md';
import {ExpedientesElectModel} from '../service/expedientes-elect.model';
import {ModalDirective as md, TabsetComponent, ToastService} from 'ng-uikit-pro-standard';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LocationsService} from '../service/locations.service';
import {LocationsModel} from '../service/locations.model';
import {RequirementModel} from '../../common.module/model/requirement.model';
import {RequirementsUploadModel} from '../../common.module/components/upload-requirements.component/service/requirement-helper.model';
import {RequisitosService} from '../service/requisitos.service';
import {ValidatorHelper} from '../../common.module/util/validator.helper';
import {GeneralPatternsValidators} from '../../common.module/util/general.patterns.validator';
import {ConsultaExpElecService} from '../service/consulta-exp-elec.service';
import {CaseFileModel} from '../../common.module/components/upload-requirements.component/service/case-file-uploaded.model';
import {environment} from '../../../environments/environment';
import {VigenciaReqModel} from '../service/vigencia-req.model';
import {UploadRequirementsComponent} from '../../common.module/components/upload-requirements.component/upload-requirements.component';
import {UsuarioService} from '../../common.tramite.module/entidad.component/service/usuario.service';
import {UsuarioExpService} from '../service/usuario.service';


@Component({
  selector: 'app-cons-exp-elec-detalle',
  templateUrl: 'cons-exp-elec-detalle.component.html'
})
export class ConsExpElecDetalleComponent implements OnInit {

  @Input()
  public listaElementos: ExpedientesElectModel[];

  @ViewChild('basicConfirmModal') basicConfirmModal?: md;

  @Output()
  reload: EventEmitter<any> = new EventEmitter();
  @ViewChild('detExpElectModal')
  public modal: ModalDirective;
  @ViewChild('appUploadRequirements')
  private appUploadRequirements: UploadRequirementsComponent;
  public habilitarArchivoVencido = false;
  detExpedienteForm: FormGroup;

  public optTipoPersona = [{
    label: 'Persona física',
    value: 'Fisica'
  }, {
    label: 'Persona moral',
    value: 'Moral'
  }];
  public optEstado = [];
  public optMunicipio = [];
  public optColonia = [];
  public desPerMoral = true;
  public desPerFisica = true;
  public editar_title = 'Editar';
  public editando = false;
  disDel = true;
  main_action = 0;
  approveReady = false;
  pathUploadUrl = environment.apiBaseUrl + '/api/core/upldFiles';
  // pathUploadUrl = 'http://localhost:8012/upldFiles';
  showReq = true;
  isDownloaded = true;
  field = false;
  validity = true;
  manageLoading = {loadCount: 0};
  blockLoading = true;
  @ViewChild('staticTabs') staticTabs: TabsetComponent;
  hideBtn = true;
  public usuario_id = '1';

  @Input()
  public expedienteElect: ExpedientesElectModel = <ExpedientesElectModel>{};
  public location: LocationsModel = <LocationsModel>{};
  public numRequisito: string;
  public vigencia: VigenciaReqModel = <VigenciaReqModel>{};
  public observacion: String;
  public estatus: String;

  public requirementModel: RequirementModel [] = [];
  public requirements: RequirementsUploadModel[] = [];
  public listaCorreo: any[] = [];

  constructor(private toastMsg: ToastService,
              private fb: FormBuilder,
              private locationsService: LocationsService,
              private requisitosService: RequisitosService,
              private consultaExpElecService: ConsultaExpElecService,
              private usuarioService: UsuarioExpService,
              private usuarioLogService: UsuarioService) {
  }

  ngOnInit() {
    this.detExpedienteForm = this.fb.group({
      formUsuario: ['', Validators.compose([Validators.required])],
      formName: [''],
      formApPaterno: [''],
      formApMaterno: [''],
      formCurp: [''],
      formIne: [''],
      formRfc: ['', Validators.compose([Validators.required, Validators.pattern(GeneralPatternsValidators.RFC_PATTERN_EXP)])],
      formTipoPersona: ['', Validators.compose([Validators.required])],
      formRazonSoc: [''],
      formProp: [''],
      formEstado: ['', Validators.compose([Validators.required])],
      formMunicipio: ['', Validators.compose([Validators.required])],
      formColonia: ['', Validators.compose([Validators.required])],
      formCiudad: ['',  Validators.compose([Validators.required])],
      formCalle: ['',  Validators.compose([Validators.required])],
      formNumExt: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]{1,6}')])],
      formNumInt: ['', Validators.compose([Validators.pattern('[0-9]{1,6}')])],
      formLetra: ['', Validators.compose([Validators.pattern('[A-Z]{1,2}')])],
      formCp: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]{1,5}')])]
    });
    this.obtenerListaEstados();
    this.obtenerUsuarios();
    this.validarUsuarios();
  }
  buscarINE() {
    if (this.expedienteElect.ine == undefined || this.expedienteElect.ine == null || this.expedienteElect.ine == '') {
      console.log('#########################');
      console.log('BUSCANDO INE DE: ' + this.expedienteElect.curp);
      this.consultaExpElecService.obtenerINE(this.expedienteElect.curp).then(ine => {
        this.detExpedienteForm.get('formIne').setValidators([Validators.compose(
          [Validators.pattern('[A-Z0-9]{18}')])]);
        this.expedienteElect.ine = ine.ine;
        this.locationsService.obtenerDatosLocation(this.location.municipality_id,
          this.location.key, this.expedienteElect.cp)
          .then(resp => {
            // Creación del objeto que contendra los datos del expediente electronico
            const xpedientesElectModel = this.expedienteElect;
            // xpedientesElectModel.usuarios_id = this.usuario_id;
            xpedientesElectModel.location = resp;
            this.consultaExpElecService.actualizaExpedienteServ(xpedientesElectModel).then().catch(e => {
              if (e.body.message)
                this.toastMsg.error(e.body.message);
            });
          }, err => {
            console.log('Error');
            console.log(err);
            if (err.body.message) {
              this.toastMsg.error(err.body.message);
            } else {
              this.toastMsg.error('Ocurrio un error al consultar la lista de estados.');
            }
          });
      }, err => {
        if (err.body) {
          if (err.body.message) {
            this.toastMsg.error(err.body.message);
          } else {
          }
        } else {
        }
      });
    }
  }
  limpiarINE() {
    console.log('Entro a limpair');
    this.expedienteElect.ine = '';
  }
    validarUsuarios() {
    this.usuarioLogService.obtenerUsuarioSesion().then(resp => {
      resp.roles.map(rol => {
        console.log('este es el rol a revisar: ' + rol.nombre);
        console.log('este es el rol a revisar miniscular: ' + rol.nombre.toLocaleLowerCase());
        if (rol.nombre.toLocaleLowerCase() == 'administrador') {
          this.field = true;
          console.log('Si es administrador');
        }
      });
    }, err => {
      console.log(err);
      if (err.body) {
        if (err.body.message) {
          this.toastMsg.error(err.body.message);
        } else {
          this.toastMsg.error('Ocurrio un error al consultar la lista usuarios.');
        }
      } else {
        this.toastMsg.error('Ocurrio un error al consultar la lista usuarios.');
      }
    });
  }
  limpiarDatos() {
    this.editando = false;
    this.appUploadRequirements.limpiarDatos();
    this.disDel = true;
    this.habilitarArchivoVencido = false;
    this.editar_title = 'Editar';
    // this.appUploadRequirements.manageLoading = {loadCount : 0};
  }

  obtenerListaEstados() {
    this.locationsService.obtenerListaEstados().then(resp => {
      this.optEstado = resp.map(estado => {
        return {
          value: estado.key,
          label: estado.state
        };
      });
    }, err => {
      console.log(err);
      if (err.body.message) {
        this.toastMsg.error(err.body.message);
      } else {
        this.toastMsg.error('Ocurrio un error al consultar la lista de estados.');
      }
    });
  }

  buscarListaMunicipios() {
    this.locationsService.obtenerListaMunicipios(this.location.key).then(resp => {

      this.optMunicipio = resp.map(municipio => {
        return {
          value: municipio.municipality_id,
          label: municipio.municipality
        };
      });
      console.log(this.optMunicipio);
    }, err => {
      console.log(err);
      if (err.body.message) {
        this.toastMsg.error(err.body.message);
      } else {
        this.toastMsg.error('Ocurrio un error al consultar la lista de municipios.');
      }
    });
  }

  buscarListaColonias(township) {
    console.log(this.location);
    console.log(township);
    this.locationsService.obtenerListaColonias(this.location.municipality_id, this.location.key).then(resp => {
      this.optColonia = resp.map(colonia => {
        return {
          value: colonia.township,
          label: colonia.township
        };
        console.log(township);
        if (township) {
          this.location.township = township;
        }
      }, err => {
        if (err.body.message) {
          this.toastMsg.error(err.body.message);
        } else {
          this.toastMsg.error('Ocurrio un error al consultar la lista de colonias.');
        }
      });
    });
  }

  cambioPersona() {
    if (this.expedienteElect.tipo_persona === 'Fisica') {
      this.desPerMoral = false;
      this.desPerFisica = true;
      this.detExpedienteForm.get('formName').setValidators([Validators.compose(
        [Validators.required])]);
      this.detExpedienteForm.get('formName').updateValueAndValidity();
      this.detExpedienteForm.get('formApPaterno').setValidators([Validators.compose(
        [Validators.required])]);
      this.detExpedienteForm.get('formApPaterno').updateValueAndValidity();
      this.detExpedienteForm.get('formApMaterno').setValidators([Validators.compose(
        [Validators.required])]);
      this.detExpedienteForm.get('formApMaterno').updateValueAndValidity();

      this.detExpedienteForm.get('formCurp').setValidators(
        Validators.compose([Validators.required, Validators.pattern(GeneralPatternsValidators.CURP_PATTERN)]));
      this.detExpedienteForm.get('formCurp').updateValueAndValidity();

      this.detExpedienteForm.get('formIne').updateValueAndValidity();
      this.detExpedienteForm.get('formRazonSoc').setValidators([]);
      this.detExpedienteForm.get('formRazonSoc').updateValueAndValidity();
      this.detExpedienteForm.get('formProp').setValidators([]);
      this.detExpedienteForm.get('formProp').updateValueAndValidity();
    } else if (this.expedienteElect.tipo_persona === 'Moral') {
      this.desPerFisica = false;
      this.desPerMoral = true;
      this.detExpedienteForm.get('formRazonSoc').setValidators([Validators.compose(
        [Validators.required])]);
      this.detExpedienteForm.get('formRazonSoc').updateValueAndValidity();
      this.detExpedienteForm.get('formProp').setValidators([Validators.compose(
        [Validators.pattern('[a-z-A-Z áéíóúÁÉÍÓÚñÑ]{1,50}'), Validators.required])]);
      this.detExpedienteForm.get('formProp').updateValueAndValidity();

      this.detExpedienteForm.get('formName').setValidators([]);
      this.detExpedienteForm.get('formName').updateValueAndValidity();
      this.detExpedienteForm.get('formApPaterno').setValidators([]);
      this.detExpedienteForm.get('formApPaterno').updateValueAndValidity();
      this.detExpedienteForm.get('formApMaterno').setValidators([]);
      this.detExpedienteForm.get('formApMaterno').updateValueAndValidity();
      this.detExpedienteForm.get('formCurp').setValidators([]);
      this.detExpedienteForm.get('formCurp').updateValueAndValidity();
    }
  }

  obtenerListaRequisitos() {
    this.requisitosService.obtenerListaRequisitos('1').then(resp => {

      this.requirementModel = resp.map(requisito => {
        return {
          id: requisito._id,
          requirement_name: requisito.descripcion,
          required: requisito.obligatorio === '1' ? true : false,
          estatus: requisito.estatus
        };
      });

      this.requirementModel.forEach(val => {
        this.requirements.push({
          requirement: val
        });
      });
    }, err => {
      console.log(err);
      if (err.body.message) {
        this.toastMsg.error(err.body.message);
      } else {
        this.toastMsg.error('Ocurrio un error al consultar la lista de estados.');
      }
    });
  }

  /**
   * Se guarda la solicitud con todos los requerimientos
   */
  guardarSolicitud() {
    // varibale para guardar los requisitos que son obligatorios y no se han cargado
    const errores = [];
    // Valida que el formulario sea válido
    if (this.detExpedienteForm.valid == true) {
      if (this.expedienteElect.tipo_persona == 'Fisica') {
        this.expedienteElect.razon_social = '';
        this.expedienteElect.propietario = '';
      } else if (this.expedienteElect.tipo_persona == 'Moral') {
        this.expedienteElect.nombre = '';
        this.expedienteElect.apellido_m = '';
        this.expedienteElect.apellido_p = '';
        this.expedienteElect.curp = '';
        this.expedienteElect.ine = '';
      }

      if (this.expedienteElect.propietario) {
        this.expedienteElect.propietario = this.expedienteElect.propietario.toUpperCase();
      }
      if (this.expedienteElect.razon_social) {
        this.expedienteElect.razon_social = this.expedienteElect.razon_social.toUpperCase();
      }
      if (this.expedienteElect.nombre) {
        this.expedienteElect.nombre = this.expedienteElect.nombre.toUpperCase();
      }
      if (this.expedienteElect.apellido_p) {
        this.expedienteElect.apellido_p = this.expedienteElect.apellido_p.toUpperCase();
      }
      if (this.expedienteElect.apellido_m) {
        this.expedienteElect.apellido_m = this.expedienteElect.apellido_m.toUpperCase();
      }
      if (this.expedienteElect.letra) {
        this.expedienteElect.letra = this.expedienteElect.letra.toUpperCase();
      }
      if (this.expedienteElect.rfc)
        this.expedienteElect.rfc = this.expedienteElect.rfc.toUpperCase();

      if (this.expedienteElect.calle)
        this.expedienteElect.calle = this.expedienteElect.calle.toUpperCase();

      if (this.expedienteElect.colonia)
        this.expedienteElect.colonia = this.expedienteElect.colonia.toUpperCase();

      if (this.expedienteElect.ciudad)
        this.expedienteElect.ciudad = this.expedienteElect.ciudad.toUpperCase();

      if (this.expedienteElect.curp)
        this.expedienteElect.curp = this.expedienteElect.curp.toUpperCase();

      if (this.expedienteElect._id && this.expedienteElect._id !== '') {
        console.log('Si tiene id******************************+');
        this.requirements.forEach(req => {
          if (req.requirement.required && !req.caseFileUploaded) {
            // En caso de que no se tengan archivos se agregan a un arreglo de errores
            if (!req.uploadFile) {
              errores.push(req.requirement.requirement_name);
            }
          }
        });

        if (errores.length > 0) {
          // Manda mensaje con todos los archivos que faltan por subir
          this.toastMsg.warning('Debe de agregar los siguientes requisitos obligatorios: \n' + errores);
        } else {
          // Se obtienen todos los datos de la ubicacion para agregarlos a la solicitud
          this.locationsService.obtenerDatosLocation(this.location.municipality_id,
            this.location.key, this.expedienteElect.cp)
            .then(resp => {
              // Creación del objeto que contendra los datos del expediente electronico
              const xpedientesElectModel = this.expedienteElect;
              // xpedientesElectModel.usuarios_id = this.usuario_id;
              xpedientesElectModel.location = resp;
              // Se guarda el expediente electronico
              this.consultaExpElecService.actualizaExpedienteServ(xpedientesElectModel).then(respAct => {
                console.log(respAct);
                // Arreglo para guardar todos los documentos que se subieron con la solicitud
                const stackRequisitos: Promise<any>[] = [];
                // Se recorre el arreglo de los requerimientos para obtener sus datos
                this.requirements.forEach(reqCargados => {
                  // En caso de que el requerimiento contenga un archivo
                  if (reqCargados.uploadFile) {
                    if (reqCargados.caseFileUploaded) {
                      console.log('Entro al if principal');
                      const ext = reqCargados.uploadFile.type.split('/');
                      // objeto que guardara los datos del requerimiento
                      const cargaArchivosReq = <any>{
                        _id: reqCargados.requirement.id,
                        nombreTemp: reqCargados.uploadFile.response.name,
                        expReqId: reqCargados.requirement.id,
                        solExpId: xpedientesElectModel._id,
                        extension: ext[1]
                      };
                      console.log(cargaArchivosReq);
                      // actualizarRequisitosServ
                      // Se agregan los datos al arreglo para guardar los archivos
                      stackRequisitos.push(this.requisitosService
                        .guardarArchivoExpedienteServ(cargaArchivosReq));
                    } else {
                      console.log('Entro al else principal');
                      const ext = reqCargados.uploadFile.type.split('/');
                      // objeto que guardara los datos del requerimiento
                      const cargaArchivosReq = <any>{
                        nombreTemp: reqCargados.uploadFile.response.name,
                        expReqId: reqCargados.requirement.id,
                        solExpId: xpedientesElectModel._id,
                        extension: ext[1]
                      };
                      console.log(cargaArchivosReq);
                      // Se agregan los datos al arreglo para guardar los archivos
                      stackRequisitos.push(this.requisitosService
                        .guardarArchivoExpedienteServ(cargaArchivosReq));
                    }
                  }
                });
                // Se guardan los archivos del expediente
                Promise.all(stackRequisitos).then(respReq => {
                  this.toastMsg.success('La solicitud de expediente electrónico se actualizó de forma correcta');
                  this.reload.emit();
                  this.modal.hide();
                  console.log(respReq);
                }, err => {
                  console.log(err);
                  if (err.body.message) {
                    this.toastMsg.error(err.body.message);
                  } else {
                    this.toastMsg.error('Ocurrio un error al consultar la lista de estados.');
                  }
                });
              })
              .catch(e => {
                if (e.body.message)
                  this.toastMsg.error(e.body.message);
              });
            }, err => {

              if (err.body.message) {
                this.toastMsg.error(err.body.message);
              } else {
                this.toastMsg.error('Ocurrio un error al consultar la lista de estados.');
              }
            });
        }

      } else {

        // Recorre los requerimientos para validarlos
        this.requirements.forEach(req => {
          // Valida que los requerimientos sean obligatorios y que se tenga un archivo
          if (req.requirement.required && !req.uploadFile) {
            // En caso de que no se tengan archivos se agregan a un arreglo de errores
            errores.push(req.requirement.requirement_name);
          }
        });
        // Valida que no se tengan errore en los requerimientos
        if (errores.length > 0) {
          // Manda mensaje con todos los archivos que faltan por subir
          this.toastMsg.warning('Debe de agregar los siguientes requisitos obligatorios: \n' + errores);
        } else {

          // Se obtienen todos los datos de la ubicacion para agregarlos a la solicitud
          this.locationsService.obtenerDatosLocation(this.location.municipality_id,
            this.location.key, this.expedienteElect.cp)
            .then(resp => {
              console.log(this.requirements);
              console.log(resp);
              // Creación del objeto que contendra los datos del expediente electronico
              const xpedientesElectModel = this.expedienteElect;
              // xpedientesElectModel.usuarios_id = this.usuario_id;
              xpedientesElectModel.location = resp;
              console.log(xpedientesElectModel);
              // Se guarda el expediente electronico
              this.consultaExpElecService.guardarExpedienteServ(xpedientesElectModel)
                .then(respGuardar => {
                  console.log(respGuardar);
                  // Arreglo para guardar todos los documentos que se subieron con la solicitud
                  const stackRequisitos: Promise<any>[] = [];
                  // Se recorre el arreglo de los requerimientos para obtener sus datos
                  this.requirements.forEach(reqCargados => {
                    // En caso de que el requerimiento contenga un archivo
                    if (reqCargados.uploadFile) {
                      const ext = reqCargados.uploadFile.type.split('/');
                      // objeto que guardara los datos del requerimiento
                      const cargaArchivosReq = <any>{
                        nombreTemp: reqCargados.uploadFile.response.name,
                        expReqId: reqCargados.requirement.id,
                        solExpId: respGuardar,
                        extension: ext[1]
                      };
                      console.log(cargaArchivosReq);
                      // Se agregan los datos al arreglo para guardar los archivos
                      stackRequisitos.push(this.requisitosService
                        .guardarArchivoExpedienteServ(cargaArchivosReq));
                    }
                  });
                  // Se guardan los archivos del expediente
                  Promise.all(stackRequisitos).then(respReq => {
                    this.toastMsg.success('La solicitud de expediente electrónico se ha guardado con éxito');
                    this.modal.hide();
                    console.log(respReq);
                  }, err => {
                    console.log(err);
                    if (err.body.message) {
                      this.toastMsg.error(err.body.message);
                    } else {
                      this.toastMsg.error('Ocurrio un error al consultar la lista de estados.');
                    }
                  });
                }, err => {
                  console.log(err);
                  if (err.body.message) {
                    this.toastMsg.error(err.body.message);
                  } else {
                    this.toastMsg.error('Ocurrio un error al consultar la lista de estados.');
                  }
                });
            }, err => {
              console.log(err);
              if (err.body.message) {
                this.toastMsg.error(err.body.message);
              } else {
                this.toastMsg.error('Ocurrio un error al consultar la lista de estados.');
              }
            });
        }
      }
    } else {
      this.toastMsg.warning('Favor de llenar todos los campos obligatorios');
      ValidatorHelper.validateAllFormFields(this.detExpedienteForm);
    }
  }

  alreadyIn(id, _array) {
    let req_flag = false;
    for (const n of _array) {
      if (n == id) {
        req_flag = true;
      }
    }
    return req_flag;
  }

  requisitosDetalle() {
    console.log(this.expedienteElect._id);
    this.requisitosService.obtenerListaRequisitosIdSolServ(this.expedienteElect._id).then(respReq => {
      const aux_req = [];
      this.requirements.forEach(req => {
        delete req.caseFileUploaded;
        respReq.forEach(arch => {
          if (arch.exp_requisitos_id === req.requirement.id && !this.alreadyIn(arch.exp_requisitos_id, aux_req)) {
            req.caseFileUploaded = <CaseFileModel>{
              id: arch._id,
              case_file: arch.archivo,
              requirements_id: arch.exp_requisitos_id,
              file_extension: arch.extension,
              estatus: arch.estatus,
              vigencia: arch.vigencia,
              observacion : arch.observacion
            };
            aux_req.push(arch.exp_requisitos_id);
          }
        });
      });
    }, err => {
      console.log(err);
      if (err.body.message) {
        this.toastMsg.error(err.body.message);
      } else {
        this.toastMsg.error('Ocurrio un error al consultar la lista de requerimientos.');
      }
    });
  }


  deshabilitarEdicion() {
    this.main_action = 0;
    this.detExpedienteForm.disable();
    this.editando = false;
    this.editar_title = 'Editar';
    this.limpiarDatos();
    this.hideBtn = false;
    this.disDel = true;
    this.blockLoading = true;
    this.showReq = true;
  }

  habilitarEdicion() {
    this.main_action = 1;
    this.editando = true;
    this.editar_title = 'Editando...';
    console.log('Entro a habilitar el los archivos');
    this.hideBtn = true;
    this.disDel = false;
    this.blockLoading = false;
    this.showReq = false;
  }


  abrirVigencia(event, CrearVigencia) {
    console.log('Abrir modal');
    this.numRequisito = event;
    console.log('NumReq');
    console.log(this.numRequisito);
    this.lodVigenciaExp(event);
    if (event !== undefined && event !== null)
      CrearVigencia.modalVigencia.show();
    return;
  }

  abrirObservacion(event, CrearObservacion) {
    this.numRequisito = event;
    this.requisitosService.obtenerArchivoExpediente(this.numRequisito).then(requisito => {
      this.observacion = requisito['observacion'];
      this.estatus = requisito['estatus'];
      CrearObservacion.modalObservacion.show();
    });
    // this.observacion = this.requirements[]

  }

  dltRequerimiento(requerimiento: RequirementsUploadModel) {
    console.log('Requerimiento a borrar');
    console.log(requerimiento);
    this.requisitosService.eliminarArchivoExpediente(requerimiento.caseFileUploaded.id).then(aVoid => {
      this.requisitosDetalle();
      this.toastMsg.info('Documento eliminado con éxito');
    }, err => {
      if (err.body && err.body.message)
        this.toastMsg.warning(err.body.message);
      else
        this.toastMsg.warning('No se pudo eliminar el archivo, intente más tarde');
    });
  }

  lodVigenciaExp(id) {
    console.log('IDCONSULTA');
    console.log(id);
    this.consultaExpElecService.obtVigencia(id).then(vigencia => {
      this.vigencia.vigencia = vigencia.vigencia;
      console.log('Vigenciiiiiiia');
      console.log(this.vigencia);
    });
  }

  obtenerUsuarios() {
    this.usuarioService.obtenerListaUsuario().then(resp => {
      this.listaCorreo = resp.map(usuario => {
        return {
          label: usuario.correo,
          value: usuario._id
        };
      });
    }, err => {
      console.log(err);
      if (err.body) {
        if (err.body.message) {
          this.toastMsg.error(err.body.message);
        } else {
          this.toastMsg.error('Ocurrio un error al consultar la lista usuarios.');
        }
      } else {
        this.toastMsg.error('Ocurrio un error al consultar la lista usuarios.');
      }
    });
  }


  revisarAprobadosRequirements() {
      return this.requirements
      .filter(r => r.requirement.required == true)
      .filter(r => r.caseFileUploaded)
      .filter(r => r.caseFileUploaded.estatus === 'A');
  }

  revisarRequirements() {
    return this.requirements
      .filter(r => r.requirement.required == true);
  }


  aprobarExpediente() {
    const flag = this.requirements
      .filter(r => r.requirement.required)
      .filter(r => r.caseFileUploaded)
      .filter(r => r.caseFileUploaded.estatus != 'A');
    if (flag.length == 0)
      this.basicConfirmModal.show();
    else
      this.toastMsg.warning('Se deben aprobar todos los documentos requeridos antes de aprobar el expediente.');
  }

  acceptRequest() {
    this.expedienteElect.estatus = 'A';
    this.guardarSolicitud();
    this.basicConfirmModal.hide();
  }

}
