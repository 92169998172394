import {Injectable} from '@angular/core';
import {PaseCajaResource} from './pase_caja.resource';
import {PaseCajaCancelModel} from './pase_caja_cancel.model';
import { PagosModel } from 'src/app/common.tramite-vu.module/entidad.pago.component/service/models/pase-caja-response/pagos.model';

@Injectable()
export class PaseCajaService {

  constructor(private paseCajaResource: PaseCajaResource) {
  }

  obtenerPaseCaja(folio: string): Promise<PagosModel> {
    return this.paseCajaResource.obtenerPaseCaja({folio: folio});
  }

  obtenerPaseCajaCancel(id: string): Promise<PaseCajaCancelModel> {
    return this.paseCajaResource.obtenerPaseCajaCancel({id: id});
  }

  crearPaseCajaCancel(pase_caja: PaseCajaCancelModel): Promise<any> {
    return this.paseCajaResource.crearPaseCajaCancel(pase_caja);
  }

  loadPasesCajaCancelados(filtros: any): Promise<PaseCajaCancelModel[]> {
    return this.paseCajaResource.loadPasesCajaCancelados(filtros);
  }

//   crearUsuario(user: UsuarioModel): Promise<any> {
//     return this.userResource.crearUsuario(user);
//   }

//   actualizarUsuario(user: UsuarioModel): Promise<any> {
//     return this.userResource.actualizarUsuario(user);
//   }

//   obtenerUsuarios(): Promise<UsuarioModel[]> {
//     return this.userResource.obtenerUsuarios(); 
//   }

//   obtenerUsuariosPaginados(filter: any): Promise<UsuarioModel[]> {
//     return this.userResource.obtenerUsuariosPaginacion(filter);
//   }

//   obtenerUsuario(id: string): Promise<UsuarioModel> {
//     return this.userResource.obtenerUsuario({id: id});
//   }

//   obtenerRoles(): Promise<RolModel[]> {
//     return this.authResource.obtenerRoles();
//   }

//   obtenerRol(id: string): Promise<RolModel> {
//     return this.authResource.obtenerRol({id: id});
//   }

//   obtenerAreas(): Promise<AreaModel[]> {
//     return this.userResource.obtenerAreas();
//   }

//   actualizarUsuariosDA(check: string): Promise<any> {
//     return this.userResource.actualizarUsuariosDA({check: check});
//   }

//   obtenerUsuarioSesion(): Promise<UsuarioModel> {
//     return this.userResource.usuarioSesion();
//   }
}