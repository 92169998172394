import {
  IResourceMethod,
  Resource,
  ResourceAction,
  ResourceHandler,
  ResourceParams,
  ResourceRequestMethod
} from '@ngx-resource/core';
import {TramitesModel} from '../model/tramites.model';
import {SolicitudesModel} from '../model/solicitudes.model';
import {Injectable} from '@angular/core';
import {ResourceAuth} from '../../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../../common.module/auth/auth-jwt.service';
import {CfgTramite} from '../model/CfgTramite.model';
import {BPMEstadoProcesoMasterModel} from '../../../common.tramite-vu.module/asignacion-agrupacion.component/service/model/bmpestadoproceso.model';
import {BPMEstadoProcesoMasterUModel} from "../model/bmpestadoproceso.model";
import {BPMEstProcMasterCPModel} from "../model/bpmestadoprocmastercp.model";
import {ReporteDatosAdicionales} from "../model/reporte-datos-adicionales.model";
import {ConfiguracionReporteAdicional} from '../model/configuracion-reporte-adicionales.model'

@Injectable()
@ResourceParams({
  pathPrefix: '/tramites'
  //url: 'http://localhost:8009'
})
export class TramitesResource extends ResourceAuth {
  constructor(restHandler: ResourceHandler, provService: AuthServerProvider) {
    super(restHandler, provService);
  }
  /*export class TramitesResource extends Resource {
    constructor(restHandler: ResourceHandler) {
      super(restHandler);
    }*/

  @ResourceAction({
    isArray: true,
    path: '/tramites',
  })
  obtenerTramites: IResourceMethod<any, TramitesModel[]>;

  @ResourceAction({
    isArray: true,
    path: '/tramites-folios',
  })
  obtenerPretramites: IResourceMethod<any, any>;

  @ResourceAction({
    isArray: true,
    path: '/SolUsuarioDetalle/{:id}',
  })
  obtenerTramitesConUsuarios: IResourceMethod<{id: string}, BPMEstadoProcesoMasterUModel[]>

  @ResourceAction({
    isArray: true,
    path: '/tramites-infoadic/{:id}',
  })
  obtenerTramitesRepDatAdic: IResourceMethod<{id:string}, ReporteDatosAdicionales[]>

  @ResourceAction({
    isArray: true,
    path: '/configuracion-infoadic/{:idTramite}',
  })
  obtenerConfiguracionTramDatAdic: IResourceMethod<{idTramite:string}, ConfiguracionReporteAdicional[]>

  @ResourceAction({
    isArray: true,
    path: '/ss/tramites'
  })
  obtenerTramitesUsuario: IResourceMethod<any, TramitesModel[]>;

  @ResourceAction({
    isArray: true,
    path: '/folio-entrada/{:idfolio}',
  })
  obtenerSolicitud: IResourceMethod<any, SolicitudesModel[]>;

  @ResourceAction({
    isArray: true,
    path: '/bandeja-entrada',
  })
  obtenerSolicitudes: IResourceMethod<any, SolicitudesModel[]>;

  @ResourceAction({
    isArray: true,
    path: '/adicionales/{:id}'
  })
  obtenerAdicionales: IResourceMethod<{id: string}, AdicionalesVm[]>

  @ResourceAction({
    isArray: true,
    path: '/cfg-tramite/{:id}'
  })
  obtenerCfgTramites: IResourceMethod<{id: string}, CfgTramite>

  @ResourceAction({
    isArray: true,
    path: '/tramites/{:id}',
  })
  obtenerTramiteFolio: IResourceMethod<any, TramitesModel[]>;
}
