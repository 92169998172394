import {RouterModule, Routes} from '@angular/router';
import {ConsultaPagoComponent} from '../portal.consulta-pago.module/consulta-pago/consulta-pago.component';
import {NgModule} from '@angular/core';

const exampleRoutes: Routes = [
  {
    path: '',
    component: ConsultaPagoComponent,
    data: {
      pageTitle: 'Consulta de pago'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(exampleRoutes)],
  exports: [RouterModule]
})
export class ConsultaPagoRouting {
}
