/**
 * Created by MTI on 29/05/2018.
 */
import {
  IResourceMethod,
  ResourceAction,
  Resource,
  ResourceHandler,
  ResourceParams,
  ResourceRequestMethod
} from '@ngx-resource/core';
import { Injectable } from '@angular/core';
import { PlantillasModel } from '../service/plantillas.model';
import { ArchivosFirmaModel } from '../service/archivos-firma.model';
import { RegistraFirmaModel } from '../service/registra-firma.model';
import { PlantillasCancelacionModel } from '../service/plantillas-cancelacion.model';
import { BpmEstadoProcesoDetalleModel } from '../../common.tramite.module/entidad.formulario.component/service/bpm-estado-proceso-detalle.model';
import { ArticulosModel } from '../../admin.bpm.module/articulos.component/service/articulos.model';
import { BpmEstadoProcesoMasterModel } from '../../common.tramite.module/entidad.formulario.component/service/bpm-estado-proceso-master.model';
import { AuthServerProvider } from '../auth/auth-jwt.service';
import { ResourceAuth } from '../auth/authenticate.resource';
import { EstadoProcDetDocModel } from '../model/estado-proc-det-doc.model';
import { DatosContribuyenteModel } from '../model/rfc/datosContribuyente.model';
import { RutasTramitesModel } from 'src/app/admin.visor.module/models/rutas-tramites.model';
import { DatosCiudadanoModel } from '../service/datos-ciudadano.model';
import { PlantillaResolModel } from '../model/plantillasresol.model';
import { ResolutivoDocPDFModel } from '../service/resolutivo-doc-pdf.model';
import { PlantillaSeccionesModel } from '../model/plantillas-secciones.model';
import { SeccionEtiquetaModel} from '../model/seccion-etiqueta.model';
import { DatoSeccionEtiquetaModel} from '../model/dato-seccion-etiqueta.model';
import { DatoTramiteEtiquetaModel } from '../model/dato-tramite-etiqueta.model';
import { ResolutivoEtiquetasCapturaModel } from '../service/resolutivo-etiquetas-captura.model';
import { CategoriasModel } from '../components/plantilla-oficio/service/model/categorias.model';
import { CategoriasResolModel } from '../components/plantilla-oficio/service/model/categoriasresol.model';
import { BpmEspecificacionProcesoDetalleModel } from '../../common.tramite-vu.module/entidad.component/service/bpm-especificacion-proceso-detalle.model';
import { UserOrganizacionModel } from '../service/userorganizacion.model';

@Injectable()
@ResourceParams({
  pathPrefix: '/ven-unica'
  // url: 'http://localhost:8006' // Pruebas locales
  // url: 'http://localhost:8082'
})

/*export class VentanillaUnicaResource extends Resource {
  constructor(requestHandler: ResourceHandler) {
    super(requestHandler);
}*/

export class VentanillaUnicaResource extends ResourceAuth {
  constructor(requestHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(requestHandler, authProv);
}

  @ResourceAction({
    path: '/na/lista-todas-plantillas',
    array: true
  })
  getPlantillasRes: IResourceMethod<{}, PlantillasModel[]>;

  @ResourceAction({
    path: '/plantillas/{!tram}',
    array: true
  })
  getPlantillasTram: IResourceMethod<{ tram: string }, PlantillasModel[]>;

  @ResourceAction({
    path: '/plantilla/{!id}',
    array: true
  })
  getPlantillaPorId: IResourceMethod<{ id: string }, PlantillasModel>;

  @ResourceAction({
    path: '/na/obtener-articulo/{!id}',
    array: true
  })
  getArticuloPorId: IResourceMethod<{ id: string }, PlantillasModel>;

  @ResourceAction({
    path: '/bmp-estado-proceso-detalle-doc/{!id}',
    array: true,
    method: ResourceRequestMethod.Get
  })
  getProcesoDetDoc: IResourceMethod<{ id: string }, EstadoProcDetDocModel>;

  @ResourceAction({
    path: '/bpm-estado-proceso-det-cp/actual/{!valor}',
    array: true,
    method: ResourceRequestMethod.Get
  })
  getProcesoDetDocCP: IResourceMethod<{ valor: string }, BpmEspecificacionProcesoDetalleModel>;

  @ResourceAction({
    path: '/plantillas-resolutivos/{!id}',
    array: true,
    method: ResourceRequestMethod.Get
  })
  getPlantillaDetDoc: IResourceMethod<{ id: string }, PlantillaResolModel>;

  @ResourceAction({
    path: '/plantillas-docpdf/{!id}',
    array: true,
    method: ResourceRequestMethod.Get
  })
  getPDFResolutivo: IResourceMethod<{ id: string }, ResolutivoDocPDFModel>;

  @ResourceAction({
    path: '/categorias/{!idTramite}',
    array: true,
    method: ResourceRequestMethod.Get
  })
  getCategoriaByTramite: IResourceMethod<{ idTramite: string }, CategoriasModel>;

  @ResourceAction({
    path: '/plantillas-secciones/{!secciones}',
    array: true,
    method: ResourceRequestMethod.Get
  })
  getSeccionesPlantillas: IResourceMethod<{ secciones: string }, PlantillaSeccionesModel[]>

  @ResourceAction({
    path: '/plantillas-listasecciones',
    array: true,
    method: ResourceRequestMethod.Get
  })
  getListaSeccionesPlantillas: IResourceMethod<{}, PlantillaSeccionesModel[]>

  @ResourceAction({
    path: '/etiqueta-listaseccion',
    array: true,
    method: ResourceRequestMethod.Get
  })
  getListaSeccionEtiqueta: IResourceMethod<{}, SeccionEtiquetaModel[]>

  @ResourceAction({
    path: '/datoetiqueta-listaseccion/{!claveetiq}',
    array: true,
    method: ResourceRequestMethod.Get
  })
  getListaDatoSeccionEtiqueta: IResourceMethod<{claveetiq: string}, DatoSeccionEtiquetaModel[]>

  @ResourceAction({
    path: '/datoetiqueta-listatramite/{!idTramite}',
    array: true,
    method: ResourceRequestMethod.Get
  })
  getListaDatoTramiteEtiqueta: IResourceMethod<{idTramite: string}, DatoTramiteEtiquetaModel[]>

  @ResourceAction({
    path: '/plantillas-listasecciones/{!id}',
    array: true,
    method: ResourceRequestMethod.Get
  })
  getListaSeccionesPlantillasId: IResourceMethod<{ id: string }, PlantillaSeccionesModel[]>

  @ResourceAction({
    path: '/plantillas-resolutivos',
    array: true
  })
  getListaResolTram: IResourceMethod<{}, PlantillaResolModel[]>;

  @ResourceAction({
    path: '/adm-resolutivosplant'
  })
  getListaPlantResolAdm: IResourceMethod<any, PlantillaResolModel[]>;

  @ResourceAction({
    path: '/adm-resolutivosplantsecc'
  })
  getListaPlantResolSeccAdm: IResourceMethod<any, PlantillaSeccionesModel[]>;

  @ResourceAction({
    path: '/adm-resolutivosplantetiq'
  })
  getListaPlantResolEtiqAdm: IResourceMethod<any, ResolutivoEtiquetasCapturaModel[]>;

  @ResourceAction({
    path: '/plantillas-resolutivostram/{!id}',
    array: true
  })
  getListaResolTramServByIdTramite: IResourceMethod<{}, PlantillaResolModel[]>;

  @ResourceAction({
    path: '/plantillas-etiqcapt',
    array: true
  })
  getEtiquetasCaptura: IResourceMethod<{}, ResolutivoEtiquetasCapturaModel[]>;

  @ResourceAction({
    path: '/plantillas-etiqcapt/{!id}',
    array: true
  })
  getEtiquetasCapturaId: IResourceMethod<{}, ResolutivoEtiquetasCapturaModel[]>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    isArray: false,
    path: '/bpm-estado-proceso-det-cp/siguiente/{!bpm_estado_proceso_master_id}'
  })
  getSiguienteEspecificacionProcesoDetCp: IResourceMethod<{ bpm_estado_proceso_master_id: string }, BpmEspecificacionProcesoDetalleModel>;

  @ResourceAction({
    path: '/plant-resolutivospdf',
    array: true,
    method: ResourceRequestMethod.Post
  })
  postJasperResolPDF: IResourceMethod< PlantillaSeccionesModel[] , ResolutivoDocPDFModel>;

  @ResourceAction({
    path: '/plant-genqrresol',
    array: true,
    method: ResourceRequestMethod.Post
  })
  postAprobarJasperResolPDF: IResourceMethod< PlantillaSeccionesModel[] , ResolutivoDocPDFModel>;

  @ResourceAction({
    path: '/plant-resoldel/{!id}',
    method: ResourceRequestMethod.Delete

  })
  deletePDFTramite: IResourceMethod<{id: string}, any>;

  @ResourceAction({
    path: '/plant-resoladmindel/{!id}',
    method: ResourceRequestMethod.Delete

  })
  deleteResolutivo: IResourceMethod<{id: string}, any>;

  @ResourceAction({
    path: '/plant-resoletiqadmindel/{!id}',
    method: ResourceRequestMethod.Delete

  })
  deleteEtiquetaResol: IResourceMethod<{id: string}, any>;

  @ResourceAction({
    path: '/plant-resolseccadmindel/{!id}',
    method: ResourceRequestMethod.Delete

  })
  deleteSeccionResol: IResourceMethod<{id: string}, any>;

  @ResourceAction({
    path: '/firma-electronica',
    array: true,
    method: ResourceRequestMethod.Post
  })
  postFirmarArchivo: IResourceMethod<{ archivosFirmaModel: ArchivosFirmaModel }, any>;

  @ResourceAction({
    path: '/genera-base-64',
    array: true,
    method: ResourceRequestMethod.Get
  })
  generaBase64: IResourceMethod<{ nombreArchivo: string }, string>;

  @ResourceAction({
    path: '/resol_genqr/{!id}',
    array: true,
    method: ResourceRequestMethod.Get
  })
  getResolutivoAprobQR: IResourceMethod<{ id: string }, string>;

  @ResourceAction({
    path: '/actualizar-docto-resolutivo',
    method: ResourceRequestMethod.Patch
  })
  patchDoctoResolutivo: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/guardar-firma-elect',
    method: ResourceRequestMethod.Post
  })
  postGuardarFirmarArchivo: IResourceMethod<{ registraFirmaModel: RegistraFirmaModel }, any>;

  @ResourceAction({
    path: '/na/lista-plantillas-cancelacion',
    method: ResourceRequestMethod.Get
  })
  getCargaListaPlantillasCancel: IResourceMethod<{}, PlantillasCancelacionModel[]>;

  @ResourceAction({
    path: '/archivo-entidad',
    method: ResourceRequestMethod.Post
  })
  postBpmEstadoDetalleDoc: IResourceMethod<RegistraFirmaModel, any>;

  @ResourceAction({
    path: '/bmp-estado-proceso-detalle',
    method: ResourceRequestMethod.Get
  })
  getEstadoProcesoDetalleUltimo: IResourceMethod<{ id_estado_master: string, siguiente: string }, BpmEstadoProcesoDetalleModel>;

  @ResourceAction({
    path: '/bmp-estado-proceso-detalle_acc',
    method: ResourceRequestMethod.Get
  })
  getEstadoProcesoDetalleAccion: IResourceMethod<{ id_estado_master: string }, BpmEstadoProcesoDetalleModel>;

  @ResourceAction({
    path: '/bmp-estado-proceso-detalle/{!_id}',
    method: ResourceRequestMethod.Patch
  })
  patchBmpEstadoProcesoDetalle: IResourceMethod<BpmEstadoProcesoDetalleModel, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    isArray: true,
    path: '/bmp-estado-proceso-master-asignacion/{!claveAsignacion}'
  })
  getBmpEstadoProcesoMasterPorAsig: IResourceMethod<{ claveAsignacion: string }, BpmEstadoProcesoMasterModel[]>;

  @ResourceAction({
    path: '/na/lista-articulos',
    method: ResourceRequestMethod.Get
  })
  getListaArticulos: IResourceMethod<any, ArticulosModel[]>;

  @ResourceAction({
    path: '/articulos/{!tram}',
    array: true
  })
  getArticulosTram: IResourceMethod<{ tram: string }, ArticulosModel[]>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    isArray: true,
    path: '/bmp-estado-proceso-detalle'
  })
  postBmpEstadoProcesoDetalle: IResourceMethod<BpmEstadoProcesoDetalleModel, any>;

  // Métodos para traer los datos por medio del RFC
  @ResourceAction({
    path: '/solicitudes-tramites-rfc/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtContribuyentePorRFC: IResourceMethod<{ id: string }, DatosContribuyenteModel>;

  @ResourceAction({
    path: '/solicitudes-tramites-estado/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtEstadoPorID: IResourceMethod<{ id: string }, any>;

  @ResourceAction({
    path: '/solicitudes-tramites-municipio/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtMunicipioPorID: IResourceMethod<{ id: string }, any>;

  @ResourceAction({
    path: '/solicitudes-tramites-colonia/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtColoniaPorID: IResourceMethod<{ id: string }, any>;

  @ResourceAction({
    path: '/solicitudes-tramites-calle/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtCallePorID: IResourceMethod<{ id: string }, any>;

  // Rutas trámites
  @ResourceAction({
    path: '/rutas-tramites'
  })
  obtieneRutasTramitesListaCallejoneada: IResourceMethod<RutasTramitesModel[], any>;

  @ResourceAction({
    path: '/usuarioorg/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtenerUsuarioOrganizacion: IResourceMethod<{ id: any }, UserOrganizacionModel>;

  // Métodos para traer los datos por medio del RFC sin motores (Local)
  @ResourceAction({
    path: '/contribuyente-rfc/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtContribuyenteRFCLocal: IResourceMethod<{id:string}, DatosContribuyenteModel>;

  @ResourceAction({
    path: '/contribuyente-estado/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtEstadoPorIDLocal: IResourceMethod<{id:string}, any>;

  @ResourceAction({
    path: '/contribuyente-municipio/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtMunicipioPorIDLocal: IResourceMethod<{id:string}, any>;

  @ResourceAction({
    path: '/contribuyente-rfc',
    array: true,
    method: ResourceRequestMethod.Post
  })
  postGuardarContribuyente: IResourceMethod<DatosCiudadanoModel, any>;

  // Rutas trámites
  @ResourceAction
  ({
    path: '/locacion-contribuyente'
  })
  obtenerLocacion: IResourceMethod<{ municipio: string, estado: string }, any>;

  @ResourceAction({
    path: '/pais-contribuyente/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtPais: IResourceMethod<{id:string}, any>;

  @ResourceAction
  ({
    path: '/estado-contribuyente'
  })
  obtenerEstado: IResourceMethod<{ pais: string, state: string }, any>;

  @ResourceAction
  ({
    path: '/municipio-contribuyente'
  })
  obtenerMunicipio: IResourceMethod<{ catalogo: string, municipio: string }, any>;

  @ResourceAction({
    path: '/obtener-estados',
    method: ResourceRequestMethod.Get
  })
  obtenerEstados: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/na/obtener-estados',
    method: ResourceRequestMethod.Get
  })
  obtenerEstadosNa: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/obtener-municipios',
    method: ResourceRequestMethod.Get
  })
  obtenerMunicipios: IResourceMethod<any, any>;
  
  @ResourceAction({
    path: '/na/obtener-municipios',
    method: ResourceRequestMethod.Get
  })
  obtenerMunicipiosNa: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/obtener-ciudades',
    method: ResourceRequestMethod.Get
  })
  obtenerCiudades: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/na/obtener-ciudades',
    method: ResourceRequestMethod.Get
  })
  obtenerCiudadesNa: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/obtener-contribuyente',
    method: ResourceRequestMethod.Get
  })
  obtenerContribuyente: IResourceMethod<DatosContribuyenteModel, any>;

  @ResourceAction({
    path: '/na/enviar-codigo-acceso',
    method: ResourceRequestMethod.Post
  })
  enviarCodigoAccesoContribuyente: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/na/enviar-codigo-acceso',
    method: ResourceRequestMethod.Patch
  })
  validarCodigoAccesoContribuyente: IResourceMethod<any, any>;

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    array: true,
    path: '/bmp-estado-proceso-master-resol/{!id}',
  })
  getBpmEstadoProcesoMasterPorId: IResourceMethod<{ id: string }, BpmEstadoProcesoMasterModel>;

  @ResourceAction({
    path: '/resolutivo-plantilla',
    method: ResourceRequestMethod.Post
  })
  crtPlantResolutivo: IResourceMethod<PlantillaResolModel, any>;

  @ResourceAction({
    path: '/resolutivo-plantilla/{!_id}',
    method: ResourceRequestMethod.Patch
  })
  updPlantResolutivo: IResourceMethod<PlantillaResolModel, any>;

  @ResourceAction({
    path: '/resolutivo-etiquetas',
    method: ResourceRequestMethod.Post
  })
  crtEtiqResolutivo: IResourceMethod<ResolutivoEtiquetasCapturaModel, any>;

  @ResourceAction({
    path: '/resolutivo-etiquetas/{!_id}',
    method: ResourceRequestMethod.Patch
  })
  updEtiqResolutivo: IResourceMethod<ResolutivoEtiquetasCapturaModel, any>;

  @ResourceAction({
    path: '/resolutivo-secciones',
    method: ResourceRequestMethod.Post
  })
  crtSeccResolutivo: IResourceMethod<ResolutivoEtiquetasCapturaModel, any>;

  @ResourceAction({
    path: '/resolutivo-secciones/{!_id}',
    method: ResourceRequestMethod.Patch
  })
  updSeccResolutivo: IResourceMethod<ResolutivoEtiquetasCapturaModel, any>;

  @ResourceAction({
    path: '/cancelatramite-bpm_estado_master/{!valor_cancela}',
    method: ResourceRequestMethod.Patch
  })
  updCancelaTramite: IResourceMethod<{ valor_cancela: string }, any>;

  @ResourceAction({
    path: '/resolutivo-plantilla-html',
    method: ResourceRequestMethod.Get
  })
  getListaResolutivosPlantHTML: IResourceMethod<any, PlantillaResolModel[]>;

  @ResourceAction({
    path: '/resolutivo-plantilla-html',
    method: ResourceRequestMethod.Post
  })
  insertPlantillaHtml: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/resolutivo-plantilla-html/{!_id}',
    method: ResourceRequestMethod.Patch
  })
  updatePlantillaHtml: IResourceMethod<PlantillaResolModel, any>;

  @ResourceAction({
    path: '/resolutivo-plantilla-html/remove',
    method: ResourceRequestMethod.Delete

  })
  deletePlantillaHtml: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/plant-resolutivospdf-html',
    method: ResourceRequestMethod.Post
  })
  insertPdfPlantillaHtml: IResourceMethod<any, any>;
}
