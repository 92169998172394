/**
 * Created by Raul.
 */
import {Injectable} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable()
export class AuthService {
  private helper: JwtHelperService;

  constructor(private $localStorage: LocalStorageService,
              private $sessionStorage: SessionStorageService) {
    this.helper = new JwtHelperService();
  }

  loggedIn() {
    let token: string;
    token = this.$localStorage.retrieve('id_token');
    if (!token)
      token = this.$sessionStorage.retrieve('id_token');
    return !this.helper.isTokenExpired(token);
  }
}
