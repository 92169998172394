import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {RfcGetModel} from '../model/rfc/rfc.get.model';
import {ResourceAuth} from '../auth/authenticate.resource';
import {AuthServerProvider} from '../auth/auth-jwt.service';
import {ContribuyenteModel} from '../model/rfc/contribuyente.model';
import {BillInformation} from '../model/rfc/bill.model';
import {CaseFilesModel} from '../model/case-file-model';

/**
 * Created by MTI on 28/05/2018.
 */

@Injectable()
@ResourceParams({
  // url: 'http://localhost:8020'
   pathPrefix: '/coremicro'
})

export class RfcResource extends ResourceAuth {
  constructor(resourceHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(resourceHandler, authProv);
  }

 // export class RfcResource extends Resource {
 //   constructor(requestHandler: ResourceHandler) {
 //     super(requestHandler);
 //   }
  @ResourceAction({
    path: '/formality/{!rfc}',
    method: ResourceRequestMethod.Get
  })
  verifyRFC: IResourceMethod<{ rfc: any }, RfcGetModel>;

  @ResourceAction({
    path: '/post-rfc',
    method: ResourceRequestMethod.Post
  })
  createRFC: IResourceMethod<ContribuyenteModel, RfcGetModel>;

  @ResourceAction({
    path: '/taxpayer',
    method: ResourceRequestMethod.Post
  })
  createTaxpayer: IResourceMethod<BillInformation, any>;

  @ResourceAction({
    path: '/taxpayer/{!id}',
    method: ResourceRequestMethod.Get
  })
  getTaxpayer: IResourceMethod<{id: number}, BillInformation>;

  @ResourceAction({
    path: '/upTaxpayer',
    method: ResourceRequestMethod.Patch
  })
  upTax: IResourceMethod<any, any>;

  @ResourceAction({
    path: '/recipes?id=formalities_id&req=376',
    method: ResourceRequestMethod.Get
  })
  getReceiptList: IResourceMethod<{id: number}, CaseFilesModel[]>;

  @ResourceAction({
    path: '/na/prior-formality/{!rfc}',
    method: ResourceRequestMethod.Get
  })
  priorRFC: IResourceMethod<{ rfc: any }, any>;

  @ResourceAction({
    path: '/na/taxpayer-rfc/{!rfc}',
    method: ResourceRequestMethod.Get
  })
  taxpayerRFC: IResourceMethod<{ rfc: any }, RfcGetModel>;

  @ResourceAction({
    path: '/na/update-taxpayer',
    method: ResourceRequestMethod.Patch
  })
  updateTaxpayer: IResourceMethod<any, any>;
}
