import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {Injectable} from '@angular/core';
import {LoginModel} from '../model/login.model';
import {environment} from '../../../environments/environment';
import {JwtToken} from '../model/jwt-token.model';
import { ResourceAuth } from 'src/app/common.module/auth/authenticate.resource';
import { AuthServerProvider } from 'src/app/common.module/auth/auth-jwt.service';
/**
 * Created by MTI on 09/05/2018.
 */

@Injectable()
@ResourceParams({
  pathPrefix: '/consumos'
  //url: environment.apiBaseUrl
 //url: 'http://localhost:8202/'
})
/*
export class AuthResourceConsumos extends Resource {
  constructor(resourceHandler: ResourceHandler) {
    super(resourceHandler);
  }*/

  export class AuthResourceConsumos extends ResourceAuth {
  constructor(resourceHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(resourceHandler, authProv);
  }


  @ResourceAction({
    // IResourceAction
    method: ResourceRequestMethod.Post,
    path: '/auth_consumos'
  })
  login: IResourceMethod<LoginModel, JwtToken>; // will make an post poolRequest to /auth/login

 
}
