<div mdbModal #confirmModal="mdb-modal" class="modal fade bottom" id="centralModalSm" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-frame modal-bottom modal-notify modal-success" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row d-flex justify-content-center align-items-center">
          <p class="p-0 my-3 text-center">{{mainText}}</p>
          <button type="button" class="btn btn-success waves-light" mdbRippleRadius
                  (click)="accept()">{{okText}} <i class="fa fa-check"></i></button>
          <button type="button" class="btn btn-default waves-effect" data-dismiss="modal" mdbRippleRadius
                  (click)="cancel()">{{cancelText}} <i class="fa fa-times"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>
