import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {ToastService} from 'ng-uikit-pro-standard';
import {AuthServerProvider} from './auth-jwt.service';

@Injectable()
export class AuthGuardChildren implements CanActivateChild {

  constructor(private auth: AuthService,
              private router: Router,
              private toastMsg: ToastService,
              private authServ: AuthServerProvider) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('AuthGuardChildren');
    return this
      .authServ
      .validacionRuta(this.stripQueryParams(state.url)).do(res => {
        if (res == false && state.url == '/app')
          this.router.navigate(['portal']);
        if (res == false)
          this.toastMsg.warning('Usted no cuenta con los permisos para entrar a esta funcionalidad.');
      });
  }

  stripQueryParams(url: string): string {
    const urlSplit = url.split('?');
    if (urlSplit.length == 2) {
      return urlSplit[0];
    } else {
      return url;
    }
  }
}
