import { Injectable } from '@angular/core';
import { IResourceAction, Resource, ResourceHandler } from '@ngx-resource/core';
import { tokenMc } from 'src/app/token';//'src/app/token-test-migracion';
import { AuthServerProvider } from './auth-jwt.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthCorregidoraResource extends Resource {

//   private apiBaseUrl: string = 'http://187.216.144.10:9938' + '/api';
  // private apiBaseUrl: string = ''; 
  private apiBaseUrl: string = environment.apiBaseUrlMc + '/api';

  constructor(resourceHandler: ResourceHandler, private authProv: AuthServerProvider) {
    super(resourceHandler);
  }

  $getHeaders(actionOptions?: IResourceAction): any | Promise<any> {
    let headers: any = super.$getHeaders();
    if (!actionOptions.auth) {
      // headers = this.authProv.extendHeaders(headers);
      const token = tokenMc();
      
      headers.Authorization = 'Bearer ' + token;
      console.log(headers);
    }
    return headers;
  }

  $getUrl(methodOptions?: IResourceAction): string | Promise<string> {
    const resPath = super.$getUrl();
    return this.apiBaseUrl + resPath;
  }
}