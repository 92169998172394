/**
 * Created by lponce on 025/07/2018.
 */
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {Injectable} from '@angular/core';
import {SanctionsModel} from './model/sanction/sanctions.model';
import {EvaluationsModel} from './model/evaluation/evaluations.model';
import {EvaluationDetailModel} from './model/evaluation/evaluation-detail.model';
import {CompetitionsModel} from './model/competition/competitions.model';
import {TendersPublicModel} from './model/tender/tenders-public.model';
import {TendersProviderModel} from './model/tender/tenders-provider.model';
import {PassCashCreateModel} from './model/pass-cash-create.model';
import {PassCashResponseModel} from './model/pass-cash-response.model';
import {ReceiptPaymentsModel} from './model/receipt/receipt-payments.model';
import {XmlModel} from './model/receipt/xml.model';
import {DownloadReceiptModel} from './model/receipt/download-receipt.model';
import {PublicFileModel} from './model/public-file.model';
import {FiscalYearModel} from './model/fiscal-years.model';
import {TenderGivensModel} from './model/tender/tender-givens.model';
import {PrintReceiptCashPassModel} from './model/receipt/print-receipt-cash-pass.model';
import {ValidRfcModel} from './model/valid-rfc.model';
import {ResourceAuth} from '../../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../../common.module/auth/auth-jwt.service';

@Injectable()
@ResourceParams({
  pathPrefix: '/providers'
  // url: 'http://localhost:8074/'
})


/*export class ProvidersResource extends Resource {

  constructor(restHandler: ResourceHandler) {
    super(restHandler);
  }*/



export class ProvidersResource extends ResourceAuth {
  constructor(restHandler: ResourceHandler, provService: AuthServerProvider) {
    super(restHandler, provService);
  }


  /**************Sanciones**************/
  @ResourceAction({
    array: true,
    path: '/sanction/'
  })
  getSanctions: IResourceMethod<{ pAnioSancion: any }, SanctionsModel>;
  /**************Sanciones**************/


  /**************Evaluaciones**************/
  @ResourceAction({
    array: true,
    path: '/evaluation/'
  })
  getEvaluations: IResourceMethod<any, EvaluationsModel>;

  @ResourceAction({
    array: true,
    path: '/detail-evaluation/{!id}'
  })
  getDetailEvaluation: IResourceMethod<{ id: any }, EvaluationDetailModel>;
  /**************Evaluaciones**************/


  /**************Concursos**************/
  @ResourceAction({
    array: true,
    path: '/competition/'
  })
  getCompetitions: IResourceMethod<{ pAnioCompetition: any }, CompetitionsModel>;

  @ResourceAction({
    array: true,
    path: '/detail-competition/{!id}'
  })
  getDetailCompetition: IResourceMethod<{ id: any, pAnioCompetition: any }, CompetitionsModel>;

  @ResourceAction({
    array: true,
    path: '/competition-print'
  })
  printCompetition: IResourceMethod<{ id: any }, CompetitionsModel>;
  /**************Concursos**************/


  /**************Licitaciones**************/
  @ResourceAction({
    array: true,
    path: '/tender-public'
  })
  getPublicTenders: IResourceMethod<{ pAnioTender: any }, TendersPublicModel>;

  @ResourceAction({
    array: true,
    path: '/na/tender-public'
  })
  getPublicTendersAuth: IResourceMethod<{ pAnioTender: any }, TendersPublicModel>;

  @ResourceAction({
    array: true,
    path: '/detail-tender-public/{!id}'
  })
  detailPublicTenders: IResourceMethod<{ pAnioTender: any, id: any }, TendersPublicModel>;

  @ResourceAction({
    array: true,
    path: '/detail-tender-provider/{!id}'
  })
  detailProviderTenders: IResourceMethod<{ pAnioTender: any, id: any }, TendersPublicModel>;

  @ResourceAction({
    array: true,
    path: '/na/tender-public/'
  })
  getPublicTendersNoLogin: IResourceMethod<{ pAnioTender: any }, TendersPublicModel>;

  @ResourceAction({
    array: true,
    path: '/na/detail-tender-public/{!id}'
  })
  detailPublicTendersNoLogin: IResourceMethod<{ pAnioTender: any, id: any }, TendersPublicModel>;

  @ResourceAction({
    array: true,
    path: '/tender-provider/'
  })
  getProviderTenders: IResourceMethod<{ pAnioTender: any }, TendersProviderModel>;

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    path: '/tender-public-cash-pass'
  })
  saveCassPass: IResourceMethod<PassCashCreateModel, PassCashResponseModel>;

  @ResourceAction({
    array: true,
    path: '/tender-public-cash-pass-print'
  })
  printCashPass: IResourceMethod<{ id: any }, CompetitionsModel>;

  @ResourceAction({
    array: true,
    path: '/tender-public-download-givens'
  })
  downloadGivens: IResourceMethod<{ clave: any }, TenderGivensModel>;
  /**************Licitaciones**************/


  /**************Recibos**************/
  @ResourceAction({
    array: true,
    path: '/receipt-payment'
  })
  getReceiptPayments: IResourceMethod<{ pAnio: any }, ReceiptPaymentsModel>;

  @ResourceAction({
    array: true,
    path: '/detail-receipt-payment/{!id}'
  })
  detailReceiptPayment: IResourceMethod<{ pAnio: any, id: any }, ReceiptPaymentsModel>;

  @ResourceAction({
    array: true,
    path: '/download-xml'
  })
  downloadXml: IResourceMethod<{ pCve: any }, XmlModel>;

  @ResourceAction({
    array: true,
    path: '/print-receipt'
  })
  printReceipt: IResourceMethod<{ pCve: any }, DownloadReceiptModel>;

  @ResourceAction({
    array: true,
    path: '/cash-pass-receipt-print'
  })
  printCashPassReceipt: IResourceMethod<{ id: any }, PrintReceiptCashPassModel>;
  /**************Recibos**************/


  /**************Generico**************/
  @ResourceAction({
    array: true,
    path: '/na/fiscal-years'
  })
  getFiscalYears: IResourceMethod<any, FiscalYearModel>;

  @ResourceAction({
    array: true,
    path: '/na/public-file/{!id}'
  })
  getPublicFile: IResourceMethod<{ id: any }, PublicFileModel>;

  @ResourceAction({
    array: true,
    path: '/valid-rfc'
  })
  getValidRfc: IResourceMethod<{}, ValidRfcModel>;
  /**************Generico**************/
}
