/**
 * Created by MTI on 28/05/2018.
 */
import {Injectable} from '@angular/core';
import {RfcResource} from '../resource/rfc.resource';
import {BillInformation} from '../model/rfc/bill.model';
import {CaseFilesModel} from '../model/case-file-model';
import {RequirementsResource} from '../resource/requirements.resource';
@Injectable()
export class RfcService {
  constructor(private rResource: RfcResource, private reqResource: RequirementsResource) {}
  verifyRFC(rfc): Promise <any>  {
    return this.rResource.verifyRFC({rfc: rfc});
  }
  verifyRfc(rfc): Promise <any>  {
    return this.rResource.verifyRFC({rfc: rfc});
  }
  creatRfc(entity): Promise <any>  {
    return this.rResource.createRFC(entity);
  }
  priorRfc(rfc: string) {
    return this.rResource.priorRFC({rfc});
  }
  taxpayerRfc(rfc: string): Promise <any> {
    return this.rResource.taxpayerRFC({rfc});
  }
  updateTaxpayer(entity: any) {
    return this.rResource.updateTaxpayer(entity);
  }
  createTaxpayer(entity): Promise <any>  {
    return this.rResource.createTaxpayer(entity);
  }
  updateTax(entity): Promise <any>  {
    return this.rResource.upTax(entity);
  }
  getTaxpayer(id: number): Promise <BillInformation> {
    return this.rResource.getTaxpayer({id: id});
  }

  getReceiptList(formalities_id: number): Promise<CaseFilesModel[]> {
    return this.rResource.getReceiptList({id: formalities_id});
  }

  delRequirement(id: number): Promise<any> {
    return this.reqResource.delCasefile({id: id});
  }
}
