import {Injectable} from '@angular/core';
import {IResourceMethod, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';
import {environment} from '../../../environments/environment';

@Injectable()
@ResourceParams({
  url: environment.apiBaseUrl
  // url: 'http://localhost:9990'
})
// export class AuthExtendedResource extends Resource {
export class AuthExtendedResource extends ResourceAuth {
  constructor(handler: ResourceHandler, auth: AuthServerProvider) {
    super(handler, auth);
  }

  // constructor(handler: ResourceHandler) {
  //   super(handler);
  // }

  @ResourceAction({
    // IResourceAction
    method: ResourceRequestMethod.Post,
    path: '/close_session'
  })
  logout: IResourceMethod<any, any>;
}
