/**
 * Created by Raul.
 */
import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../../environments/environment';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {NgProgress} from 'ngx-progressbar';
import {JwtHelperService} from '@auth0/angular-jwt';
import {UserAuth} from './user-auth.model';
import {ToastService} from 'ng-uikit-pro-standard';
import {DataSessionShareService as DataShareServiceUser} from '../util/data-share-session.service';
import {Router} from '@angular/router';

@Injectable()
export class AuthServerProvider {
  private apiAuthUrl: string = environment.apiBaseUrl + '/api/auth';
  private apiValidarRuta: string = environment.apiBaseUrl + '/api/administracion/validate';
  private apiValidarInbox: string = environment.apiBaseUrl + '/api/administracion/validar-bandeja';
  private apiBaseRefresh: string = environment.apiBaseUrl + '/refresh-token';
  private apiBaseLogout: string = environment.apiBaseUrl + '/api/logout';
  private helper: JwtHelperService;
  public ngProgress: NgProgress;
  public user: Observable<UserAuth>;
  public userAux: UserAuth;


  constructor(private http: Http,
              private http1: HttpClient,
              private $localStorage: LocalStorageService,
              private $sessionStorage: SessionStorageService,
              private ngPro: NgProgress,
              protected dataShare: DataShareServiceUser,
              private toastMsg: ToastService,
              private router: Router) {
    this.ngProgress = ngPro;
    this.helper = new JwtHelperService();
  }

  extendHeaders(headers: any) {
    if (headers) {
      const token = this.getToken();
      headers.Authorization = 'Bearer ' + token;
    }
    return headers;
  }

  /**
   * Obtener el token actualmente almacenado en la aplicacion.
   * @returns {string} Token almacenado
   */
  getToken() {
    return this.$localStorage.retrieve('id_token') || this.$sessionStorage.retrieve('id_token');
  }

  /**
   * Solicitud de token para trabajar dentro de la aplicación
   *
   * @param credentials Objeto con las credenciales de acceso.
   * @returns {Observable<R>} respon de la solicitud.
   */
  login(credentials: any): Observable<any> {
    const data = {
      username: credentials.username,
      password: credentials.password,
      rememberMe: credentials.rememberMe
    };
    //noinspection TypeScriptValidateTypes
    return this.http.post(this.apiAuthUrl, data).map(authenticateSuccess.bind(this));

    function authenticateSuccess(resp) {
      const res = resp.json();
      const jwt = (res) ? res.id_token : null;
      if (jwt) {
        this.storeAuthenticationToken(jwt, credentials.rememberMe);
        return jwt;
      }
    }
  }

  /**
   * Funcion para la validacion del acceso a rutas de la
   * aplicacion, del lado del servidor
   *
   * @param ruta Ruta a la que se le realizara la validación
   * @returns {Observable<R>} respuesta de la vlidación
   */
  validacionRuta(ruta: string): Observable<any> {
    const data = {
      ruta: ruta
    };
    //noinspection TypeScriptValidateTypes
    return this
      .http
      .post(this.apiValidarRuta, data, {headers: this.extendHeaders({})})
      .map(mappingResultValidacion.bind(this))
      ._catch(err => Observable.of(false));

    function mappingResultValidacion(resp) {
      return resp && resp.status && resp.status === 200;
    }
  }

  validacionInbox(ruta: string): Observable<any> {
    const data = {
      ruta: ruta
    };
    //noinspection TypeScriptValidateTypes
    return this
      .http
      .post(this.apiValidarInbox, data, {headers: this.extendHeaders({})})
      .map(mappingResultValidacion.bind(this))
      ._catch(err => Observable.of(false));

    function mappingResultValidacion(resp) {
      return resp && resp.status && resp.status === 200;
    }
  }

  /**
   * Almacena el token en el apartado correspondiente.
   *
   * @param jwt Token a almacenar
   * @param rememberMe Tipo de almacenamiento (en tiempo).
   */
  storeAuthenticationToken(jwt: string, rememberMe: boolean) {
    this.$sessionStorage.store('id_token', jwt);
  }

  /**
   * Elimina los datos del usuario autentificado.
   */
  logout(): void {
    this.$localStorage.clear('id_token');
    this.$sessionStorage.clear('id_token');
  }

  /**
   * Refresca el token que actualmente se encuentra en la aplicacion
   *
   * @param bearerToken
   */
  refreshToken(bearerToken: string) {
    if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
      const jwt = bearerToken.slice(7, bearerToken.length);
      if (jwt) {
        const localJwt = this.$localStorage.retrieve('id_token');
        if (localJwt)
          this.$localStorage.store('id_token', jwt);
        else
          this.$sessionStorage.store('id_token', jwt);
      }
    }
  }

  getUserDecoded(): UserAuth {
    return this.helper.decodeToken(this.getToken());
  }

  refreshToken2() {
    // return this.http.post<any>(`${environment.apiUrl}/users/refresh-token`, {}, { withCredentials: true })
    const jwtToken = JSON.parse(atob(this.getToken().split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now();
    console.log(this.user);
    if (timeout <= 60000 && timeout >= 1000) {
      const data = {
        email: this.userAux.correo,
        password: this.userAux.password,
        rememberMe: this.userAux.rememberMe
      };

      this.http1.post(this.apiBaseRefresh, data).subscribe(res => {
        this.$localStorage.store('id_token', res['token']);
        return res['token'];
      });
    } else if (timeout < 0) {
      const path = this.router.url;
      if (path.indexOf('login') != -1 || path.indexOf('app') != -1) {
        this.toastMsg.info('Hemos cerrado la sesión.');
        setTimeout(() => {
          this.logout();
          this.router.navigate(['/login']);
          window.location.reload();
        }, 600);
      } else if (path.indexOf('portal') != -1) {
        // HeaderComponent.prototype.logout();
        this.toastMsg.info('Hemos cerrado la sesión.');
        setTimeout(() => {
          this.dataShare.confirmObject({discriminator: 'closeSession'});
          this.router.navigate(['/portal']);
          window.location.reload();
        }, 600);

      }
      return null;
    }
  }

}
