/**
 * Created by MTI on 09/10/2018.
 */
import { Injectable } from '@angular/core';
import { PlantillasModel } from './plantillas.model';
import { VentanillaUnicaResource } from '../resource/ventanilla-unica.resource';
import { ArchivosFirmaModel } from './archivos-firma.model';
import { RegistraFirmaModel } from './registra-firma.model';
import { PlantillasCancelacionModel } from './plantillas-cancelacion.model';
import { BpmEstadoProcesoDetalleModel } from '../../common.tramite.module/entidad.formulario.component/service/bpm-estado-proceso-detalle.model';
import { ArticulosModel } from '../../admin.bpm.module/articulos.component/service/articulos.model';
import { BpmEstadoProcesoMasterModel } from '../../common.tramite.module/entidad.formulario.component/service/bpm-estado-proceso-master.model';
import { EstadoProcDetDocModel} from '../model/estado-proc-det-doc.model';
import { DatosContribuyenteModel } from '../model/rfc/datosContribuyente.model';
import { RutasTramitesModel } from 'src/app/admin.visor.module/models/rutas-tramites.model';
import { DatosCiudadanoModel } from '../service/datos-ciudadano.model';
import { PlantillaResolModel } from '../model/plantillasresol.model';
import { ResolutivoDocPDFModel } from '../service/resolutivo-doc-pdf.model';
import { ResolutivoEtiquetasCapturaModel } from '../service/resolutivo-etiquetas-captura.model';
import { PlantillaSeccionesModel } from '../model/plantillas-secciones.model';
import { SeccionEtiquetaModel} from '../model/seccion-etiqueta.model';
import { DatoSeccionEtiquetaModel} from '../model/dato-seccion-etiqueta.model';
import { DatoTramiteEtiquetaModel } from '../model/dato-tramite-etiqueta.model';
import { CategoriasModel } from '../components/plantilla-oficio/service/model/categorias.model';
import { CategoriasResolModel } from '../components/plantilla-oficio/service/model/categoriasresol.model';
import { BpmEspecificacionProcesoDetalleModel } from '../../common.tramite-vu.module/entidad.component/service/bpm-especificacion-proceso-detalle.model';
import { UserOrganizacionModel } from './userorganizacion.model';

@Injectable()
export class VentanillaUnicaService {
  constructor(private ventanillaUnicaResource: VentanillaUnicaResource) { }

  getPantillasServ(): Promise<PlantillasModel[]> {
    return this.ventanillaUnicaResource.getPlantillasRes();
  }

  getPlantillasTramServ(tram: string): Promise<PlantillasModel[]> {
    console.log("Entro aqui - " + tram);
    return this.ventanillaUnicaResource.getPlantillasTram({ tram: tram });
  }

  getPantillaPorIdServ(id: string): Promise<PlantillasModel> {
    return this.ventanillaUnicaResource.getPlantillaPorId({ id: id });
  }

  getArticuloPorIdServ(id: string): Promise<PlantillasModel> {
    return this.ventanillaUnicaResource.getArticuloPorId({ id: id });
  }

  getBPMProcesoDetDoc(id: string): Promise<EstadoProcDetDocModel> {
    return this.ventanillaUnicaResource.getProcesoDetDoc({ id: id });
  }

  getBPMProcesoDetDocCP(valor: string): Promise<BpmEspecificacionProcesoDetalleModel> {
    return this.ventanillaUnicaResource.getProcesoDetDocCP({ valor: valor });
  }

  getListaResolTramServ(): Promise<PlantillaResolModel[]> {
    return this.ventanillaUnicaResource.getListaResolTram();
  }

  public getListaPlantResolAdm(filtros: any): Promise<PlantillaResolModel[]> {
    return this.ventanillaUnicaResource.getListaPlantResolAdm(filtros);
  }

  public getListaPlantResolSeccAdm(filtros: any): Promise<PlantillaSeccionesModel[]> {
    return this.ventanillaUnicaResource.getListaPlantResolSeccAdm(filtros);
  }

  public getListaPlantResolEtiqAdm(filtros: any): Promise<ResolutivoEtiquetasCapturaModel[]> {
    return this.ventanillaUnicaResource.getListaPlantResolEtiqAdm(filtros);
  }

  getListaResolTramServByIdTramite(id: string): Promise<PlantillaResolModel[]> {
    return this.ventanillaUnicaResource.getListaResolTramServByIdTramite({ id: id });
  }

  getEtiquetasCaptura(): Promise<ResolutivoEtiquetasCapturaModel[]> {
    return this.ventanillaUnicaResource.getEtiquetasCaptura();
  }

  getEtiquetasCapturaId(id: string): Promise<ResolutivoEtiquetasCapturaModel[]> {
    return this.ventanillaUnicaResource.getEtiquetasCapturaId({ id: id });
  }

  getPlantillaDetDoc(id: string): Promise<PlantillaResolModel> {
    console.log("Estoy entrando a getPlantillaDetDoc, para el id..." + id);
    return this.ventanillaUnicaResource.getPlantillaDetDoc({id: id});
  }

  getCategoriaByTramite(idTramite: string): Promise<CategoriasModel> {
    console.log("Estoy entrando a getCategoriaByTramite, para el id..." + idTramite);
    return this.ventanillaUnicaResource.getCategoriaByTramite({idTramite: idTramite});
  }

  getSeccionesPlantillas(secciones: string): Promise<PlantillaSeccionesModel[]> {
    console.log("Plantillas: " + secciones);
    return this.ventanillaUnicaResource.getSeccionesPlantillas({secciones: secciones});
  }

  getListaSeccionesPlantillas(): Promise<PlantillaSeccionesModel[]> {
    return this.ventanillaUnicaResource.getListaSeccionesPlantillas();
  }

  getListaSeccionEtiqueta(): Promise<SeccionEtiquetaModel[]> {
    return this.ventanillaUnicaResource.getListaSeccionEtiqueta();
  }

  getListaDatoSeccionEtiqueta(claveetiq: string): Promise<DatoSeccionEtiquetaModel[]> {
    return this.ventanillaUnicaResource.getListaDatoSeccionEtiqueta({claveetiq: claveetiq});
  }

  getListaDatoTramiteEtiqueta(idTramite: string): Promise<DatoTramiteEtiquetaModel[]> {
    return this.ventanillaUnicaResource.getListaDatoTramiteEtiqueta({idTramite: idTramite});
  }

  getListaSeccionesPlantillasId(id: string): Promise<PlantillaSeccionesModel[]> {
    return this.ventanillaUnicaResource.getListaSeccionesPlantillasId({id: id});
  }

  getPDFResolutivo(id: string): Promise<ResolutivoDocPDFModel> {
    console.log("Entrando a buscar PDF...");
    return this.ventanillaUnicaResource.getPDFResolutivo({id: id});
  }

  getBpmEstadoProcesoMasterPorId(id: string): Promise<BpmEstadoProcesoMasterModel> {
    console.log("Entrando a buscar Master ID");
    return this.ventanillaUnicaResource.getBpmEstadoProcesoMasterPorId({id: id});
  }

  postJasperResolPDF(secresol: PlantillaSeccionesModel[]): Promise<ResolutivoDocPDFModel> {
    console.log("Entrando a postJasperResolPDF");
    return this.ventanillaUnicaResource.postJasperResolPDF(secresol);
  }

  postAprobarJasperResolPDF(secresol: PlantillaSeccionesModel[]): Promise<ResolutivoDocPDFModel> {
    console.log("Entrando a generar QR en resolutivo");
    return this.ventanillaUnicaResource.postAprobarJasperResolPDF(secresol);
  }

  deletePDFTramite(id: string): Promise<any>  {
    console.log("Entrando a eliminar el resolutivo pdf");
    return new Promise ( (resolve,reject) => {
      this.ventanillaUnicaResource.deletePDFTramite({id: id}).then(eliminar =>{
        resolve();
      },err =>{
        reject(err);
      });
    });
  }

  deleteResolutivo(id: string): Promise<any> {
    console.log("Entrando a eliminar el resolutivo pdf");
    return new Promise ( (resolve,reject) => {
      this.ventanillaUnicaResource.deleteResolutivo({id: id}).then(eliminar =>{
        resolve();
      },err =>{
        reject(err);
      });
    });
  }

  deleteEtiquetaResol(id: string): Promise<any> {
    console.log("Entrando a eliminar la etiqueta para resolutivos");
    return new Promise ( (resolve,reject) => {
      this.ventanillaUnicaResource.deleteEtiquetaResol({id: id}).then(eliminar =>{
        resolve();
      },err =>{
        reject(err);
      });
    });
  }

  deleteSeccionResol(id: string): Promise<any> {
    console.log("Entrando a eliminar la seccion para resolutivos");
    return new Promise ( (resolve,reject) => {
      this.ventanillaUnicaResource.deleteSeccionResol({id: id}).then(eliminar =>{
        resolve();
      },err =>{
        reject(err);
      });
    });
  }

  postFirmarArchivoServ(archivosFirmaModel: ArchivosFirmaModel): Promise<any> {
    return this.ventanillaUnicaResource.postFirmarArchivo({ archivosFirmaModel: archivosFirmaModel });
  }

  generaBase64(nombreArchivo: string): Promise<string> {
    return this.ventanillaUnicaResource.generaBase64({ nombreArchivo: nombreArchivo });
  }

  getResolutivoAprobQR(id: string): Promise<string> {
    return this.ventanillaUnicaResource.getResolutivoAprobQR({id: id});
  }

  patchDocResolutivo(valores: any) : Promise<any> {
    return this.ventanillaUnicaResource.patchDoctoResolutivo(valores);
  }

  postRegistraFirmaServ(registraFirmaModel: RegistraFirmaModel): Promise<any> {
    return this.ventanillaUnicaResource.postGuardarFirmarArchivo({ registraFirmaModel: registraFirmaModel });
  }

  getCargaListaPlantillasCancel(): Promise<PlantillasCancelacionModel[]> {
    return this.ventanillaUnicaResource.getCargaListaPlantillasCancel();
  }

  postBpmEstadoDetalleDoc(registraFirmaModel: RegistraFirmaModel): Promise<any> {
    return this.ventanillaUnicaResource.postBpmEstadoDetalleDoc(registraFirmaModel);
  }

  getEstadoProcesoDetalleUltimo(id_estado_master: string, siguiente: string): Promise<BpmEstadoProcesoDetalleModel> {
    return this.ventanillaUnicaResource.getEstadoProcesoDetalleUltimo({ id_estado_master: id_estado_master, siguiente: siguiente });
  }

  getEstadoProcesoDetalleAccion(id_estado_master: string): Promise<BpmEstadoProcesoDetalleModel> {
    console.log("Entre a getEstadoProcesoDetalleAccion, folio master: " + id_estado_master);
    return this.ventanillaUnicaResource.getEstadoProcesoDetalleAccion({ id_estado_master: id_estado_master });
  }

  getBpmEstadoProcesoMasterPorAsig(claveAsignacion: string): Promise<BpmEstadoProcesoMasterModel[]> {
    return this.ventanillaUnicaResource.getBmpEstadoProcesoMasterPorAsig({ claveAsignacion: claveAsignacion });
  }

  patchBpmEstadoProcesoDetalle(bpmEstadoProcesoDetalleModel: BpmEstadoProcesoDetalleModel): Promise<any> {
    return this.ventanillaUnicaResource.patchBmpEstadoProcesoDetalle(bpmEstadoProcesoDetalleModel);
  }

  obtenerListaArticulos(parametros: any): Promise<ArticulosModel[]> {
    return this.ventanillaUnicaResource.getListaArticulos(parametros);
  }
  obtenerArticulosTram(tram: string): Promise<ArticulosModel[]> {
    return this.ventanillaUnicaResource.getArticulosTram({ tram: tram });
  }

  postBpmEstadoProcesoDetalle(bpmEstadoProcesoDetalleModel: BpmEstadoProcesoDetalleModel): Promise<any> {
    return this.ventanillaUnicaResource.postBmpEstadoProcesoDetalle(bpmEstadoProcesoDetalleModel);
  }

  // Métodos para traer datos por medio de RFC
  obtContribuyentePorRFC(rfc: string): Promise<DatosContribuyenteModel> {
    return this.ventanillaUnicaResource.obtContribuyentePorRFC({ id: rfc });
  }
  obtEstadoPorID(id: string): Promise<any> {
    return this.ventanillaUnicaResource.obtEstadoPorID({ id: id });
  }
  obtMunicipioPorID(id: string): Promise<any> {
    return this.ventanillaUnicaResource.obtMunicipioPorID({ id: id });
  }
  obtColoniaPorID(id: string): Promise<any> {
    return this.ventanillaUnicaResource.obtColoniaPorID({ id: id });
  }
  obtCallePorID(id: string): Promise<any> {
    return this.ventanillaUnicaResource.obtCallePorID({ id: id });
  }

  // Rutas trámites

  public obtieneRutasTramitesListaCallejoneada(): Promise<RutasTramitesModel[]> {
    return this.ventanillaUnicaResource.obtieneRutasTramitesListaCallejoneada();
  }

  obtenerUsuarioOrganizacion(id: string): Promise<UserOrganizacionModel> {
    return this.ventanillaUnicaResource.obtenerUsuarioOrganizacion({id: id});
  }

  // Métodos para traer datos por medio de RFC --LOCAL
  obtContribuyenteRFCLocal(rfc: string): Promise<DatosContribuyenteModel> {
    return this.ventanillaUnicaResource.obtContribuyenteRFCLocal({id:rfc});
  }
  obtEstadoPorIDLocal(id: string): Promise<any> {
    return this.ventanillaUnicaResource.obtEstadoPorIDLocal({id:id});
  }
  obtMunicipioPorIDLocal(id: string): Promise<any> {
    return this.ventanillaUnicaResource.obtMunicipioPorIDLocal({id:id});
  }
  // Método para
  postContribuyente(datosCiudadanoModel:DatosCiudadanoModel): Promise <any> {
    return this.ventanillaUnicaResource.postGuardarContribuyente(datosCiudadanoModel);
  }
  obtenerLocacion(municipio: string, estado: string): Promise<any[]> {
    return this.ventanillaUnicaResource.obtenerLocacion({municipio:municipio,estado:estado});
  }
  obtPais(id: string): Promise<any> {
    return this.ventanillaUnicaResource.obtPais({id:id});
  }
  obtenerEstado(pais: string, state: string): Promise<any> {
    return this.ventanillaUnicaResource.obtenerEstado({pais:pais,state:state});
  }
  obtenerMunicipio(catalogo: string, municipio: string): Promise<any> {
    return this.ventanillaUnicaResource.obtenerMunicipio({catalogo:catalogo, municipio:municipio});
  }

  public obtenerEstados(params: any): Promise<any> {
    return this.ventanillaUnicaResource.obtenerEstados(params);
  }

  public obtenerEstadosNa(params: any): Promise<any> {
    return this.ventanillaUnicaResource.obtenerEstadosNa(params);
  }

  public obtenerMunicipios(params: any): Promise<any> {
    return this.ventanillaUnicaResource.obtenerMunicipios(params);
  }

  public obtenerMunicipiosNa(params: any): Promise<any> {
    return this.ventanillaUnicaResource.obtenerMunicipiosNa(params);
  }

  public obtenerCiudades(params: any): Promise<any> {
    return this.ventanillaUnicaResource.obtenerCiudades(params);
  }

  public obtenerCiudadesNa(params: any): Promise<any> {
    return this.ventanillaUnicaResource.obtenerCiudadesNa(params);
  }

  public obtenerContribuyente(params: any): Promise<DatosContribuyenteModel> {
    return this.ventanillaUnicaResource.obtenerContribuyente(params);
  }

  public enviarCodigoAccesoContribuyente(params: any): Promise<any> {
    return this.ventanillaUnicaResource.enviarCodigoAccesoContribuyente(params);
  }

  public validarCodigoAccesoContribuyente(params: any): Promise<any> {
    return this.ventanillaUnicaResource.validarCodigoAccesoContribuyente(params);
  }

  public crtPlantResolutivo(resolutivo: PlantillaResolModel): Promise<any> {
    return this.ventanillaUnicaResource.crtPlantResolutivo(resolutivo);
  }

  public updPlantResolutivo(resolutivo: PlantillaResolModel): Promise<any> {
    return this.ventanillaUnicaResource.updPlantResolutivo(resolutivo);
  }

  public crtEtiqResolutivo(etiqueta: ResolutivoEtiquetasCapturaModel): Promise<any> {
    return this.ventanillaUnicaResource.crtEtiqResolutivo(etiqueta);
  }

  public updEtiqResolutivo(etiqueta: ResolutivoEtiquetasCapturaModel): Promise<any> {
    return this.ventanillaUnicaResource.updEtiqResolutivo(etiqueta);
  }

  public crtSeccResolutivo(etiqueta: PlantillaSeccionesModel): Promise<any> {
    return this.ventanillaUnicaResource.crtSeccResolutivo(etiqueta);
  }

  public updSeccResolutivo(etiqueta: PlantillaSeccionesModel): Promise<any> {
    return this.ventanillaUnicaResource.updSeccResolutivo(etiqueta);
  }

  public updCancelaTramite(valor_cancela: string): Promise<any> {
    return this.ventanillaUnicaResource.updCancelaTramite({valor_cancela:valor_cancela});
  }


  public getListaResolutivosPlantHTML(filtros: any): Promise<any[]> {
    return this.ventanillaUnicaResource.getListaResolutivosPlantHTML(filtros);
  }

  public savePlantillaHTML(body: PlantillaResolModel): Promise<boolean> {
    return this.ventanillaUnicaResource.insertPlantillaHtml(body);
  }

  public updatePlantillaHTML(body: PlantillaResolModel): Promise<boolean> {
    return this.ventanillaUnicaResource.updatePlantillaHtml(body);
  }

  public deletePlantillaHtml(body: {ids: string[]}): Promise<boolean> {
    return this.ventanillaUnicaResource.deletePlantillaHtml(body);
  }

  public savePdfPlantillaHTML(body: ResolutivoDocPDFModel): Promise<any> {
    return this.ventanillaUnicaResource.insertPdfPlantillaHtml(body);
  }
  
}
