import { ResourceAuth } from "../../../auth/authenticate.resource";
import { Injectable } from "@angular/core";
import { ResourceParams, ResourceHandler, ResourceAction, ResourceRequestMethod, IResourceMethod } from "@ngx-resource/core";
import { AuthServerProvider } from "../../../auth/auth-jwt.service";
import { PaymentMethodPortalModel } from '../../../../portal-legacy/portal.traslado.module/service/paymentMethodPortal.model';//"../../../../../  /portal.traslado.module/service/paymentMethodPortal.model";

@Injectable()
@ResourceParams({
  pathPrefix: '/payments'
})
export class PaymentMethodPortalResource extends ResourceAuth {
  constructor(resourceHandler: ResourceHandler, provider: AuthServerProvider) {
    super(resourceHandler, provider);
  }

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/payment-method-portal'
  })
  getAllPaymentMethodPortal: IResourceMethod<any,PaymentMethodPortalModel[]>;
}