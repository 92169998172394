import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ModalDirective} from 'angular-bootstrap-md';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastService} from 'ng-uikit-pro-standard';
import {RequisitosService} from '../service/requisitos.service';
import {ExpedienteArchivosModel} from '../service/expediente-archivos.model';
import {DataShareService} from '../../common.module/util/data.share';

@Component({
  selector: 'app-observacion-exp-elect-detalle',
  templateUrl: './observacion-exp-elect-detalle.component.html',
  styles: []
})
export class ObservacionExpElectDetalleComponent implements OnInit {
  @ViewChild('CrearObservacion')
  public modalObservacion: ModalDirective;
  public formObservacion: FormGroup;
  @Input()
  public observacion;
  @Input()
  public numRequisito: string;
  constructor(private fb: FormBuilder,
              private toastMsg: ToastService,
              private requisitosService: RequisitosService,
              private dataShare: DataShareService
              ) {
  }

  ngOnInit() {
    this.formObservacion = this.fb.group({
      formVigenciaReq: ['', Validators.maxLength(50)],
    });
  }

  crearObserv() {
    if (this.formObservacion.valid == false) {
      this.toastMsg.warning('Tamaño máximo de letras alcanzado (50)');
      return;
    }
    console.log('observacion ', this.observacion);
    if (this.observacion !== null && this.observacion !== undefined) {
      let aux: ExpedienteArchivosModel;
      // parchararchivo
      aux = {
        estatus: 'R',
        observacion: this.observacion,
        _id: this.numRequisito
      }

        this.requisitosService.actualizarArchivoId(aux).then(res => {
          this.toastMsg.success('El estatus se ha actualizado con éxito');
          this.dataShare.confirmObject({'regreso_de_modal_de_observacion': 'R', '_id': this.numRequisito});
          this.formObservacion.reset();
          this.modalObservacion.hide();
        }, err => {
          if (err.body) {
            if (err.body.message)
              this.toastMsg.error(err.body.message);
            else
              this.toastMsg.error('Hubo un problema al actualizar el archivo');
          } else {
            this.toastMsg.error('Hubo un error inesperado');
          }
          this.modalObservacion.hide();
        });
    } else {
      this.toastMsg.error('Error al guardar, no se encontro motivo de rechazo.');
    }
  }

}
