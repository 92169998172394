/**
 * Created by rsierra on 25/09/2017.
 */

import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {ConsultaExpElecComponent} from './consulta-exp-elec/consulta-exp-elec.component';


const exampleRoutes: Routes = [
  {
    path: '',
    component: ConsultaExpElecComponent,
    data: {
      pageTitle: 'Expediente Electrónico'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(exampleRoutes)],
  exports: [RouterModule]
})
export class AdminCiudadanoRoutingModule {
}
