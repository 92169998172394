import { Injectable } from '@angular/core';
import {ConfiguracionGeneralResourceConsumos} from '../resource/configuracion-general.resource';
import {ConfiguracionGeneralModel} from '../model/configuracion-general.model';
import {UsuarioModel} from '../model/usuario.model';
import {EstablecimientoModel} from '../model/establecimientoModel.model';

@Injectable()
export class ConfiguracionGeneralServiceConsumos {

    constructor(private configuracionGeneralResource: ConfiguracionGeneralResourceConsumos) { }
  
    obtenerConfiguracionGeneral(): Promise<ConfiguracionGeneralModel> {
      return this.configuracionGeneralResource.obtenerConfiguracionGeneral();
    }
  
    actualizarConfiguracionGeneral(configuracion: ConfiguracionGeneralModel): Promise<any> {
      return this.configuracionGeneralResource.actualizarConfiguracionGeneral(configuracion);
    }

    obtenerUsuario(id: string): Promise<UsuarioModel> {
      return this.configuracionGeneralResource.obtenerUsuario({id: id});
    }
  
    obtenerEstablecimiento(id: string): Promise<EstablecimientoModel> {
      return this.configuracionGeneralResource.obtenerEstablecimiento({id: id});
    }
  
  }
  