<div mdbModal #CreateUser="mdb-modal" class="modal fade overflow-auto" tabindex="-1" role="dialog"
     aria-labelledby="myBasicModalLabel" aria-hidden="true" [config]="{backdrop: true, ignoreBackdropClick: true}">
  <div class="modal-dialog mw-90i" role="document">
    <div class="modal-content">
      <div mti-color-theme class="modal-header">
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <span aria-hidden="true">×</span>
        </button>
        <h4 class="modal-title w-100" id="myModalLabel">{{(!user._id) ? 'Nuevo Usuario' : 'Detalle de Usuario: ' +
          user.nombre}}</h4>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <form [formGroup]="formUser">
              <div class="row no-gutters">
                <div class="col-12 d-inline-flex">
                  <div class="switch mr-auto mt-2" [ngClass]="{disabled: disEdit}">
                    <label>
                      Inactivo
                      <input type="checkbox" [checked]="user.estatus === 'A'"
                             (change)="user.estatus = user.estatus == 'A' ? 'I' : 'A'">
                      <span class="lever"></span>
                      Activo
                    </label>
                  </div>

                  <button mdbBtn type="button" color="primary" rounded="true" outline="true" mdbWavesEffect size="sm"
                          class="ml-auto" *ngIf="user._id !== undefined" (click)="enEdit()">
                    <i class="fa mr-1" aria-hidden="true" [ngClass]="{'fa-eye': disEdit, 'fa-edit': !disEdit}"></i>
                    {{ disEdit ? 'Editar' : 'Editando...'}}
                  </button>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-12 col-20-p">
                  <div class="md-form">
                    <input mdbInput type="text" id="nombre" class="form-control overflow-ellipsis"
                           formControlName="defaultFormName"
                           [(ngModel)]="user.nombre" [ngClass]="{disabled: disEdit}"
                           autocomplete="off">
                    <label for="nombre" [ngClass]="{disabled: disEdit}">Nombre</label>
                    <div *ngIf="formUser.controls['defaultFormName'].touched">
                      <div *ngIf="formUser.controls['defaultFormName'].errors?.required"
                           class="text-danger animated fadeInDown fast-4 small">
                        El nombre del usuario es obligatorio.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-20-p">
                  <div class="md-form">
                    <input mdbInput type="text" id="apellido_p" class="form-control"
                           formControlName="defaultFormFirstName"
                           [(ngModel)]="user.apellido_p" [ngClass]="{disabled: disEdit}">
                    <label for="apellido_p" [ngClass]="{disabled: disEdit}">Apellido Paterno</label>
                    <div *ngIf="formUser.controls['defaultFormFirstName'].touched">
                      <div *ngIf="formUser.controls['defaultFormFirstName'].errors?.required"
                           class="text-danger animated fadeInDown fast-4 small">
                        El apellido paterno del usuario es obligatorio.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-20-p">
                  <div class="md-form">
                    <input mdbInput type="text" id="apellido_m" class="form-control"
                           formControlName="defaultFormLastName"
                           [(ngModel)]="user.apellido_m" [ngClass]="{disabled: disEdit}">
                    <label for="apellido_m" [ngClass]="{disabled: disEdit}">Apellido Materno</label>
                    <div *ngIf="formUser.controls['defaultFormLastName'].touched">
                      <div *ngIf="formUser.controls['defaultFormLastName'].errors?.required"
                           class="text-danger animated fadeInDown fast-4 small">
                        El apellido materno del usuario es obligatorio.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-20-p alinear-date-input-2">
                  <div class="md-form mt-2">
                    <mdb-date-picker id="birthdate" name="birthdate" #birthdate
                                     [placeholder]="'Seleccione una fecha'"
                                     [ngModelOptions]="{standalone: true}"
                                     [(ngModel)]="user.cumpleanios"
                                     [options]="myDateOptionPicker"
                                     [ngClass]="{disabled: disEdit}">
                    </mdb-date-picker>
                    <label  class="active" [ngClass]="{disabled: disEdit}" for="birthdate">Fecha de nacimiento</label>
                  </div>
                </div>
                <div class="col-12 col-20-p">
                  <div class="md-form">
                    <input mdbInput type="text" id="telefono" class="form-control" [(ngModel)]="user.telefono"
                           formControlName="defaultFormTelephone" [ngClass]="{disabled: disEdit}">
                    <label for="telefono" [ngClass]="{disabled: disEdit}">Número telefónico</label>
                    <div *ngIf="formUser.controls['defaultFormTelephone'].touched">
                      <div *ngIf="formUser.controls['defaultFormTelephone'].errors?.maxlength"
                           class="text-danger animated fadeInDown fast-4 small">El campo tiene que ser menor de 10
                        caracteres
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-12 col-20-p">
                  <div class="md-form">
                    <input mdbInput type="text" id="rfc1" class="form-control text-uppercase" [(ngModel)]="user.rfc"
                           formControlName="defaultFormRFC" [ngClass]="{disabled: disEdit}">
                    <label for="rfc1" [ngClass]="{disabled: disEdit}">RFC</label>
                    <div *ngIf="formUser.controls['defaultFormRFC'].touched">
                      <div *ngIf="formUser.controls['defaultFormRFC'].errors?.pattern"
                           class="text-danger animated fadeInDown fast-4 small">Formato de RFC incorrecto.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-20-p alinear-select-input">
                  <mdb-select [options]="optionsSex" placeholder="Seleccione el género..."
                              [ngModelOptions]="{standalone: true}"
                              [(ngModel)]="user.sexo" [ngClass]="{disabled: disEdit}">
                  </mdb-select>
                </div>
                <div class="col-12 col-20-p">
                  <div class="md-form">
                    <input mdbInput type="text" id="correo" class="form-control"
                           formControlName="defaultFormEmail"
                           [(ngModel)]="user.correo"
                           [ngClass]="{disabled: disEdit }">
                    <label for="correo" [ngClass]="{disabled: disEdit }">Correo
                      electrónico</label>
                    <div *ngIf="formUser.controls['defaultFormEmail'].touched">
                      <div *ngIf="formUser.controls['defaultFormEmail'].errors?.required"
                           class="text-danger animated fadeInDown fast-4 small">
                        El correo electrónico del usuario es obligatorio.
                      </div>
                      <div *ngIf="formUser.controls['defaultFormEmail'].errors?.pattern"
                           class="text-danger animated fadeInDown fast-4 small">
                        El formato del correo electrónico es incorrecto.
                      </div>
                    </div>
                  </div>
                </div>
                <!-- </div>
                <div class="row no-gutters"> -->
                <div class="col-12 col-20-p">
                  <div class="md-form">
                    <input mdbInput type="password" id="contrasenia" class="form-control"
                           formControlName="password1"
                           [(ngModel)]="user.contrasenia" [ngClass]="{disabled: disEdit}">
                    <label for="contrasenia" [ngClass]="{disabled: disEdit}">Contraseña</label>
                    <div *ngIf="user.contrasenia">
                      <div *ngIf="user.contrasenia != user.contraseniaConfirma"
                           class="text-danger animated fadeInDown fast-4 small">
                        Las contraseñas no coinciden, favor de validar.
                      </div>
                    </div>

                    <div *ngIf="formUser.controls['password1'].touched">
                      <div *ngIf="formUser.controls['password1'].errors?.required"
                           class="text-danger animated fadeInDown fast-4 small">
                        El password del usuario es obligatorio.
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-12 col-20-p">
                  <div class="md-form">
                    <input mdbInput type="password" id="contraseniaConfirma" class="form-control"
                           formControlName="defaultPassword2"
                           [(ngModel)]="user.contraseniaConfirma" [ngClass]="{disabled: disEdit}">
                    <label for="contraseniaConfirma" [ngClass]="{disabled: disEdit}">Confirmación de contraseña</label>
                    <div *ngIf="user.contrasenia ">
                      <div *ngIf="user.contrasenia != user.contraseniaConfirma"
                           class="text-danger animated fadeInDown fast-4 small">
                        Las contraseñas no coinciden, favor de validar.
                      </div>
                    </div>

                    <div *ngIf="formUser.controls['defaultPassword2'].touched">
                      <div *ngIf="formUser.controls['defaultPassword2'].errors?.required"
                           class="text-danger animated fadeInDown fast-4 small">
                        La confirmación del usuario es obligatorio.
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-4">
                  <mdb-checkbox [disabled]="disEdit" [(ngModel)]="user.twoFactorAuth" formControlName="twoFactorAuth">
                    Doble autentificación
                  </mdb-checkbox>
                </div>
                <div class="col-2">
                  <button class="btn btn-cyan" [ngClass]="{disabled: disEdit}"
                          (click)="triggerFileInput()">
                          Subir firma
                  </button>
                </div>
                <div class="col">
                  <input type="file" #fileInput (change)="onFileSelected($event)" accept="image/*" style="display: none;" />
                  <!-- Vista previa de la imagen cargada -->
                  <div *ngIf="imageSrc" class="image-preview">
                    <img [src]="imageSrc" alt="Vista previa de la firma" />
                  </div>
                </div>
              </div>
            </form>
            <br>
            <div class="row">
              <div class="col-12 col-lg-12">
                <h4 class="mt-3">Roles de usuario</h4>
                <hr class="p-0">
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6 mt-2">
                <mdb-select [filterEnabled]="true" [options]="optionsSelect" placeholder="Seleccione un rol..."
                            [(ngModel)]="rolSelected" [ngClass]="{disabled: disEdit}">
                </mdb-select>
                <label [ngClass]="{disabled: disEdit}">Catálogo de Roles</label>
              </div>
              <div class="col-12 col-lg-3">
                <button class="btn btn-cyan w-100" [ngClass]="{disabled: disEdit}"
                        (click)="rolSelected && showAuthorityDetail(rolSelected._id); rolSelected && authorityModal.modal.show();">
                  <i class="fa fa-info-circle"></i> Ver a detalle
                </button>
              </div>
              <div class="col-12 col-lg-3">
                <button class="btn btn-info w-100" (click)="addAuthority()" [ngClass]="{disabled: disEdit}">
                  <i class="fa fa-plus-circle"></i> Agregar
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-12">
                <ul class="list-group mt-3">
                  <li class="list-group-item d-flex animated flipInX fast-5"
                      *ngFor="let auth of user.roles">
                <span class="cursor-pointer "
                      (click)="showAuthorityDetail(auth._id); authorityModal.modal.show();">
                  <i class="fa fa-info-circle mr-2 mt-1"></i> {{ auth.nombre }}
                </span>
                    <i class="fa fa-trash text-danger ml-auto mt-1 cursor-pointer"
                       (click)="removeAuthority(auth)" [ngClass]="{disabled: disEdit}"></i>
                  </li>
                  <li class="list-group-item cursor-pointer text-muted text-center animated flipInX fast-5"
                      *ngIf="!user.roles || user.roles?.length === 0"> No se han agregado roles al usuario.
                  </li>
                </ul>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col-12 col-lg-12">
                <h4 class="mt-3">Organización</h4>
                <hr class="p-0">
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-6 mt-2">
                <mdb-select [filterEnabled]="true" (selected)="getValue($event)" [options]="listaOrganizacion" placeholder="Seleccione una organización..."
                            [(ngModel)]="idOrganizacion" [ngClass]="{disabled: disEdit}">
                </mdb-select>
                <label [ngClass]="{disabled: disEdit}">Catálogo de Organizaciones</label>
              </div>
              <div class="col-12 col-lg-3">
                <button class="btn btn-cyan w-100" [ngClass]="{disabled: disEdit}"
                (click)="rolSelected && showAuthorityDetail(rolSelected._id); rolSelected && authorityModal.modal.show();">
                <i class="fa fa-info-circle"></i> Ver a detalle
                </button>
              </div>
              <div class="col-12 col-lg-3">
                <button class="btn btn-info w-100" (click)="agregarOrganizacion()" [ngClass]="{disabled: disEdit}">
                  <i class="fa fa-plus-circle"></i> Agregar
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-lg-12">
                <ul class="list-group mt-3">
                  <li class="list-group-item d-flex animated flipInX fast-5"
                  *ngIf="user.id_organizacion || user.id_organizacion?.length > 0">
                <span class="cursor-pointer "
                      (click)="showAuthorityDetail(auth._id); authorityModal.modal.show();">
                  <i class="fa fa-info-circle mr-2 mt-1"></i> {{ user.organizacionNombre }}
                </span>
                    <i class="fa fa-trash text-danger ml-auto mt-1 cursor-pointer"
                       (click)="removerOrganizacion()" [ngClass]="{disabled: disEdit}"></i>
                  </li>
                  <li class="list-group-item cursor-pointer text-muted text-center animated flipInX fast-5"
                  *ngIf="!user.id_organizacion || user.id_organizacion?.length === 0"> No se ha agregado una organización al usuario.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="hideModal()" class="btn btn-danger waves-light btn-md"
                aria-label="Close"
                mdbWavesEffect>
          Cancelar
        </button>
        <button class="btn btn-info btn-theme-primary btn darken-3 waves-ligth btn-md" mdbWavesEffect
                (click)="validateAllFormFields(formUser) && formUser.valid && saveUser()"
                [ngClass]="{disabled: disEdit}" mti-color-theme>
          <i class="fa fa-save" aria-hidden="true"></i> Guardar
        </button>
      </div>
    </div>
  </div>
</div>
<app-authority-detail #authorityModal [authority]="authorityDetail"></app-authority-detail>
