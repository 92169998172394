<!--Card-->
<div  class="card mt-2 container-chat group-container-chat fadeIn fast-5" 
      style="overflow-y: auto;"
      (click)="focusChat()" 
      *ngIf="showChat">
  <div class="chat-card-header grey lighten-2 pr-2 pl-3 py-0 text-center d-flex justify-content-between">
    <i class="fa fa-ellipsis-h fa-3x grey-text chat-minimize-icon float-left  pt-1 cursor-pointer"
       (click)="ventanaChat.toggle()"></i>
    <div class="mr-2">Ciudadano en línea</div>
    <i class="fa fa-times float-right pt-1 cursor-pointer mx-1" (click)="cleanMessages()"></i>
  </div>
  <div mdbCollapse (collapsed)="change(false)" (expanded)="change(true)" [isCollapsed]="false"
       #ventanaChat="bs-collapse"
       [ngClass]="{'chat-height': expanded}">
    <div class="card-body p-0">
      <div mti-color-theme class="row header-body-chat px-3 text-white blue darken-2 mx-0 py-2">
        <div class="col-2 px-0">
          <i class="fas fa-headset mx-1 my-2" style="font-size: 2rem;"></i>
        </div>
        <div class="col-10 pl-2 pr-0 text-uppercase">
          <div class="font-weight-bold">
            Chat
          </div>
          <div class="font-weight-light">
            De orientación ciudadana
          </div>
        </div>
      </div>
      <div mti-color-theme class="amber chat-modal-aviso accent-4 text-white text-center extra-small mx-0 p-1">
        Si tiene alguna duda, comuníquese con un funcionario
      </div>
      <div class="mx-3" *ngIf="!showConversation">
        <form [formGroup]="chatForm">
          <label for="chat_name" class="small mb-0">Nombre completo*</label>
          <input type="text" id="chat_name" class="form-control p-0" formControlName="name"
                 [(ngModel)]="chatRequest.name">
          <div *ngIf="chatForm.controls['name'].touched">
            <div *ngIf="chatForm.controls['name'].errors?.required"
                 class="text-danger animated fadeInDown fast-4 small">Dato requerido
            </div>
          </div>
          <label for="chat_email" class="small mb-0">Correo electrónico*</label>
          <input type="text" id="chat_email" class="form-control p-0" formControlName="email"
                 [(ngModel)]="chatRequest.email">
          <div *ngIf="chatForm.controls['email'].touched">
            <div *ngIf="chatForm.controls['email'].errors?.required"
                 class="text-danger animated fadeInDown fast-4 small">Dato requerido
            </div>
            <div *ngIf="chatForm.controls['email'].errors?.email"
                 class="text-danger animated fadeInDown fast-4 small">Formato de correo inválido
            </div>
          </div>
          <div class="md-form" *ngIf="ifDayIsEnabled() && ifCurrentTimeIsInRange()">
            <mdb-select mdbActive formControlName="formality" id="formality" [options]="formalitiesList"
                        class="chat-select pb-2"
                        [filterEnabled]="'true'" placeholder="Seleccione"
                        [(ngModel)]="chatRequest.formality"></mdb-select>
            <label class="small-black">Trámite</label>
          </div>
          <div class="" *ngIf="!ifDayIsEnabled() || !ifCurrentTimeIsInRange()">
            <label for="subject" class="small mb-0">Asunto*</label>
            <textarea mdbActive type="text" id="subject" class="w-100" rows="3"
                      [(ngModel)]="chatRequest.subject" formControlName="subject"></textarea>
            <div *ngIf="chatForm.controls['subject'].touched">
              <div *ngIf="chatForm.controls['subject'].errors?.required"
                   class="text-danger animated fadeInDown fast-4 small">Dato requerido
              </div>
            </div>
          </div>
          <div class="float-right mt-2 chat-accept-bottom">
            <button type="button" mti-color-theme
                    class="btn btn-sm btn-theme-secondary corner-round-btn amber accent-4 waves-light white-text"
                    mdbWavesEffect
                    (click)="getConversation()">
              Aceptar
            </button>
          </div>
        </form>

        <div class="modal fade" #outOfWorkModal id="outOfWorkModal" tabindex="-1" role="dialog"
             aria-labelledby="outOfWorkModalLabel" aria-hidden="true"
             style="position: absolute !important; top: 1.5rem; overflow: auto">
          <div class="mt-auto ml-auto mr-2">
            <button type="button" mti-color-theme
                    class="btn btn-theme-secondary chat-modal-close-button amber accent-4 waves-light" mdbWavesEffect
                    (click)="closeChatModal()">
              <span class="white-text font-weight-light" aria-hidden="true">✖</span>
            </button>
          </div>
          <div class=" mb-auto mt-0 mx-3">
            <div class="modal-content corner-round-btn">
              <div mti-color-theme
                   class="modal-body chat-modal-aviso corner-round-btn amber accent-4 white-text text-center extra-small">
                <div class="font-weight-bold">
                  ¡Gracias por ponerse en contacto con nosotros!
                </div>
                <div class="my-4 font-weight-light" *ngIf="diaExclusion">
                  El día de hoy no hay servicio de chat en celebración del siguiente día: <span
                  class="font-weight-bold">{{diaExclusion.nombre}}</span>
                </div>
                <div class="my-4 font-weight-light">
                  Le recordamos que nuestro horario de atención es:
                  <div><span class="font-weight-bold">Lunes:</span> {{obtenerHorario(1)}}</div>
                  <div><span class="font-weight-bold">Martes:</span> {{obtenerHorario(2)}}</div>
                  <div><span class="font-weight-bold">Miércoles:</span> {{obtenerHorario(3)}}</div>
                  <div><span class="font-weight-bold">Jueves:</span> {{obtenerHorario(4)}}</div>
                  <div><span class="font-weight-bold">Viernes:</span> {{obtenerHorario(5)}}</div>
                  <div><span class="font-weight-bold">Sábado:</span> {{obtenerHorario(6)}}</div>
                  <div><span class="font-weight-bold">Domingo:</span> {{obtenerHorario(0)}}</div>
                </div>
                <div class="font-weight-light mb-3">
                  Déjenos sus datos y uno de nuestros funcionarios se pondrá en contacto usted
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div #backdrop></div>
      <perfect-scrollbar style="max-height: 252px;" *ngIf="showConversation">
        <ul class="list-group" style="height: 252px">
          <li class="list-group-item px-2 py-1 animated flipInX fast-4" *ngFor="let message of messagesList"
              [ngClass]="{'grey lighten-4': (user?._id == message?.user_id || message?.user_id == null),
                  'text-danger': (message.error && message.error !== ''),
                  'cyan lighten-3': message.visto == false}">
            <p class="mb-1"
               [ngClass]="{'float-right': (user?._id == message?.user_id || message?.user_id == null),
               'float-left': user?._id != message?.user_id}">
              {{message?.message}}</p>
            <div class="clearfix"></div>
            <small class="text-muted"
                   [ngClass]="{'float-right': user?._id == message?.user_id, 'float-left': user?._id != message?.user_id}">
              <strong>{{message.created_at | date: 'short'}}</strong></small>
          </li>
          <li class="list-group-item text-center" *ngIf="messagesList.length == 0">
            <small>¿Desea que le ayudemos en algo?</small>
          </li>
        </ul>
      </perfect-scrollbar>
    </div>
    <div class="card-footer text-muted white-color white-text p-1" *ngIf="showConversation">
      <div class="md-form input-group my-0">
        <input type="text" class="form-control pl-1" placeholder="Escribe tu mensaje..."
               [(ngModel)]="message.message"
               aria-describedby="basic-addon2" (keydown.enter)="saveMessage(adminToken)">
        <div class="input-group-append cursor-pointer" (click)="saveMessage(adminToken)">
          <span class="input-group-text"><mdb-icon far icon="paper-plane"></mdb-icon></span>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Card-->
<a type="button" mti-color-theme
   class="chat-theme-component white-text button-chat warning-color-dark animated fadeIn fast-5"
   (click)="openChat()" *ngIf="!showChat && chatConfiguracionModel.habilitado == true"
   mdbTooltip="Si tiene dudas, comuníquese con un funcionario." placement="left">
  <i class="fas fa-headset mx-1 my-2" style="font-size: 2rem;"></i>
  <i class="fas fa-phone mx-1 my-2" style="font-size: 2rem;"></i>
</a>
