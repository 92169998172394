import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastService} from 'ng-uikit-pro-standard';
import {ActivatedRoute, Router} from '@angular/router';
import {Location, DatePipe} from '@angular/common';
import {ConsultaPagoModel} from '../service/consulta-pago.model';
import {ConsultaPagoService} from '../service/consulta-pago.service';
import {ValidatorHelper} from '../../common.module/util/validator.helper';
import {DownloadHelper} from '../../common.module/util/download-helper.service';

@Component({
    selector: 'app-consulta-pago',
    templateUrl: 'consulta-pago.component.html'
})

export class ConsultaPagoComponent implements OnInit {
    constructor(private fb: FormBuilder, private toastMsg: ToastService,
                private activedRoute: ActivatedRoute,
                private router: Router,
                private location: Location,
                private consultaPagoService: ConsultaPagoService) {
    }

    reciboForm: FormGroup;
    listaReciboPago: any;
    public existRecibo = false;
    public isTimbrado = false;
    public prmImporteTotal = 0;
    public modelCampos: ConsultaPagoModel = {};
    public filterModel: any = {
        elemPorPagina: 5,
        pagina: 1,
        forma: 'DESC',
        ordenarPor: '_id',
        adm_tramite_id: '',
        folio: '',
        serie: '',
        importeTotal: ''
    };
    public fileName: string;

    public importeT: any;
    public importeTS: string;
    public decimales: any;

    ngOnInit() {
        this.reciboForm = this.fb.group({
            formSerie: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9]{1,8}')])],
            formFolio: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]{1,15}')])],
            formImporte: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]+(.[0-9]{2})?')])]
        });
    }

    regresar() {
        this.location.back();
    }

    buscarRecibo() {
        if (this.reciboForm.valid == false) {
            this.toastMsg.warning('Favor de llenar los campos obligatorios');
            ValidatorHelper.validateAllFormFields(this.reciboForm);
            return;
        }
        this.decimales = this.modelCampos.importeTotal.split('.', 2);
        if (this.decimales[1] == '00') {
            this.importeT = parseFloat(this.modelCampos.importeTotal);
            this.importeTS = this.importeT + '.0';
        } else {
            this.importeT = parseFloat(this.modelCampos.importeTotal);
            this.importeTS = String(this.importeT);
        }
        console.log(' ' + this.importeT);
        this.filterModel.folio = this.modelCampos.folio;
        this.filterModel.serie = this.modelCampos.serie.toUpperCase();
        this.filterModel.importeTotal = this.importeTS;

        // this.consultaPagoService.getInfoRecibo(this.filterModel).then(resp => {
        this.consultaPagoService.getInfoReciboConfigurable(this.filterModel).then(resp => {
            // @ts-ignore
            // Valida si se encontraron registros y se asigna el resultado en variables o se lanza mensaje de advertencia.
            if (resp.length > 0) {
            // Cuando es un response de motores, se recibe una lista (se considera solo si tiene 1 o más elementos)
                this.listaReciboPago = resp;
                this.modelCampos.folioPaseCaja = resp[0].folioPaseCaja;
                this.modelCampos.referenciaPago = resp[0].referenciaPago;
                // Verifica si está timbrado y lo asigna en una variable flag.
                this.isTimbrado = resp[0].estatusFact === 'T';
                this.existRecibo = true;
            } else if (resp != null) {
            // Cuando es un response de reger, se recibe un elemento o un err. 400 (se considera si no es null)
                const responseConvertido = this.convertirResponseRegerEnResponseMotores(resp);
                console.log("AOG importe capturado: " + this.filterModel.importeTotal);
                console.log("AOG importe obtenido: " + this.prmImporteTotal);
                if (this.filterModel.importeTotal == this.prmImporteTotal) {
                  // Se necesita crear constante y reasignar para evitar error al compilar
                  this.listaReciboPago = responseConvertido;
                  this.modelCampos.folioPaseCaja = this.listaReciboPago.folioPaseCaja;
                  // Verifica si está timbrado y lo asigna en una variable boolean.
                  const respuestaGenerica: any = resp; // Asignación necesaria para evitar mensaje de error al compilar.
                  this.isTimbrado = respuestaGenerica.ESTATUS === 'TIMBRADO';
                  this.existRecibo = true;
                } else {
                  this.existRecibo = false;
                  this.toastMsg.warning('El importe capturado no coincide con el importe del recibo con la serie: ' + this.filterModel.serie +
                      ' y folio: ' + this.filterModel.folio + '. Intente nuevamente.');
                }
            } else {
                this.existRecibo = false;
                this.toastMsg.warning('No existe el recibo con la serie: ' + this.filterModel.serie +
                    ' y folio: ' + this.filterModel.folio + '. Intente nuevamente.');
            }
        }, err => {
            console.log(err);
            if (err.body) {
                this.toastMsg.warning(err.body['message']);
            } else {
                this.toastMsg.error('Ocurrio un error al consultar el recibo.');
            }
        });
    }

    private convertirResponseRegerEnResponseMotores(resp: any): ConsultaPagoModel[] {
        const responseMotores: ConsultaPagoModel[] = [];
        const consultaPago: ConsultaPagoModel = {};
        consultaPago.folio = resp.FOLIO;
        consultaPago.serie = resp.SERIE;

        // inicializo importe total
        this.prmImporteTotal = resp.IMPORTE_TOTAL;

        consultaPago.importeTotal = resp.IMPORTE_TOTAL;
        // convierte de UTC a hora local y da formato 'DD/MM/AAAA HH:MM:SS'
        consultaPago.fechaPago = this.formatearFechaIgnorandoZonaHoraria(resp.FECHA_PAGO);
        consultaPago.nombre_contribuyente = resp.NOMBRE;
        consultaPago.rfc_contribuyente = resp.RFC_CONTRIBUYENTE;
        consultaPago.rfc_timbrado = resp.RFC_FACTURA;
        consultaPago.estatus = resp.ESTATUS;
        consultaPago.uuid = resp.UUID;
        // convierte de UTC a hora local y da formato 'DD/MM/AAAA HH:MM:SS'
        consultaPago.fechaTimbrado = this.formatearFechaIgnorandoZonaHoraria(resp.FECHA_TIMBRADO);
        consultaPago.folioPaseCaja = resp.PASE_CAJA;
        responseMotores.push(consultaPago);
        return responseMotores;
    }

    /**
     * Da formato de fecha 'DD/MM/AAAA HH:MM:SS' sin convertir a hora local.
     *
     * Si no es fecha válida o no es una fecha, devuelve string vacío.
     * @param fecha
     */
    private formatearFechaIgnorandoZonaHoraria(fecha: string): string {
        let fechaFormateada = '';
        if (fecha != null) {
            // Recorta la fecha omitiendo la zona horaria. En este ejemplo "2021-10-06T11:30:43Z" le quita la Z.
            fecha = fecha.substring(0, 19);
            const fechaZonaHorariaLocal = new Date(fecha);
            // verifica qeu sea una fecha válida
            if (Object.prototype.toString.call(fechaZonaHorariaLocal) === '[object Date]' &&
                !isNaN(fechaZonaHorariaLocal.getTime())) {  // d.valueOf() could also work
                const datepipe: DatePipe = new DatePipe('es-MX');
                fechaFormateada = datepipe.transform(fechaZonaHorariaLocal, 'dd/MM/yyyy HH:mm:ss');
            }
        }
        return fechaFormateada;
    }

    /**
     * Convierte de UTC a hora local y da formato de fecha 'DD/MM/AAAA HH:MM:SS'
     *
     * Si no es fecha válida o no es una fecha, devuelve string vacío.
     * @param fecha
     */
    private convertirYFormatearAFechaHoraLocal(fecha: string): string {
        const fechaZonaHorariaLocal = new Date(fecha);
        let fechaFormateada = '';
        // verifica qeu sea una fecha válida
        if (Object.prototype.toString.call(fechaZonaHorariaLocal) === '[object Date]' &&
            !isNaN(fechaZonaHorariaLocal.getTime()) ) {  // d.valueOf() could also work
                const datepipe: DatePipe = new DatePipe('es-MX');
                fechaFormateada =  datepipe.transform(fechaZonaHorariaLocal, 'dd/MM/yyyy HH:mm:ss');
        }
        return fechaFormateada;
    }

    public descargarComprobante(serie: string, folio: string) {
        this.fileName = `Recibo-${serie.toUpperCase()}-${this.modelCampos.folio}.pdf`;
        console.log('Descargando ', this.fileName);
        this.consultaPagoService.descargarComprobanteConfigurable(serie, folio).then(res => {
            // tslint:disable-next-line:no-console
            console.info('Comprobante', folio);
            this.toastMsg.success('Descargando el comprobante de pago.');
            // El stream de bytes viene bajo la llave «comprobante»(motores) o «base64»(reger)
            const archivo = res.comprobante != null ? res.comprobante : res.base64;
            DownloadHelper.downloadFile(this.fileName, archivo);
        }, err => {
            if (err.body && err.body.message) {
                this.toastMsg.error(err.body.message);
            } else {
                this.toastMsg.error('Por el momento no esta disponible la descarga.');
            }
        });
    }

    public descargarPdf(serie: string, folio: string, importe: string) {
        this.fileName = `Factura-${serie.toUpperCase()}-${this.modelCampos.folio}.pdf`;
        console.log('Descargando ', this.fileName);
        this.consultaPagoService.descargarPdfFacturaConfigurable(serie, folio, importe).then(res => {
            // tslint:disable-next-line:no-console
            console.info('PDF', folio);
            this.toastMsg.success('Descargando la factura del pago.');
            // El stream de bytes viene bajo la llave «comprobante»(motores) o «base64»(reger)
            const archivo = res.comprobante != null ? res.factura : res.base64;
            DownloadHelper.downloadFile(this.fileName, archivo);
        }, err => {
            if (err.body && err.body.message) {
                this.toastMsg.error(err.body.message);
            } else {
                this.toastMsg.error('Por el momento no esta disponible la descarga.');
            }
        });
    }

    public descargarXml(serie: string, folio: string, importe: string) {
        this.fileName = 'Xml-' + serie.toUpperCase() + '-' + this.modelCampos.folio + '.xml';
        console.log('Descargando ' + this.fileName);
        this.consultaPagoService.descargarXmlFacturaConfigurable(serie, folio, importe).then(res => {
            console.log('XML', serie + '-' + folio);
            this.toastMsg.success('Descargando XML del pago.');
            // El stream de bytes viene bajo la llave «xml»(motores) o «base64»(reger)
            const bytesArchivo = res.comprobante != null ? res.xml : res.base64;
            // Se decodifica desde base64 dos veces para poder obtener el xml correctamente.
            DownloadHelper.downloadXmlFile(this.fileName, (bytesArchivo));
        }, err => {
            if (err.body && err.body.message) {
                this.toastMsg.error(err.body.message);
            } else {
                this.toastMsg.error('Por el momento no esta disponible la descarga.');
            }
        });
    }
}
