/**
 * Created by Raul on 17/05/2018.
 */

export class GeneralPatternsValidators {
  public static EMAIL_PATTERN = '^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+' +
    '(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$';
  public static RFC_PATTERN = '^([a-zA-Z,Ñ,&amp;]{3,4})([0-9]{2})(([0][1-9])|([1][0-2]))' +
    '(([0][1-9])|([1,2][0-9])|([3][0,1]))([A-Za-z|0-9]{3})$';
  public static RFC_PATTERN_EXP = '^([a-zA-Z,Ñ,&amp;]{3,4})([0-9]{2})(([0][1-9])|([1][0-2]))' +
    '(([0][1-9])|([1,2][0-9])|([3][0,1]))([A-Za-z|0-9]{2,3})?$';
  public static  URL_PATTERN = '^(https?:\/\/)([a-zA-z]{1})([a-zA-Z\.-@!%\'+$"?,=:/*#&-])*\/?$';
  public static  CURP_PATTERN = '^([a-zA-Z][aeiouxAEIOUX][a-zA-Z]{2})(\\d{2})(?:0[1-9]|1[0-2])(\\d{2})[hmHM](?:[Aa][Ss]|[bB][csCS]|[cC][clmshCLMSH]|[dD][fgFG]|[gG][trTR]|[Hh][Gg]|[Jj][Cc]|[Mm][cnsCNS]|[nN][etlETL]|[Oo][Cc]|[Pp][Ll]|[qQ][trTR]|[sS][plrPLR]|[tT][cslCSL]|[Vv][Zz]|[Yy][Nn]|[Zz][Ss])([b-df-hj-np-tv-zB-DF-HJ-NP-TV-Z]{3}[a-zA-Z\\d])(\\d{1})';
  public static EMAIL_CON_DOMINIO_PATTERN = '^\\w+([-+.\']*\\w+)*@\\w+([-.]\\w+)*\\.\\w{2,}(([-]\\w+)*|([.]\\w{2,}))$';
  public static ONLYCHARS = '/[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*\s[a-zA-ZÀ-ÿ\u00f1\u00d1]+/';
  public static PHONE = '^[0-9]{7,12}';
  public static stringNames ='^[a-zA-Z\s]+';
}
