import {Component, OnInit} from '@angular/core';
import {ConfiguracionGeneralServiceConsumos} from '../main-login-app-consumos.component/service/configuracion-general.service';
import {ConfiguracionGeneralModel} from '../main-login-app-consumos.component/model/configuracion-general.model';
/**
 * Created by EMOLINA on 24/01/2019.
 */
@Component({
  selector: 'app-index-admin-consumos',
  templateUrl: './index-admin.component.html'
})
export class IndexAdminComponentConsumos implements OnInit {
  public configuracion: ConfiguracionGeneralModel = {};

  constructor(private configuracionGeneralService: ConfiguracionGeneralServiceConsumos) {
  }

  ngOnInit(): void {
    this.configuracionGeneralService.obtenerConfiguracionGeneral().then(configuracion => {
      this.configuracion = configuracion;
    });
  }
}
