import {Directive, ElementRef, Renderer2} from '@angular/core';

@Directive({
  selector: '[mti-font-contenido-consumos]'
})
export class MtiFontContenidoDirectiveConsumos {
  constructor(public el: ElementRef,
              private renderer: Renderer2) {
  }

  ngAfterViewChecked() {
    
    enum VALORES {
      theme = <any>JSON.parse(localStorage.getItem('theme')) != null ? JSON.parse(localStorage.getItem('theme')) : null
    }
    
    if (VALORES.theme != null) {
      this.renderer.setAttribute(this.el.nativeElement, 'class', VALORES.theme['fuente']);
    } else {
      this.renderer.setAttribute(this.el.nativeElement, 'class', 'roboto');
    }
  }
}
