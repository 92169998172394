/**
 * Created by Raul on 20/07/2018.
 */
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NotificationsResource} from '../service/notifications.resource';
import {NotificationModel} from '../service/notification.model';
import {Router} from '@angular/router';
import {ModalDirective, ToastService} from 'ng-uikit-pro-standard';
import {UserAuth} from '../../common.module/auth/user-auth.model';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';
import {UsuarioService} from '../../admin.admin.module/service/usuario.service';

@Component({
  selector: 'app-notifications-modal',
  templateUrl: 'notifications-modal.component.html'
})
export class NotificationsModalComponent implements OnInit {
  @ViewChild('notificationsModal')
  public modal: ModalDirective;
  public notifications: NotificationModel[] = [];
  @Input()
  borrarNotificacionDeListaYDisminuirContador: Function;
  user: UserAuth;

  constructor(private authServerProvider: AuthServerProvider,
              private notificationsResource: NotificationsResource,
              private router: Router,
              private usuario: UsuarioService,
              private toastMsg: ToastService) {
    this.user = authServerProvider.getUserDecoded();
  }

  ngOnInit() {
  }

  loadNotifications() {
    this.usuario.obtenerUsuario(this.user._id).then(userRes => {
      let emplSol = "T"; let emplClavePuesto = "vacio";

      if (userRes.empleado != null && userRes.empleado != undefined) {
        emplSol = userRes.empleado.visualizacion_solicitudes
        emplClavePuesto = userRes.empleado.clave_puesto;
      }

      this.notificationsResource.getNotificationsB({s: 2, empleado: emplSol, userid: emplClavePuesto}).then(res => {
        this.notifications = res;
        this.notifications.sort(function(a, b) {
          return a._id - b._id;
        }).reverse();
      }, err => this.toastMsg.error('No se han podido cargar sus notificaciones.'));
    }, err => console.error(err));
  }

  seenNotification(notification: NotificationModel, navegar: boolean = false) {
    if (notification && !notification.fecha_visto) {
      this.notificationsResource.seenNotifications({_id: notification._id}).then(obj => {
        const index = this.notifications.findIndex(notif => notif._id == notification._id);
        if (index != -1) {
          // Asigna la fecha de visto a la notificación en la lista (porque no se actualiza el dato automáticamente)
          this.notifications[index].fecha_visto = {'$date': new Date().toISOString()};
          // Disminuye el contador de notificaciones que se encuentra en el componente padre (en el header).
          this.borrarNotificacionDeListaYDisminuirContador(this.notifications[index]);
        }
      });
    }
  }
}
