import {Directive, ElementRef, Renderer2} from '@angular/core';
import {Router} from '@angular/router';

@Directive({
  selector: '[mti-font-contenidolp]'
})
export class MtiFontContenidolpDirective {
  constructor(public el: ElementRef,
              private renderer: Renderer2,
              private route: Router) {
  }

  ngAfterViewChecked() {
    enum VALORES {
      theme = <any>JSON.parse(localStorage.getItem('theme')) != null ? JSON.parse((localStorage.getItem('theme'))) : null
    }

    if (VALORES.theme != null && this.route.url === '/portal') {
      this.renderer.setAttribute(this.el.nativeElement, 'class', VALORES.theme['fuenteContenidoLP']);
    } else if (VALORES.theme != null && this.route.url != '/portal') {
      this.renderer.setAttribute(this.el.nativeElement, 'class', VALORES.theme['fuenteContenido']);
    } else if (VALORES.theme == null) {
      this.renderer.setAttribute(this.el.nativeElement, 'class', 'roboto');
    }
  }
}
