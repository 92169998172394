import {Injectable} from '@angular/core';
import {IResourceMethod, Resource, ResourceAction, ResourceHandler, ResourceParams, ResourceRequestMethod} from '@ngx-resource/core';
import {ResourceAuth} from '../../common.module/auth/authenticate.resource';
import {AuthServerProvider} from '../../common.module/auth/auth-jwt.service';
import {CnfFolioModel} from './model/cnf-folio.model';
import {TramitesModel} from './model/tramites.model';

@Injectable()
@ResourceParams({
    pathPrefix: '/tramites'
    // url: 'http://localhost:8013'
})

export class CnfFolioResource extends ResourceAuth {
  constructor(resourceHandler: ResourceHandler, authProv: AuthServerProvider) {
    super(resourceHandler, authProv);
  }
//  export class CnfFolioResource extends Resource {
//   constructor(resourceHandler: ResourceHandler) {
//      super(resourceHandler);
//    }
  @ResourceAction({
    path: '/cnf-folio/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtieneCnfFolioPorID: IResourceMethod<{id: string}, CnfFolioModel>;

  @ResourceAction({
    path: '/cnf-folio',
    method: ResourceRequestMethod.Post
  })
  creaCnfFolio: IResourceMethod<CnfFolioModel, any>;

  @ResourceAction({
    path: '/cnf-folio/{!_id}',
    method: ResourceRequestMethod.Patch
  })
  actualizaCnfFolioPorID: IResourceMethod<CnfFolioModel, any>;

  @ResourceAction({
    path: '/cnf-folio-clave/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtieneCnfFolioPorClave: IResourceMethod<{clave: string}, CnfFolioModel>;

  @ResourceAction({
    path: '/cnf-folio-filtro',
    array: true,
    method: ResourceRequestMethod.Get
  })
  obtieneCnfFolioListaFiltro: IResourceMethod<any, CnfFolioModel[]>;


  @ResourceAction({
    path: '/cnf-folio/{!id}',
    method: ResourceRequestMethod.Delete

  })
  eliminaCnfFolio: IResourceMethod<{ id: any }, any>;

  @ResourceAction({
    path: '/cnf-folio-tramites',
    array: true,
    method: ResourceRequestMethod.Get
  })
  obtieneTramitesLista: IResourceMethod<any, TramitesModel[]>;

  @ResourceAction({
    path: '/cnf-folio-tram/{!id}',
    method: ResourceRequestMethod.Get
  })
  obtieneConfigFolioPorTramiteId: IResourceMethod<{id: string}, CnfFolioModel>;

  @ResourceAction({
    path: '/cnf-folio-clave',
    method: ResourceRequestMethod.Patch
  })
  actualizarFolioInternoConsecutivo: IResourceMethod<any, any>;

}
