import { DirectivasModule } from './../directivas.module/directivas.module';
/**
 * Created by rsierra on 25/09/2017.
 */

import {NgModule} from '@angular/core';
import {AppCommonLibsModule} from '../common.module/common.module';
import {UsuarioResource} from './service/usuario.resource';
import {UsuarioService} from './service/usuario.service';
import {AdminAdminRoutingModule} from './admin-admin.routing';
import {UsuarioListaComponent} from './usuario-lista/usuario-lista.component';
import {UsersDetailComponent} from './usuario-detalle/usuario-detalle.component';
import {RolResource} from './service/rol.resource';
import {RolDetalleComponent} from './usuario-detalle/rol-detalle/rol-detalle.component';
import {OrganizacionesService} from './service/organizaciones.service';
import {OrganizacionesResource} from './service/organizaciones.resource';

@NgModule({
  imports: [
    AdminAdminRoutingModule,
    AppCommonLibsModule,
    DirectivasModule
  ],
  declarations: [
    UsuarioListaComponent,
    UsersDetailComponent,
    RolDetalleComponent
  ],
  providers: [
    UsuarioResource,
    UsuarioService,
    RolResource,
    OrganizacionesService,
    OrganizacionesResource
  ]
})
export class AdminAdminModule {
}
