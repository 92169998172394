/**
 * Created by MTI on 30/05/2018.
 */
export class DownloadHelper {

  /**
   *
   * Funcion que nos permite descargar nuestro archivo indicandole unicamente el nombre del archivo con el su extensión
   * y el valor base64 alojado en la base de datos
   *
   * @param filename Nombre del archivo con extension. ejemplo: ejemplo.pdf
   * @param base64 Base64 del archivo. ejemplo: SG9sYQ== (Hola)
   */
  /*public static downloadFile(filename, base64) {
    const a = document.createElement('a');
    const dataURI = 'data:;base64,' + base64;
    a.href = dataURI;
    a['download'] = filename;
    const e = document.createEvent('MouseEvents');
    e.initMouseEvent('click', true, false,
      document.defaultView, 0, 0, 0, 0, 0,
      false, false, false, false, 0, null);
    a.dispatchEvent(e);
  }*/

  public static downloadFile(filename, base64) {
    const mimeType = 'application/pdf';
    this.createFileAndSendDownload(base64, mimeType, filename);
  }

  public static downloadXmlFile(filename, base64) {
    const mimeType = 'application/xml';
    this.createFileAndSendDownload(base64, mimeType, filename);

  }

  public static downloadZipFile(filename, base64) {
    const mimeType = 'application/zip';
    this.createFileAndSendDownload(base64, mimeType, filename);

  }

  private static createFileAndSendDownload(base64, mimeType: string, filename) {
    const dataURI = this.base64ToArrayBuffer(base64);
    const blob = new Blob([dataURI], {type: mimeType});
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    }
  }

  public static base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array( len );
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
}
