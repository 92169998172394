import {MtiColorThemeDirectiveConsumos} from './directivas/mti-color-theme.directive';
import {MtiFontSubtitulosDirectiveConsumos} from './directivas/mti-font-subtitulos.directive';
import {MtiFontTitulosDirectiveConsumos} from './directivas/mti-font-titulos.directive';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MtiFontContenidoDirectiveConsumos} from './directivas/mti-font-contenido.directive';
import {MtiFontContenidolpDirectiveConsumos} from './directivas/mti-font-contenidolp.directive';
import {MtiColorHoverItemDirectiveConsumos} from './directivas/mti-color-hover-item.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    MtiFontContenidoDirectiveConsumos,
    MtiFontContenidolpDirectiveConsumos,
    MtiFontTitulosDirectiveConsumos,
    MtiFontSubtitulosDirectiveConsumos,
    MtiColorThemeDirectiveConsumos,
    MtiColorHoverItemDirectiveConsumos
  ],
  exports: [
    MtiFontContenidoDirectiveConsumos,
    MtiFontContenidolpDirectiveConsumos,
    MtiFontTitulosDirectiveConsumos,
    MtiFontSubtitulosDirectiveConsumos,
    MtiColorThemeDirectiveConsumos,
    MtiColorHoverItemDirectiveConsumos
  ]
})
export class DirectivasModuleConsumos {
}
